import axios, { AxiosResponse } from 'axios'

import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import CustomerAddress from '../types/CustomerAddress'
import getBearerToken from '../../utils/getBearerToken'

interface SetShippingAddressesOnCartResponse {
  data: {
    setShippingAddressesOnCart: { cart: Cart } | null
  }
  errors?: [{ message: string }]
}

export const setShippingAddressesOnCartMutation = gql`
  ${cartFragment}
  mutation($cartId: String!, $shippingAddresses: [ShippingAddressInput!]!) {
    setShippingAddressesOnCart(
      input: { cart_id: $cartId, shipping_addresses: $shippingAddresses }
    ) {
      cart {
        ...CartFields
      }
    }
  }
`

/**
 * This method should NOT be called if the cart doesn't contain any physical items, or
 * if 'sameAsShipping' is set to true when setting the billing address on the cart.
 *
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const setShippingAddressesOnCart = async (
  cartId: string,
  shippingAddresses: CustomerAddress[],
): Promise<Cart | null> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<SetShippingAddressesOnCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: setShippingAddressesOnCartMutation,
      variables: {
        cartId,
        shippingAddresses: shippingAddresses.map(address => ({
          address: {
            firstname: address.firstName,
            lastname: address.lastName,
            street: address.suite ? [address.street, address.suite] : [address.street],
            city: address.city,
            region: address.stateCode,
            postcode: address.postalCode,
            country_code: address.country,
            telephone: address.phoneNumber || '',
            company: address.company,
          },
        })),
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.setShippingAddressesOnCart) {
    return data.data.setShippingAddressesOnCart.cart
  }

  return null
}
