import { ref, Ref } from 'vue'

interface UseForm {
  isBusy: Ref<boolean>
  errorMessage: Ref<string>
  clearErrorMessage: () => void
  setErrorMessage: (message: string) => void
}

const useForm = (): UseForm => {
  const isBusy = ref<boolean>(false)
  const errorMessage = ref<string>('')

  const setErrorMessage = (message: string): void => {
    errorMessage.value = message
  }

  const clearErrorMessage = (): void => {
    errorMessage.value = ''
  }

  return {
    isBusy,
    errorMessage,
    setErrorMessage,
    clearErrorMessage,
  }
}

export default useForm
