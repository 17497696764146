import { ref, Ref } from 'vue'

import useNotify from './useNotify'
import useCustomer from './useCustomer'
import Country from '../../../global/js/magento-api-library/types/Country'
import CountryRegion from '../../../global/js/magento-api-library/types/CountryRegion'
import { getCountries } from '../../../global/js/magento-api-library/address/countries'
import { log } from '../../../global/js/utils/logging'

interface UseCountries {
  countryData: Ref<Country[]>
  countries: Ref<{ label: string; value: string }[]>
  selectedCountry: Ref<{ label: string; value: string }>
  stateData: Ref<CountryRegion[]>
  states: Ref<{ label: string; value: string }[]>
  selectedState: Ref<{ label: string; value: string }>
  setRegionData: () => Promise<void>
}

const useCountries = (): UseCountries => {
  const { notify } = useNotify()
  const { customer } = useCustomer()

  const countryData = ref<Country[]>([])
  const countries = ref<{ label: string; value: string }[]>([])
  const selectedCountry = ref<{ label: string; value: string }>({
    label: '',
    value: '',
  })

  const stateData = ref<CountryRegion[]>([])
  const states = ref<{ label: string; value: string }[]>([])
  const selectedState = ref<{ label: string; value: string }>({
    label: '',
    value: '',
  })

  const setRegionData = async (): Promise<void> => {
    try {
      countryData.value = await getCountries()

      countries.value = countryData.value.map((country: Country) => ({
        label: country.full_name_english,
        value: country.two_letter_abbreviation,
      }))

      if (customer.value && customer.value.addresses.length) {
        const currentAddress =
          customer.value.addresses[customer.value.addresses.length - 1]

        if (currentAddress) {
          const country = countryData.value.find((countryToFind: Country) => {
            return countryToFind.two_letter_abbreviation === currentAddress.country_code
          }) as Country

          selectedCountry.value = {
            label: country.full_name_english,
            value: country.two_letter_abbreviation,
          }

          selectedState.value = {
            label: currentAddress.region.region || '',
            value: currentAddress.region.region_code || '',
          }
        }
      }
    } catch (error) {
      log(error as string, 'src/reliasmedia/js/composables/useCountries.ts')

      notify({
        type: 'error',
        title: 'Error',
        text: 'Countries could not be retrieved.',
        duration: -1,
      })
    }
  }

  return {
    states,
    countries,
    stateData,
    countryData,
    selectedState,
    setRegionData,
    selectedCountry,
  }
}

export default useCountries
