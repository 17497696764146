
import { Options } from '@splidejs/splide'
import { Splide } from '@splidejs/vue-splide'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  name: 'AppCarousel',
  components: {
    Splide,
  },
  props: {
    options: {
      type: Object as PropType<Options>,
      required: false,
      default: (): Options => ({
        type: 'loop',
        pagination: false,
        fixedWidth: '100%',
        classes: {
          arrow : 'splide__arrow hero-image__arrow-btns',
        },
      }),
    },
  },
})
