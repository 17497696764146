import {CustomerAddress} from './Customer'
import {getCountries} from '../address/countries';
import Country from './Country';

export class AddressValidator {
  private readonly customerAddress: CustomerAddress;
  constructor(customerAddress: CustomerAddress) {
    this.customerAddress = customerAddress
  }
  async isValid(): Promise<boolean> {
    return this.hasValidRegion();
  }

  private async hasValidRegion(): Promise<boolean> {
    let countries = await getCountries()
    const country = countries.find((countryToFind: Country) => {
      return countryToFind.two_letter_abbreviation === this.customerAddress.country_code
    }) as Country|undefined;

    if(!country){
      return false;
    }
    if(country.available_regions && country.available_regions.length > 0){
      return this.customerAddress.region.region !== null &&
        this.customerAddress.region.region_code !== null &&
        this.customerAddress.region.region_id !== 0
    }
    return true
  }
}
