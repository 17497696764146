
import { defineComponent, PropType } from 'vue'

import useAuth from '../../../../composables/useAuth'

interface DownloadableFile {
  title: string
  downloadLink: string
  bookTest: string | null
  status: string
}

export default defineComponent({
  name: 'DownloadableFilesTableRows',
  props: {
    file: {
      type: Object as PropType<DownloadableFile>,
      required: true,
    },
  },
  setup() {
    const checkLoginStatus = (): void => {
      let { isLoggedIn } = useAuth()
      if (!isLoggedIn.value) {
        window.location.assign('/user/login')
      }
    }
    return {
      checkLoginStatus,
    }
  },

})
