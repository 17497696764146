import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import SingleOrder from '../types/SingleOrder'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface GetSingleOrderResponse {
  data: {
    customer: {
      orders: {
        items: SingleOrder[]
      }
    } | null
  }
  errors?: [{ message: string }]
}

export const singleOrderQuery = gql`
  query ($orderNumber: FilterStringTypeInput) {
    customer {
      orders(filter: { number: $orderNumber }) {
        items {
          id
          number
          total {
            grand_total {
              currency
              value
            }
            total_shipping {
              currency
              value
            }
            total_tax {
              currency
              value
            }
            subtotal {
              currency
              value
            }
          }
          billing_address {
            firstname
            lastname
            street
            city
            region
            postcode
          }
          shipping_address {
            firstname
            lastname
            street
            city
            region
            postcode
          }
          shipping_method
          items {
            id
            product_name
            quantity_ordered
            product_sale_price {
              currency
              value
            }
            discounts {
              amount {
                currency
                value
              }
              label
            }
          }
        }
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const getSingleOrder = async (
  orderNumber: string,
): Promise<SingleOrder | null> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<GetSingleOrderResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: singleOrderQuery,
      variables: {
        orderNumber: {
          eq: orderNumber,
        },
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.customer) {
    return data.data.customer.orders.items[0]
  }

  return null
}
