import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'
import {AxiosRequest} from '../../utils/request';
import { log } from '../../utils/logging'

interface GetCourseStartUrlResponse {
  data: {
    fetchCourseStartUrl: {
      startUrl: string | null
    }
  }
}

export const testStartLinkQuery = gql`
    query($issueCode: String) {
        fetchCourseStartUrl(courseCode: $issueCode) {
            startUrl
        }
    }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const getCourseStartUrl = async (issueCode: string): Promise<string | null> => {
  const bearerToken = getBearerToken()
  try {
    const data: GetCourseStartUrlResponse = await AxiosRequest.cacheableRequest({
      method: 'POST',
      url: process.env.MIX_MAGENTO_GRAPHQL,
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${bearerToken}`,
        Store: getStoreName(),
      },
      data: {
        query: testStartLinkQuery,
        variables: {
          issueCode,
        },
      },
    }, {key: issueCode, expirationInSeconds: 1800})
    
    if (data.data.fetchCourseStartUrl && data.data.fetchCourseStartUrl.startUrl) {
      return data.data.fetchCourseStartUrl.startUrl
    } else {
      log('getCourseStartUrl() response using code "' + issueCode + '": ' + JSON.stringify(data.data), 'src/global/js/magento-api-library/customer/getCourseStartUrl.ts')
    }
  } catch (e) {
    // Do Nothing the null return value is acceptable here
  }

  return null
}
