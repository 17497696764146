
import { defineComponent } from 'vue'

import AppAlert from './AppAlert.vue'

export default defineComponent({
  name: 'AppContentWrapper',
  components: {
    AppAlert,
  },
  props: {
    hasLoaded: {
      type: Boolean,
      required: true,
    },
    hasErrors: {
      type: Boolean,
      required: true,
    },
    errorText: {
      type: String,
      required: true,
    },
  },
})
