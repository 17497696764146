
import Cookies from 'js-cookie'
import { defineComponent, onMounted, ref } from 'vue'

import OrderDetailsTable from './OrderDetailsTable.vue'
import ListSkeleton from '../../skeletons/ListSkeleton.vue'
import TableSkeleton from '../../skeletons/TableSkeleton.vue'
import OrderDetailsAddresses from './OrderDetailsAddresses.vue'
import AppContentWrapper from '../../global/AppContentWrapper.vue'

import SingleOrder from '../../../../../global/js/magento-api-library/types/SingleOrder'
import { getSingleOrder } from '../../../../../global/js/magento-api-library/orders/getSingleOrder'
import { trackPurchase } from '../../../../../global/js/analytics/gtm'
import { log } from '../../../../../global/js/utils/logging'

export default defineComponent({
  name: 'OrderDetailsContainer',
  components: {
    ListSkeleton,
    TableSkeleton,
    AppContentWrapper,
    OrderDetailsTable,
    OrderDetailsAddresses,
  },
  setup() {
    const hasLoaded = ref<boolean>(false)
    const hasErrors = ref<boolean>(false)
    const order = ref<SingleOrder | null>(null)

    onMounted(async (): Promise<void> => {
      try {
        hasLoaded.value = false
        hasErrors.value = false

        const response = await getSingleOrder(Cookies.get('orderNumber') || '')

        if (response) {
          order.value = response
          trackPurchase(response)
        }
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/checkout/details/OrderDetailsContainer.vue')

        hasErrors.value = true
      } finally {
        hasLoaded.value = true
      }
    })

    return {
      order,
      hasErrors,
      hasLoaded,
    }
  },
})
