import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import {AxiosRequest} from '../../utils/request'

interface GetGuestCartResponse {
  data: {
    cart: Cart | null
  }
  errors?: [{ message: string }]
}

export const guestCartQuery = gql`
    ${cartFragment}
    query($cartId: String!) {
        cart(cart_id: $cartId) {
            ...CartFields
        }
    }
`

/**
 * Retrieve the cart for a guest user.
 *
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const getGuestCart = async (cartId: string): Promise<Cart | null> => {
  return new Promise<Cart | null>(async (resolve, reject) => {
    await AxiosRequest.queuedCacheableRequest({
      method: 'POST',
      url: process.env.MIX_MAGENTO_GRAPHQL,
      headers: {
        'Content-Type': 'application/json',
        Store: getStoreName(),
      },
      data: {
        query: guestCartQuery,
        variables: {
          cartId,
        },
      },
    }, {key: 'guestCart', expirationInSeconds: 300}, (data: GetGuestCartResponse) => {
      if (data.errors) {
        reject(data.errors[0].message)
      }

      if (data.data.cart) {
        resolve(data.data.cart)
      }

      resolve(null)
    })
  })
}
