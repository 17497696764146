
import { computed, defineComponent, toRefs } from 'vue'

export default defineComponent({
  name: 'AppSelectInput',
  props: {
    modelValue: {
      type: [String, Number, Array, Boolean],
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      required: false,
      default: '',
    },
    labelClasses: {
      type: String,
      required: false,
      default: null,
    },
    containerClasses: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    defaultOptionText: {
      type: String,
      required: false,
      default: '',
    },
    redirect: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, { emit }) {
    const { modelValue } = toRefs(props)

    const local = computed({
      get: () => modelValue.value,
      set: (value) => {
        if (props.redirect) {
          window.location.href = value as string
        }
        else {
          emit('update:modelValue', value)
        }
      },
    })

    return {
      local,
    }
  },
})
