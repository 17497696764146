
import { defineComponent, ref } from 'vue'
import AccountTab from '../../types/AccountTab'
import useAuth from '../../composables/useAuth'

export default defineComponent({
  name: 'AccountTabs',
  props: {
    activeTab: {
      type: String,
      required: true,
    },
  },
  emits: ['setActiveTab'],
  setup(props, { emit }) {
    const tabs = ref<AccountTab[]>([
      { tabTitle: 'My Account', tabSlug: 'Account' },
      { tabTitle: 'My Subscriptions', tabSlug: 'Subscriptions' },
      { tabTitle: 'My Orders', tabSlug: 'OrderHistory' },
      { tabTitle: 'My Content', tabSlug: 'PurchasedContent' },
      { tabTitle: 'My Transcripts', tabSlug: 'MyTranscripts' },
    ])

    const getTabLinkPath = (tabSlug: string): string => {
      return `/user/profile#${tabSlug}`
    }

    const updateActiveTab = (tabSlug: string): void => {
      let { isLoggedIn } = useAuth()
      if(!isLoggedIn.value)
      {
        window.location.assign('/user/login')
      }
      emit('setActiveTab', tabSlug)
    }

    return {
      tabs,
      getTabLinkPath,
      updateActiveTab,
    }
  },
})
