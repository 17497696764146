import axios, { AxiosResponse } from 'axios'

import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface RemoveProductFromCartResponse {
  data: {
    removeItemFromCart: { cart: Cart } | null
  }
  errors?: [{ message: string }]
}

export const removeProductFromCartMutation = gql`
  ${cartFragment}
  mutation($cartId: String!, $cartItemUid: ID!) {
    removeItemFromCart(input: { cart_id: $cartId, cart_item_uid: $cartItemUid }) {
      cart {
        ...CartFields
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const removeProductFromCart = async (
  cartId: string,
  cartItemUid: string,
): Promise<Cart | null> => {

  if (cartItemUid === undefined) {
    return null
  }

  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<RemoveProductFromCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: removeProductFromCartMutation,
      variables: {
        cartId,
        cartItemUid,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.removeItemFromCart) {
    return data.data.removeItemFromCart.cart
  }

  return null
}
