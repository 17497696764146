import { ref, Ref, nextTick } from 'vue'

interface UseArrowKeys {
  menuItemIndex: Ref<number>
  listItems: Ref<HTMLAnchorElement[]>
  setListItems: (selector: string) => void
  handleArrowPress: (index: number) => void
}

const useArrowKeys = (): UseArrowKeys => {
  const menuItemIndex = ref<number>(0)
  const listItems = ref<HTMLAnchorElement[]>([])

  const setListItems = async (selector: string): Promise<void> => {
    await nextTick()

    const list = document.querySelector(selector)

    if (list) {
      listItems.value = Array.from(list.querySelectorAll('li a'))
    }
  }

  const handleArrowPress = (index: number): void => {
    if (index === listItems.value.length) {
      index = 0
    } else if (index < 0) {
      index = listItems.value.length - 1
    }

    listItems.value[index].focus()

    menuItemIndex.value = index
  }

  return {
    listItems,
    setListItems,
    menuItemIndex,
    handleArrowPress,
  }
}

export default useArrowKeys
