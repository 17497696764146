import { MutationTree } from 'vuex'

import { State } from './state'
import { CartMutationTypes } from './mutation-types'
import Cart from '../../../../../global/js/magento-api-library/types/Cart'

export interface Mutations {
  [CartMutationTypes.SET_CART]: (state: State, cart: Cart) => void
  [CartMutationTypes.MINICART_COUNT]: (state: State, status: number) => void
}

export const mutations: MutationTree<State> & Mutations = {
  [CartMutationTypes.SET_CART]: (state: State, cart: Cart) => {
    state.cart = cart
  },
  [CartMutationTypes.MINICART_COUNT]: (state: State, count: number) => {
    state.minicartCount = count
  },
}
