import { computed, ComputedRef } from 'vue'

import { useStore } from '../store/index'
import { UIMutationTypes } from '../store/modules/ui/mutation-types'

interface UseMobileNav {
  mobileNavIsOpen: ComputedRef<boolean>
  toggleMobileNav: () => void
  resetMobileNav: () => void
}

const useMobileNav = (): UseMobileNav => {
  const store = useStore()

  const mobileNavIsOpen = computed((): boolean => store.state.ui.mobileNavIsOpen)

  const toggleMobileNav = (): void => {
    store.commit(UIMutationTypes.TOGGLE_MOBILE_NAV)
  }

  const resetMobileNav = (): void => {
    store.commit(UIMutationTypes.RESET_MOBILE_NAV)
  }

  return {
    resetMobileNav,
    mobileNavIsOpen,
    toggleMobileNav,
  }
}

export default useMobileNav
