
import { defineComponent } from 'vue'

import useAuth from '../../composables/useAuth'

export default defineComponent({
  name: 'ShowAuthUserContent',
  setup() {
    const { isLoggedIn } = useAuth()

    return {
      isLoggedIn,
    }
  },
})
