import SingleOrder from '../magento-api-library/types/SingleOrder'
import CartItem from '../magento-api-library/types/CartItem'

interface UAPurchaseItem {
	name: string
	id: string
	price?: number
	brand?: string
	category?: string
	variant?: string
	quantity?: number
	coupon?: string
	list_position?: number
}

interface UAPurchase {
	actionField: {
		id: string
		affiliation?: string
		revenue: number
		tax: number
		shipping: number
		coupon?: string
	}
	products: UAPurchaseItem[]
}

export const trackPurchaseUa = (order: SingleOrder): void => {
	const ga4Purchase: UAPurchase = {
		actionField: {
			id: order.id,
			revenue: order.total.subtotal.value,
			tax: order.total.total_tax.value,
			shipping: order.total.total_shipping.value,
		},
		products: [],
	}
	for (const item of order.items) {
		let couponCombinedString = ''
		item.discounts.forEach((discount) => {
			couponCombinedString += discount.label + '|'
		})
		ga4Purchase.products.push({
			id: item.product_sku,
			name: item.product_name,
			coupon: couponCombinedString.substring(0, couponCombinedString.length - 1),
			price: item.product_sale_price.value,
			quantity: item.quantity_ordered,
		})
	}

	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({ecommerce: null})  // Clear the previous ecommerce object.
	window.dataLayer.push({
		'ecommerce': {
			'purchase': ga4Purchase,
		},
	})
}

export const trackAddToCartUa = (cartItem: CartItem, quantity: number): void => {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({ecommerce: null})  // Clear the previous ecommerce object.
	window.dataLayer.push({
		'event': 'addToCart',
		'ecommerce': {
			'currencyCode': 'USD',
			'add': {                                // 'add' actionFieldObject measures.
				'products': [{                        //  adding a product to a shopping cart.
					'name': cartItem.product.name,
					'id': cartItem.product.sku,
					'price': cartItem.prices.price.value,
					'quantity': quantity,
				}] as UAPurchaseItem[],
			},
		},
	})
}