
import Cookies from 'js-cookie'
import {defineComponent, onBeforeMount, ref} from 'vue'
import axios, {AxiosResponse} from 'axios'
import useAuth from '../../composables/useAuth'
import useModal from '../../composables/useModal'
import { getSubscriptions } from '../../../../global/js/magento-api-library/subscriptions/getSubscriptions'
import { log } from '../../../../global/js/utils/logging'

export default defineComponent({
  name: 'ArticleLimiter',
  props: {
    articleId: {
      type: Number,
      required: true,
    },
    newsletterId: {
      type: Number,
      required: false,
      default: 0,
    },
    publicationSku: {
      type: String,
      required: true,
    },
  },
  setup(props) {

    const { isLoggedIn }= useAuth()
    const { setOpenedModal } = useModal()
    const isAuthorized = ref<boolean>(false)

    const handleAuthenticated = async (): Promise<void> => {
      try {
        const subscriptions = await getSubscriptions()

        if (subscriptions) {
          const subscriptionProducts = new Set()
          subscriptions.forEach((subscription) => {
            subscription.quote.items.forEach((item) => {
              subscriptionProducts.add(item.sku)
            })
          })
          isAuthorized.value = subscriptionProducts.has(props.publicationSku) || subscriptionProducts.has('RM-AAP')
        }
      } catch (e) {
        log(e as string, 'src/reliasmedia/js/components/articles/ArticleLimiter.vue')
        console.error(e)
      }
    }

    const handleUnauthenticated = (): void => {
      if (!Cookies.get('viewedArticles')) {
        Cookies.set('viewedArticles', JSON.stringify([props.articleId]), { expires: 30 })

        isAuthorized.value = true
      } else {
        const viewedArticles: number[] = JSON.parse(Cookies.get('viewedArticles') as string)

        if (viewedArticles.length < 3) {
          isAuthorized.value = true

          if (!viewedArticles.includes(props.articleId)) {
            Cookies.set(
              'viewedArticles',
              JSON.stringify([...viewedArticles, props.articleId]),
              { expires: 30 },
            )
          }
        } else {
          isAuthorized.value = viewedArticles.includes(props.articleId)
        }
      }
    }

    const handleLicenseAccess = async ():Promise<boolean> => {
      let userIpCall = await axios( {
        method: 'GET',
        url: 'https://api.ipify.org?format=json',
        headers: {
          'Accept': 'application/json',
        },
      })
      let userIp = 0
      if (userIpCall?.data != undefined) {
        userIp = userIpCall.data.ip ?? 0
      }
      const result: AxiosResponse = await axios({
        method: 'POST',
        url: '/actions/allow-list/allow-list/authenticate',
        headers: {
          'Accept': 'application/json',
        },
        data: {
          entryId: props.newsletterId,
          ip: userIp,
        },
      })
      if (result?.data != undefined) {
        return result.data.success ?? false
      }
      return false
    }

    const handleAccess = async () => {
      isAuthorized.value = await handleLicenseAccess()
      if(!isAuthorized.value) {
        if (isLoggedIn.value) {
          await handleAuthenticated()
        }
        // If they still aren't authorized by a subscription, we'll fall back to the unauthenticated mode
        if (!isAuthorized.value) {
          handleUnauthenticated()
        }
      }
    }

    onBeforeMount(async (): Promise<void> => {
      await handleAccess()

      if (!isAuthorized.value) {
        setOpenedModal('articleLimit')
      }
    })

    return {
      isLoggedIn,
      isAuthorized,
      handleAuthenticated,
      handleUnauthenticated,
      handleAccess,
    }
  },
})
