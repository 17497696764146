
import {computed, defineComponent, toRefs, reactive, watch} from 'vue'
import {useField} from 'vee-validate'

export default defineComponent({
  name: 'AppTextInput',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    type: {
      type: String,
      required: false,
      default: 'text',
    },
    labelText: {
      type: String,
      required: false,
      default: '',
    },
    labelClasses: {
      type: String,
      required: false,
      default: null,
    },
    containerClasses: {
      type: String,
      required: false,
      default: null,
    },
    placeholder: {
      type: String,
      required: false,
      default: '',
    },
    validationRules: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props, { emit }) {
    const { modelValue } = toRefs(props)

    const validateField = reactive(useField(props.name, props.validationRules))

    const local = computed({
      get: () => modelValue.value,
      set: (value) => {
        emit('update:modelValue', value)
      },
    })

    watch(
      () => validateField.errorMessage,
      () => {
        if(validateField.errorMessage){
          emit('setError', {
            fieldName: props.name,
            validationError: validateField.errorMessage,
          })
        } else {
          emit('removeError', props.name)
        }
      },
    )

    return {
      local,
      validateField,
    }
  },
})
