
import {computed, defineComponent, ref} from 'vue'

import useCart from '../../../composables/useCart'
import useLoader from '../../../composables/useLoader'
import useNotify from '../../../composables/useNotify'
import {removeCouponFromCart} from '../../../../../global/js/magento-api-library/cart/removeCouponFromCart'
import { log } from '../../../../../global/js/utils/logging'
import {get, update} from 'idb-keyval'
import useAuth from '../../../composables/useAuth'

export default defineComponent({
  name: 'CartPromoCodeRow',
  setup() {
    const {notify} = useNotify()
    const {setLoading} = useLoader()
    const {cart, setCart, isCartPage} = useCart()
    const isLoading = ref<boolean>(false)
    const {isLoggedIn} = useAuth()

    const code = computed((): string => {
      return cart.value.applied_coupons
        ? cart.value.applied_coupons[0].code
        : ''
    })

    const handleRemoveCode = async (): Promise<void> => {
      try {
        isLoading.value = true

        setLoading(true)

        const cartResponse = await removeCouponFromCart(cart.value.id)

        if (cartResponse !== null) {
          let updatedCart = null
          let cacheKey = 'guestCart'

          if (isLoggedIn.value) {
            cacheKey = 'cart'
            updatedCart = {
              data: {
                customerCart: cartResponse,
              },
            }
            setCart(updatedCart.data.customerCart)
          } else {
            updatedCart = {
              data: {
                cart: cartResponse,
              },
            }
            setCart(updatedCart.data.cart)
          }
          const cacheData = await get(cacheKey)
          const cacheValue = {
            data: updatedCart,
            expiration: cacheData ? (cacheData.expiration + (300 * 1000)) : (Date.now() + (300 * 1000)), // .now returns milliseconds
          }
          await update(cacheKey, cacheData => cacheValue)
        }

        notify({
          type: 'success',
          title: 'Success!',
          text: 'Promo code was removed from your cart.',
        })
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/checkout/cart/CartPromoCodeRow.vue')

        notify({
          type: 'error',
          title: 'Error',
          text: 'The promo code could not be removed.',
          duration: -1,
        })
      } finally {
        isLoading.value = false

        setLoading(false)
      }
    }

    return {
      cart,
      code,
      isLoading,
      isCartPage,
      handleRemoveCode,
    }
  },
})
