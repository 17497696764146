import axios, {AxiosResponse} from 'axios';

interface CustomAttribute {
	attribute_options: [{ value: string, label: string}]
}

interface CustomerMetadataResponse {
	data: {
		customAttributeMetadata: {
			items: [CustomAttribute]
		}
	}
}
export const fetchCustomerDropDowns = async () :Promise<CustomAttribute[] | null> => {
	try {
		const query = `
                {
                customAttributeMetadata(
                    attributes: [
                    {
                        attribute_code: "media_abim_membership"
                        entity_type: "customer"
                    },
                    {
                        attribute_code: "media_abms_board"
                        entity_type: "customer"
                    },
                    {
                        attribute_code: "media_credentials"
                        entity_type: "customer"
                    },
                    {
                        attribute_code: "media_specialty"
                        entity_type: "customer"
                    },
                    {
                        attribute_code: "media_profession"
                        entity_type: "customer"
                    },
                    {
                        attribute_code: "media_credential_secondary"
                        entity_type: "customer"
                    }
                    ]
                ) {
                    items {
                    attribute_options {
                        value
                        label
                        }
                    }
                }
            }
        `

		const result: AxiosResponse<CustomerMetadataResponse> = await axios({
			method: "POST",
			url: process.env.MIX_MAGENTO_GRAPHQL,
			data: {
				query: query
			}
		});

		return result.data.data.customAttributeMetadata.items

	} catch (error) {
		console.error(error);
	}
	return null;
}
