const fetchCurrencySymbol = (countryCode: string): string => {

  const currencies = [
    {
      currencyCode: 'USD',
      currencySymbol: '$',
    },
    {
      currencyCode: 'CAD',
      currencySymbol: 'CA$',
    },
  ]
  const currency = currencies.find(currency => currency.currencyCode === countryCode)
  if (currency)
    return currency.currencySymbol
  else
    return '$'

}

export default fetchCurrencySymbol