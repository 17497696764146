
import {computed, defineComponent, watch, ref, onMounted} from 'vue'
import AppTextInput from '../global/forms/inputs/AppTextInput.vue'
import AppInlineAlert from '../global/AppInlineAlert.vue'
import TableSkeleton from '../skeletons/TableSkeleton.vue'
import TranscriptsListItem from './TranscriptsListItem.vue'
import AppContentWrapper from '../global/AppContentWrapper.vue'

import TranscriptItem from '../../../../global/js/magento-api-library/types/TranscriptItem'
import {getN8nTranscripts} from '../../../../global/js/n8n-api-library/getTranscripts'
import useCustomer from '../../composables/useCustomer'
import getRLPSSOToken from '../../../../global/js/utils/getRLPSSOToken'
import getStoreName from '../../../../global/js/utils/getStoreName'
import getBearerToken from '../../../../global/js/utils/getBearerToken'
import print from '../../../../global/js/utils/print'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'TranscriptsContainer',
  components: {
    FontAwesomeIcon,
    TableSkeleton,
    AppInlineAlert,
    AppContentWrapper,
    TranscriptsListItem,
    AppTextInput,
  },
  setup() {
    const {customer} = useCustomer()
    const customerName = computed(() =>
      `${customer.value?.prefix ? customer.value?.prefix + ' ' : ''}${customer.value?.firstname}
      ${customer.value?.lastname}`)
    const customerLicenseId = computed(() => customer.value?.media_abim_member_id
        || customer.value?.media_secondary_board_id
        || '',
    )
    const hasLoaded = ref<boolean>(false)
    const hasErrors = ref<boolean>(false)
    const transcripts = ref<TranscriptItem[]>([])
    const showDownloadError = ref<boolean>(false)
    const logo = document.querySelector('.header__logo__link>img') as HTMLImageElement
    const fromDate = ref<string>('1999-01-01')
    const toDate = ref<string>()
    const completedByBody = ref<TranscriptItem[]>([])
    const sortBy = ref<string>('createDate')
    const sortDirection = ref<string>('desc')
    const sortOptions = [
      {
        label: 'Completion Date',
        value: 'completionDate',
      },
      {
        label: 'Created Date',
        value: 'createDate',
      },
      {
        label: 'Course Title',
        value: 'courseTitle',
      },
      {
        label: 'Credit Hours',
        value: 'creditHours',
      },
      {
        label: 'Grade',
        value: 'grade',
      },
      {
        label: 'Expiration Date',
        value: 'expires',
      },
    ]

    const filterByDate = (date: string) => {
      return Date.parse(date as string) > Date.parse(fromDate.value as string)
          && (toDate.value ? Date.parse(date.split('T')[0] as string) <= Date.parse(toDate.value as string) : true)
    }
    const sortTranscripts = () => {
      if (sortBy.value === 'createDate') {
        transcripts.value.sort((a, b) => {
          return sortDirection.value === 'desc'
            ? Date.parse(b.createDate as string) - Date.parse(a.createDate as string)
            : Date.parse(a.createDate as string) - Date.parse(b.createDate as string)
        })
      } else if (sortBy.value === 'completionDate') {
        transcripts.value.sort((a, b) => {
          return sortDirection.value === 'desc'
            ? Date.parse(b.completionDate as string) - Date.parse(a.completionDate as string)
            : Date.parse(a.completionDate as string) - Date.parse(b.completionDate as string)
        })
      } else if (sortBy.value === 'courseTitle') {
        transcripts.value.sort((a, b) => {
          return sortDirection.value === 'desc'
            ? b.courseTitle.localeCompare(a.courseTitle)
            : a.courseTitle.localeCompare(b.courseTitle)
        })
      } else if (sortBy.value === 'expires') {
        transcripts.value.sort((a, b) => {
          return sortDirection.value === 'desc'
            ? Date.parse(b.expires as string) - Date.parse(a.expires as string)
            : Date.parse(a.expires as string) - Date.parse(b.expires as string)
        })
      } else if (sortBy.value === 'grade') {
        transcripts.value.sort((a, b) => {
          let gradeA = a.grade ?? 0
          let gradeB = b.grade ?? 0
          return sortDirection.value === 'desc'
            ? gradeB - gradeA
            : gradeA - gradeB
        })
      } else if (sortBy.value === 'creditHours') {
        transcripts.value.sort((a, b) => {
          return sortDirection.value === 'desc'
            ? b.creditHours - a.creditHours
            : a.creditHours - b.creditHours
        })
      }
    }

    const completed = computed(() => transcripts.value
      .filter(transcript => !!transcript.completionDate)
      .filter(t => filterByDate(t.completed as string)),
    )
    const inProgress = computed(() => transcripts.value.filter(
      transcript => !transcript.completionDate && transcript.started).filter(t =>
      filterByDate(t.createDate as string),
    ))
    const filterByBody = () => {
      completedByBody.value = completed.value
        .map(t => t.certificateCredits.map((body) => {
          return {
            'body': body.Name,
            'completeDate': format(parseISO(t.completionDate as string), 'MMM d, y'),
            ...t,
          }
        }))
        .flat() as TranscriptItem[]
    }
    const setDownload = (showError: boolean) => {
      showDownloadError.value = showError
    }
    const printTranscript = (printContent: string): void => {
      filterByBody()
      print(printContent)
    }
    const handleSsoLink = async (uri: string) => {
      try {
        hasLoaded.value = false
        hasErrors.value = false
        let ssoLink = await getRLPSSOToken(getStoreName(), getBearerToken(), uri)
        window.open(ssoLink ?? '', '_blank')
        hasLoaded.value = true
      } catch (e) {
        hasErrors.value = true
        hasLoaded.value = true
        console.log(e)
      }
    }

    const getTranscripts = async () => {
      try {
        hasLoaded.value = false
        hasErrors.value = false
        let results = await getN8nTranscripts({
          externalLearnerId: String(customer.value?.entity_id ?? ''),
          // courseEnrollmentType: 1 or 2
          // completed: bool
        })
        if (results) {
          results.forEach(r => r.completionDate = r.completed ? r.completed : '')
          transcripts.value = results
          localStorage.setItem('transcripts', JSON.stringify(results))
        }
      } catch (error) {
        hasErrors.value = true
      } finally {
        hasLoaded.value = true
      }
    }
    watch(customer, async () => await getTranscripts())
    watch(sortDirection, () => sortTranscripts())

    onMounted(async (): Promise<void> => {
      let transcriptsLoaded = localStorage.getItem('transcripts')
      if (transcriptsLoaded) {
        transcripts.value = JSON.parse(transcriptsLoaded)
      } else {
        await getTranscripts()
      }
      filterByBody()
      sortTranscripts()
      hasLoaded.value = true
    })
    return {
      handleSsoLink,
      completed,
      showDownloadError,
      hasErrors,
      hasLoaded,
      inProgress,
      setDownload,
      transcripts,
      customerLicenseId,
      customerName,
      logo,
      printTranscript,
      completedByBody,
      fromDate,
      toDate,
      filterByBody,
      sortTranscripts,
      sortOptions,
      sortBy,
      sortDirection,
    }
  },
})
