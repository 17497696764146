
import { defineComponent, ref } from 'vue'
import TableSkeleton from '../../../skeletons/TableSkeleton.vue'
import AppContentWrapper from '../../../global/AppContentWrapper.vue'
import { getDownloadableProduct } from '../../../../../../global/js/magento-api-library/products/getDownloadableProducts'
import { log } from '../../../../../../global/js/utils/logging'
import DownloadableFilesTableRow from './DownloadableFilesTableRow.vue'

export default defineComponent({
  name: 'DownloadableFilesTable',
  components: {
    DownloadableFilesTableRow,
    TableSkeleton,
    AppContentWrapper,
  },
  setup() {
    const hasLoaded = ref<boolean>(false)
    const hasErrors = ref<boolean>(false)

    const getProductCollection = async (): Promise<{ title: string, downloadLink: string, bookTest: string,
      status: string }[] | null> => {
      
      const result = []
      
      try {
        const products = await getDownloadableProduct()
        
        if (products != null) {
          for (let i = 0; i < products?.items.length; i++) {
            result.push({
              title: products?.items[i].product_name,
              downloadLink: products?.items[i].download_url,
              bookTest: products?.items[i].book_test,
              status: products?.items[i].status,
            })
          }
        }
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/account/tabs/content/DownloadableFilesTable.vue')
      }

      if (result.length > 0) {
        return result
      } else {
        return null
      }
    }

    return {
      getProductCollection,
      hasLoaded,
      hasErrors,
    }
  },
  data() {
    return {
      productCollection: [{
        title: '',
        downloadLink: '',
        bookTest: '',
        status: '',
      }],
      showProductData: false,
      loadingData: true,
    }
  },
  async created() {
    const collection = await this.getProductCollection()

    if (collection != null) {
      this.productCollection = collection
      this.showProductData = true
    }

    this.hasLoaded = true
  },
})
