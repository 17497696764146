import Cookies from 'js-cookie'
import { computed, ComputedRef } from 'vue'

import useAuth from './useAuth'
import { useStore } from '../store'
import Cart from '../../../global/js/magento-api-library/types/Cart'
import { CartMutationTypes } from '../store/modules/cart/mutation-types'
import { mergeCarts } from '../../../global/js/magento-api-library/cart/mergeCarts'
import { getGuestCart } from '../../../global/js/magento-api-library/cart/getGuestCart'
import { createEmptyCart } from '../../../global/js/magento-api-library/cart/createEmptyCart'
import { getAuthUserCart } from '../../../global/js/magento-api-library/cart/getAuthUserCart'
import { setBillingAddressOnCart } from '../../../global/js/magento-api-library/cart/setBillingAddressOnCart'
import { getCustomer } from '../../../global/js/magento-api-library/customer/getCustomer'
import { log } from '../../../global/js/utils/logging'
import { notify } from '@kyvg/vue3-notification'

interface UseCart {
  cart: ComputedRef<Cart>
  guestCartId: ComputedRef<string>
  isCartPage: ComputedRef<boolean>
  getCart: () => Promise<void>
  setCart: (cart: Cart) => void
  combineCarts: () => Promise<void>
  setMinicartCount: (count: number) => void
  getMinicartCount: () => number
}

const useCart = (): UseCart => {
  const store = useStore()
  const { isLoggedIn } = useAuth()
  const cart = computed((): Cart => store.state.cart.cart)

  const guestCartId = computed((): string => Cookies.get('cartId') || '')

  const setMinicartCount = (count: number) => {
    store.commit(CartMutationTypes.MINICART_COUNT, count)
  }

  const getMinicartCount = () => {
    return store.state.cart.minicartCount
  }

  const isCartPage = computed((): boolean => window.location.pathname === '/cart')

  const getCart = async (): Promise<void> => {
    let cart

    if (isLoggedIn.value) {
      try {
        cart = await getAuthUserCart()
      } catch(error){
        const cartError = error as {message: string, cart: Cart}
        if(cartError) {
          const customer = await getCustomer()
          const invalidCart = cartError.cart
          if (invalidCart && invalidCart.billing_address === null && customer) {
            // set billing address
            const customerAddress = customer.addresses[0]
            const billingAddress = {
              id: customerAddress.id,
              street: customerAddress?.street[0],
              suite: customerAddress?.street[1],
              city: customerAddress?.city,
              state: customerAddress.region.region || '',
              stateCode: customerAddress?.region.region_code || '',
              postalCode: customerAddress?.postcode || '',
              country: customerAddress?.country_code,
              regionID: customerAddress?.region.region_id,
              company: customerAddress?.company,
              phoneNumber: customerAddress?.telephone,
              faxNumber: customerAddress?.fax,
              firstName: customer.firstname,
              lastName: customer.lastname,
            }
            await setBillingAddressOnCart(invalidCart.id, {
              sameAsShipping: true,
              address: billingAddress,
            })
          } else {
            log(cartError.message, 'src/reliasmedia/js/composables/useCart.ts')

            await notify({
              type: 'error',
              title: 'Error!',
              text: 'Your cart could not be initialized.',
            })
            return
          }
        }
      }
      if(!cart){
        try {
          cart = await getAuthUserCart()
        } catch(error){
          log(error as string, 'src/reliasmedia/js/composables/useCart.ts')

          await notify({
            type: 'error',
            title: 'Error!',
            text: 'There is an error with your cart.',
          })
        }
      }
    } else if (guestCartId.value) {
      try {
        cart = await getGuestCart(guestCartId.value)
      } catch(error){
        log(error as string, 'src/reliasmedia/js/composables/useCart.ts')

        await notify({
          type: 'error',
          title: 'Error!',
          text: 'There is an error with your cart.',
        })
      }
    } else {
      const emptyCartId = await createEmptyCart()

      if (emptyCartId) {
        try{
          cart = await getGuestCart(emptyCartId)
        }
        catch(error) {
          log(error as string, 'src/reliasmedia/js/composables/useCart.ts')

          await notify({
            type: 'error',
            title: 'Error!',
            text: 'There is an error with your cart.',
          })
        }

        Cookies.set('cartId', emptyCartId)
      }
    }

    if (cart) {
      store.commit(CartMutationTypes.SET_CART, cart)
    }
  }

  const setCart = (cart: Cart) => {
    store.commit(CartMutationTypes.SET_CART, cart)
  }

  const combineCarts = async (): Promise<void> => {
    await mergeCarts(guestCartId.value)

    Cookies.remove('cartId')
  }

  return {
    cart,
    getCart,
    setCart,
    isCartPage,
    guestCartId,
    combineCarts,
    setMinicartCount,
    getMinicartCount,
  }
}

export default useCart
