import NavTabItem from './NavTabItem.vue'
import MobileNavToggler from './MobileNavToggler.vue'
import MobileNavigation from './MobileNavigation.vue'
import DesktopNavigation from './DesktopNavigation.vue'
import UtilityNavigation from './UtilityNavigation.vue'
import DesktopNavToggler from './DesktopNavToggler.vue'
import UtilityNavigationSearch from './UtilityNavigationSearch.vue'

const navigationComponents = [
  NavTabItem,
  MobileNavToggler,
  MobileNavigation,
  DesktopNavigation,
  DesktopNavToggler,
  UtilityNavigation,
  UtilityNavigationSearch,
]

export default navigationComponents
