
import {computed, defineComponent, PropType, reactive, ref, toRefs, watch} from 'vue'
import AppTextInput from '../../../global/forms/inputs/AppTextInput.vue'
import { errorMessage } from '../../../../store/modules/ui/errorMessage'
import { useField, useForm } from 'vee-validate'

interface UserPassword {
  currentPassword: string
  newPassword: string
  newPasswordConfirmation: string
}

export default defineComponent({
  name: 'PasswordFields',
  components: {
    AppTextInput,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserPassword>,
      required: true,
    },
  },
  emits:['isPasswordValid'],
  setup(props, context) {
    const { modelValue } = toRefs(props)

    const { meta: formMeta } = useForm()
    const newPasswordField = reactive(useField('password', {password: true}))
    const newPasswordConfirmField = reactive(useField('confirmPassword', 'confirmPassword:@password'))
    const newPasswordFieldError = ref(null)
    const newPasswordConfirmFieldError = ref(null)

    const local = computed((): UserPassword => modelValue.value)

    watch([newPasswordFieldError, newPasswordConfirmFieldError], (): void => {
      if(newPasswordFieldError.value === null && newPasswordConfirmFieldError.value === null) {
        context.emit('isPasswordValid', true)
      }
      else {
        context.emit('isPasswordValid', false)
      }
    })

    return {
      local,
      errorMessage,
      formMeta,
      newPasswordField,
      newPasswordConfirmField,
      newPasswordFieldError,
      newPasswordConfirmFieldError,
    }
  },
})
