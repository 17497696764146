
import isEqual from 'lodash.isequal'
import { defineComponent, toRefs, computed } from 'vue'

export default defineComponent({
  name: 'AppRadioInput',
  props: {
    modelValue: {
      type: [String, Object],
      required: true,
    },
    value: {
      type: [String, Object],
      required: false,
      default: '',
    },
    id: {
      type: String,
      required: true,
    },
    name: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      required: false,
      default: '',
    },
    labelClasses: {
      type: String,
      required: false,
      default: '',
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props, { emit }) {
    const { modelValue, value } = toRefs(props)

    const local = computed({
      get: () => modelValue.value,
      set: (value) => {
        emit('update:modelValue', value)
      },
    })

    const checked = computed((): boolean => isEqual(modelValue.value, value.value))

    return {
      local,
      checked,
    }
  },
})
