
import { computed, defineComponent, PropType } from 'vue'

import OrderItem from '../../../../../global/js/magento-api-library/types/OrderItem'

export default defineComponent({
  name: 'OrderDetailsTableRow',
  props: {
    orderItem: {
      type: Object as PropType<OrderItem>,
      required: true,
    },
  },
  setup(props) {
    const discount = computed((): string => {
      return props.orderItem.discounts.length
        ? `$${props.orderItem.discounts[0].amount.value.toFixed(2)}`
        : ''
    })

    const netPrice = computed((): string => {
      return props.orderItem.discounts.length
        ? (props.orderItem.product_sale_price.value - props.orderItem.discounts[0].amount.value).toFixed(2)
        : props.orderItem.product_sale_price.value.toFixed(2)
    })

    return {
      discount,
      netPrice,
    }
  },
})
