
import {defineComponent, onMounted, ref} from 'vue'

import useAuth from '../../composables/useAuth'
import useModal from '../../composables/useModal'
import {getCourseStartUrl} from '../../../../global/js/magento-api-library/customer/getCourseStartUrl'
import { log } from '../../../../global/js/utils/logging'

export default defineComponent({
  name: 'StartIssueTestLink',
  props: {
    issueCode: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {isLoggedIn} = useAuth()
    const testStartLink = ref<string>('')
    const {setOpenedModal, resetOpenedModal} = useModal()
    const popupDuration = 3000
    const popupTimeOut = 3005

    const handleAuthenticated = async (): Promise<void> => {
      try {
        if (props.issueCode) {
          const startLink = await getCourseStartUrl(props.issueCode)

          if (startLink) {
            testStartLink.value = startLink
          }
        }
      } catch (e) {
        log(e as string, 'src/reliasmedia/js/components/issues/StartIssueTestLink.vue')
      }
    }

    const showModal = async () => {
      try {
        setOpenedModal('redirectNotice')
        setTimeout(async () => {
          window.open(testStartLink.value, '_blank')
        }, popupDuration)

        setTimeout(async () => {
          resetOpenedModal()
        }, popupTimeOut)

      } catch (e) {
        log(e as string, 'src/reliasmedia/js/components/issues/StartIssueTestLink.vue')
      }
    }

    onMounted(async (): Promise<void> => {
      if (isLoggedIn.value) {
        await handleAuthenticated()
      }
    })

    return {
      isLoggedIn,
      testStartLink,
      showModal,
      handleAuthenticated,
    }
  },
})
