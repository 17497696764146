import gql from '../../../utils/gql'

const cartFragment = gql`
  fragment CartFields on Cart {
    id
    email
    total_quantity
    prices {
      subtotal_excluding_tax {
        value
        currency
      }
      grand_total {
        value
        currency
      }
      applied_taxes {
        amount {
          value
          currency
        }
      }
    }
    applied_coupons {
      code
    }
    is_virtual
    items {
      uid
      quantity
      ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
      product {
        __typename
        sku
        name
        image {
          url
          label
        }
        small_image {
          url
          label
        }
      }
      prices {
        price {
          value
          currency
        }
        discounts {
          amount {
            value
            currency
          }
        }
        row_total {
          value
          currency
        }
        total_item_discount {
          value
          currency
        }
      }
    }
    billing_address {
      firstname
      lastname
      telephone
      company
      street
      city
      region {
        code
        label
        region_id
      }
      postcode
      country {
        code
        label
      }
    }
    shipping_addresses {
      firstname
      lastname
      telephone
      company
      street
      city
      region {
        code
        label
        region_id
      }
      postcode
      country {
        code
        label
      }
      available_shipping_methods {
        carrier_code
        carrier_title
        method_code
        method_title
        amount {
          value
          currency
        }
      }
      selected_shipping_method {
        carrier_code
        carrier_title
        method_code
        method_title
        amount {
          value
          currency
        }
      }
    }
    available_payment_methods {
      code
      title
    }
    selected_payment_method {
      code
      title
    }
  }
`

export default cartFragment
