import { createFocusTrap, FocusTrap } from 'focus-trap'

interface FocusTrapBinding {
  value: boolean
}

let trap: FocusTrap

const createTrap = (element: HTMLElement): void => {
  trap = createFocusTrap(element, {
    escapeDeactivates: true,
    allowOutsideClick: true,
  })
}

const focusTrap = {
  updated(element: HTMLElement, binding: FocusTrapBinding): void {
    if (!trap && binding.value) {
      createTrap(element)

      setTimeout(() => {
        trap.activate()
      })
    } else if (trap && !binding.value) {
      trap.deactivate()
    }
  },
}

export default focusTrap
