import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface GetTranscriptsResponse {
  data: {
    getCustomerCertificates: {
      certificateLocation: string
    } | null
  }
  errors?: [{ message: string }]
}

export const customerTranscriptsQuery = gql`
    query (
        $externalLearnerId: String!,
        $courseEnrollmentId: Int!
        ) {
        getCustomerCertificates(
            externalLearnerId: $externalLearnerId
            courseEnrollmentId: $courseEnrollmentId
            ) {
            certificateLocation
        }
    }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const getTranscriptDownload = async (courseEnrollmentId: number, externalLearnerId: string): Promise<string | null> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<GetTranscriptsResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: customerTranscriptsQuery,
      variables: {
        externalLearnerId: externalLearnerId,
        courseEnrollmentId: courseEnrollmentId,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.getCustomerCertificates) {
    return data.data.getCustomerCertificates.certificateLocation
  }

  return null
}
