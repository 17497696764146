import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface UpdatePasswordResponse {
  data: {
    changeCustomerPassword: {
      firstname: string
      lastname: string
    } | null
  }
  errors?: [{ message: string }]
}

export const updatePasswordMutation = gql`
  mutation($currentPassword: String!, $newPassword: String!) {
    changeCustomerPassword(currentPassword: $currentPassword, newPassword: $newPassword) {
      firstname
      lastname
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const updatePassword = async (
  currentPassword: string,
  newPassword: string,
): Promise<void> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<UpdatePasswordResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: updatePasswordMutation,
      variables: {
        currentPassword,
        newPassword,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }
}
