import Minicart from './cart/Minicart.vue'
import CartTable from './cart/CartTable.vue'
import CartTableRow from './cart/CartTableRow.vue'
import CartAddressForm from './CartAddressForm.vue'
import CartPromoCodeRow from './cart/CartPromoCodeRow.vue'
import CartPromoCodeForm from './cart/CartPromoCodeForm.vue'
import CartItemsTable from './confirmation/CartItemsTable.vue'
import OrderDetailsTable from './details/OrderDetailsTable.vue'
import CartAddressesContainer from './CartAddressesContainer.vue'
import OrderDetailsTableRow from './details/OrderDetailsTableRow.vue'
import OrderDetailsAddresses from './details/OrderDetailsAddresses.vue'
import OrderDetailsContainer from './details/OrderDetailsContainer.vue'
import CartAddressInfoItem from './confirmation/CartAddressInfoItem.vue'
import CartBillingContactForm from './confirmation/CartBillingContactForm.vue'
import CartConfirmationContainer from './confirmation/CartConfirmationContainer.vue'

const checkoutComponents = [
  Minicart,
  CartTable,
  CartTableRow,
  CartItemsTable,
  CartAddressForm,
  CartPromoCodeRow,
  CartPromoCodeForm,
  OrderDetailsTable,
  CartAddressInfoItem,
  OrderDetailsTableRow,
  OrderDetailsAddresses,
  OrderDetailsContainer,
  CartAddressesContainer,
  CartBillingContactForm,
  CartConfirmationContainer,
]

export default checkoutComponents
