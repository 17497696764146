
import {computed, defineComponent, watch, ref} from 'vue'

import CartTableRow from '../cart/CartTableRow.vue'
import CartPromoCodeRow from '../cart/CartPromoCodeRow.vue'
import AppInlineAlert from '../../global/AppInlineAlert.vue'
import SelectedShippingMethod from '../../../../../global/js/magento-api-library/types/SelectedShippingMethod'
import useCart from '../../../composables/useCart'

export default defineComponent({
  name: 'CartItemsTable',
  components: {
    CartTableRow,
    AppInlineAlert,
    CartPromoCodeRow,
  },
  setup() {
    const {cart} = useCart()

    const shippingTotal = ref(0)
    const grandTotal = ref(parseFloat(cart.value.prices.grand_total.value.toFixed(2)))

    const shippingMethod = computed((): SelectedShippingMethod | null => {
      if (cart.value.shipping_addresses[0]?.selected_shipping_method) {
        return cart.value.shipping_addresses[0].selected_shipping_method
      } else {
        return null
      }
    })

    const taxTotal = computed(() => {
      if (cart.value.prices.applied_taxes[0]?.amount?.value) {
        let total_tax = cart.value.prices.applied_taxes.reduce((a, b) => {
          return a + b.amount.value
        }, 0)
        return parseFloat(total_tax.toFixed(2))
      } else {
        return 0
      }
    })

    const carrierCode = computed((): string | undefined => {
      return shippingMethod.value?.carrier_code
    })

    if (shippingMethod.value !== null) {
      shippingTotal.value = parseFloat(shippingMethod.value.amount.value.toFixed(2))
    }

    const showShipping = computed((): boolean => {
      // Show shipping cost if physical products are in cart
      let hasPhysicalItems =  !(cart.value.items.find(item => item.product.__typename === 'SimpleProduct') === undefined)
      if (!hasPhysicalItems && !cart.value.is_virtual) {
        hasPhysicalItems = true
      }
      return hasPhysicalItems
    })

    watch(
      () => shippingMethod.value?.carrier_code,
      async (): Promise<void> => {
        grandTotal.value = parseFloat(cart.value.prices.grand_total.value.toFixed(2))

        if (shippingMethod.value !== null) {
          shippingTotal.value = parseFloat(shippingMethod.value.amount.value.toFixed(2))
        }
      },
    )

    return {
      cart,
      grandTotal,
      shippingTotal,
      showShipping,
      carrierCode,
      taxTotal,
    }
  },
})
