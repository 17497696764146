
import { defineComponent } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'AppFormErrorAlert',
  components: {
    FontAwesomeIcon,
  },
  props: {
    teleportTo: {
      type: String,
      required: true,
    },
    headingText: {
      type: String,
      required: true,
    },
  },
})
