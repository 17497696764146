
import {computed, defineComponent, onMounted, ref, watch} from 'vue'

import CartAddressInfoItem from './CartAddressInfoItem.vue'
import AppRadioInput from '../../global/forms/inputs/AppRadioInput.vue'
import Cart from '../../../../../global/js/magento-api-library/types/Cart'
import useCart from '../../../composables/useCart'
import useNotify from '../../../composables/useNotify'
import ShippingMethod from '../../../types/ShippingMethod'
import CartAddress from '../../../../../global/js/magento-api-library/types/CartAddress'
import { setShippingMethodsOnCart } from '../../../../../global/js/magento-api-library/cart/setShippingMethodsOnCart'

export default defineComponent({
  name: 'CartAddressInfo',
  components: {
    AppRadioInput,
    CartAddressInfoItem,
  },
  setup() {
    const { cart, setCart } = useCart()
    const { notify } = useNotify()
    const hasPhysicalItems = ref<boolean>(false)

    const selectedShippingOption = ref({
      carrier_code: '',
      method_code: '',
    })

    const isLoading = ref(false)

    const shippingAddress = computed((): CartAddress | null => cart.value.shipping_addresses[0])

    const billingAddress = computed((): CartAddress | null => cart.value.billing_address || null)

    const showShippingOptions = computed((): boolean => {
      if (shippingAddress.value && shippingAddress.value.available_shipping_methods) {
        return shippingAddress.value.available_shipping_methods.length > 0
      }

      return false
    })

    const shippingOptions = computed((): ShippingMethod[] => shippingAddress.value?.available_shipping_methods || [])

    const setShippingMethod = async (): Promise<Cart | null> => {

      let updatedCart = null

      try {
        updatedCart = await setShippingMethodsOnCart(cart.value.id, [selectedShippingOption.value])

        if (updatedCart != null) {
          setCart(updatedCart)
        }
      } catch (error) {
        notify({
          type: 'error',
          title: 'Error',
          text: 'Shipping method could not be set on your cart.',
          duration: -1,
        })
      }

      return updatedCart
    }

    watch(
      selectedShippingOption,
      async (): Promise<void> => {
        isLoading.value = true
        const updatedCart = await setShippingMethod()
        if (updatedCart === null) {
          notify({
            type: 'error',
            title: 'Error',
            text: 'Shipping method could not be set on your cart.',
            duration: -1,
          })
        }
        isLoading.value = false
      },
    )

    onMounted(async (): Promise<void> => {
      hasPhysicalItems.value = !(cart.value.items.find(item => item.product.__typename === 'SimpleProduct') === undefined)

      if (!hasPhysicalItems.value && !cart.value.is_virtual) {
        hasPhysicalItems.value = true
      }

      if (shippingOptions.value.length > 0) {
        if (cart.value.shipping_addresses[0].selected_shipping_method?.carrier_code) {
          selectedShippingOption.value.carrier_code = cart.value.shipping_addresses[0].selected_shipping_method?.carrier_code
          selectedShippingOption.value.method_code = cart.value.shipping_addresses[0].selected_shipping_method?.method_code
        } else {
          selectedShippingOption.value.carrier_code = shippingOptions.value[0].carrier_code
          selectedShippingOption.value.method_code = shippingOptions.value[0].method_code
        }
        await setShippingMethod()
      }
    })

    return {
      cart,
      billingAddress,
      shippingAddress,
      shippingOptions,
      showShippingOptions,
      selectedShippingOption,
      hasPhysicalItems,
      isLoading,
    }
  },
})
