
import {defineComponent} from 'vue'
import useModal from '../../composables/useModal'
import AppModal from '../global/AppModal.vue'

export default defineComponent({
  name: 'ProductColumns',
  props: {
    id: {
      type: String,
      required: true,
    },
    theme: {
      type: String
    }
  },
  components: {
    AppModal,
    useModal
  },
  setup() {
    const { openedModal, setOpenedModal } = useModal()

    return {
      setOpenedModal
    }
  }
})
  