
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'AppButton',
  props: {
    type: {
      type: String,
      required: false,
      default: 'button',
    },
    text: {
      type: String,
      required: false,
      default: '',
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
})
