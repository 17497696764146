import { computed, ComputedRef } from 'vue'

import { useStore } from '../../store/index'
import Order from '../../../../global/js/magento-api-library/types/Order'
import { AccountMutationTypes } from '../../store/modules/account/mutation-types'

interface UseOrderHistory {
  activeOrder: ComputedRef<Order>
  setActiveOrder: (order: Order) => void
}

const useOrderHistory = (): UseOrderHistory => {
  const store = useStore()

  const activeOrder = computed((): Order => store.state.account.activeOrder)

  const setActiveOrder = (order: Order): void => {
    store.commit(AccountMutationTypes.SET_ACTIVE_ORDER, order)
  }

  return {
    activeOrder,
    setActiveOrder,
  }
}

export default useOrderHistory
