import axios, { AxiosResponse } from 'axios'

import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface RemoveCouponFromCartResponse {
  data: {
    removeCouponFromCart: { cart: Cart } | null
  }
  errors?: [{ message: string }]
}

export const removeCouponFromCartMutation = gql`
  ${cartFragment}
  mutation($cartId: String!) {
    removeCouponFromCart(input: { cart_id: $cartId }) {
      cart {
        ...CartFields
      }
    }
  }
`

export const removeCouponFromCart = async (cartId: string): Promise<Cart | null> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<RemoveCouponFromCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: removeCouponFromCartMutation,
      variables: {
        cartId,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.removeCouponFromCart) {
    return data.data.removeCouponFromCart.cart
  }

  return null
}
