// Helper function to parse date, convert to UNIX timestamp, and update the refinement
const updateRefinement = (helper, attribute, operator, dateStr) => {
  const milliseconds = Date.parse(dateStr)
  const seconds = milliseconds / 1000 // UNIX timestamps must be in seconds
  
  //Need to remove the old refinement filter otherwise it just keeps adding additional filters
  //https://github.com/monthly-cloud/property-instantsearch/blob/develop/resources/assets/js/components/Ais/DatePicker.vue
  helper.removeNumericRefinement(attribute, operator)
  helper.addNumericRefinement(attribute, operator, seconds).search()
}

// Function to create the refine logic
const createRefine = ({ helper }) => ({ from, to }) => {

  // Update the start date refinement
  updateRefinement(helper, 'post_date_filter', '>=', from)

  // Update the end date refinement
  updateRefinement(helper, 'post_date_filter', '<=', to)
}

// Function to connect the date range widget
export const connectDateRange = (render, unmount) => (widgetParams = {}) => {
  const { from, to, restrictResultsToStrictRange } = widgetParams

  return {
    init({ instantSearchInstance, helper }) {
      render({
        instantSearchInstance,
        refine: createRefine({
          helper,
          attributes: { from, to },
          restrictResultsToStrictRange,
        }),
      }, true)
    },
    render({ instantSearchInstance, helper }) {
      render({
        instantSearchInstance,
        refine: createRefine({
          helper,
          attributes: { from, to },
          restrictResultsToStrictRange,
        }),
      }, false)
    },
    dispose() {
      unmount()
    },
  }
}
