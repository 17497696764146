
import { computed, defineComponent, PropType } from 'vue'

import NavTab from '../../types/NavTab'
import NavTabDropdown from './NavTabDropdown.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'NavTabItem',
  components: {
    FontAwesomeIcon,
    NavTabDropdown,
  },
  props: {
    tab: {
      type: Object as PropType<NavTab>,
      required: true,
    },
    activeTab: {
      type: Object as PropType<NavTab>,
      required: false,
      default: null,
    },
  },
  emits: ['updateActiveTab'],
  setup(props, { emit }) {
    const isActiveTab = computed((): boolean => props.activeTab?.tabSlug === props.tab.tabSlug)

    const setActiveTab = (): void => {
      const newActiveTab = props.activeTab?.tabSlug === props.tab.tabSlug
        ? null
        : {
          tabSlug: props.tab.tabSlug,
          tabTitle: props.tab.tabTitle,
          links: props.tab.links,
        }

      emit('updateActiveTab', newActiveTab)
    }

    const focusSubNavLink = (): void => {
      const subNav = document.getElementById(`js-subnav-menu-${props.tab.tabSlug}`)

      if (subNav) {
        const subNavLinks: HTMLAnchorElement[] = Array.from(subNav.querySelectorAll('li a'))

        subNavLinks[0].focus()
      }
    }

    return {
      isActiveTab,
      setActiveTab,
      focusSubNavLink,
    }
  },
})
