
import { defineComponent } from 'vue'
import FormSectionSkeleton from './FormSectionSkeleton.vue'

export default defineComponent({
  name: 'FormSkeleton',
  components: {
    FormSectionSkeleton,
  },
  props: {
    count: {
      type: Number,
      required: false,
      default: 2,
    },
  },
})
