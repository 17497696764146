
import { defineComponent } from 'vue'

import useMobileNav from '../../composables/useMobileNav'
import {FontAwesomeIcon} from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'MobileNavToggler',
  components: { FontAwesomeIcon },
  setup() {
    const { mobileNavIsOpen, toggleMobileNav } = useMobileNav()

    return {
      mobileNavIsOpen,
      toggleMobileNav,
    }
  },
})
