import { MutationTree } from 'vuex'

import { State } from './state'
import { CustomerMutationTypes } from './mutation-types'
import Customer from '../../../../../global/js/magento-api-library/types/Customer'

export interface Mutations {
  [CustomerMutationTypes.SET_CUSTOMER]: (state: State, customer: Customer) => void
}

export const mutations: MutationTree<State> & Mutations = {
  [CustomerMutationTypes.SET_CUSTOMER]: (state: State, customer: Customer) => {
    state.customer = customer
  },
}
