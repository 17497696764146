
import { defineComponent, reactive, ref } from 'vue'

import { errorMessage } from '../../store/modules/ui/errorMessage'
import { useField, useForm } from 'vee-validate'
import { login } from '../../../../global/js/magento-api-library/auth/login'
import { passwordReset } from '../../../../global/js/magento-api-library/auth/passwordReset'
import { log } from '../../../../global/js/utils/logging'
import useLoader from '../../composables/useLoader'
import useNotify from '../../composables/useNotify'

export default defineComponent({
  name: 'ResetPasswordForm',
  setup() {
    const { meta: formMeta } = useForm()
    const { setLoading } = useLoader()
    const { notify } = useNotify()
    const displayAlert = ref<boolean>(false)

    const passwordField = reactive(useField('password', { password: true, required: true }))
    const confirmPasswordField = reactive(useField('confirmPassword', 'confirmPassword:@password'))

    const form = ref({
      email: '',
      newPassword: '',
      newPasswordConfirmation: '',
    })

    const handleSubmit = async (): Promise<void> => {
      displayAlert.value = false
      try {
        setLoading(true)

        const token = new URLSearchParams(window.location.search).get('token') || ''
        const email = new URLSearchParams(window.location.search).get('email') || ''
        const replacedSpaceEmail = email.replace(/\s+/g, '+')
        const response = await passwordReset(replacedSpaceEmail, token, form.value.newPassword)

        if (response === true) {
          await login(replacedSpaceEmail, form.value.newPassword)

          notify({
            type: 'success',
            title: 'Success!',
            text: 'Your account information was updated.',
          })

          window.location.assign('/')
        }
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/auth/ResetPasswordForm.vue')
        displayAlert.value = true
      } finally {
        setLoading(false)
      }
    }

    return {
      form,
      errorMessage,
      handleSubmit,
      passwordField,
      confirmPasswordField,
      formMeta,
      displayAlert,
    }
  },
})
