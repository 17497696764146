
import { defineComponent, ref } from 'vue'

const alertTypes = ['primary', 'success', 'info', 'warning', 'danger']

export default defineComponent({
  name: 'AppAlert',
  props: {
    alertText: {
      type: String,
      required: true,
    },
    alertType: {
      type: String,
      required: false,
      default: 'primary',
      validator: (value: string): boolean => alertTypes.includes(value),
    },
    allowClose: {
      type: Boolean,
      required: false,
      default: true,
    },
  },
  setup() {
    const isOpen = ref<boolean>(true)

    const closeAlert = (): void => {
      isOpen.value = false
    }

    return {
      isOpen,
      closeAlert,
    }
  },
})
