import axios, { AxiosResponse } from 'axios'

import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'
import PaymentMethodInput from '../types/PaymentMethodInput'

interface SetPaymentMethodOnCart {
  data: {
    setPaymentMethodOnCart: { cart: Cart } | null
  }
  errors?: [{ message: string }]
}

export const setPaymentMethodOnCartMutation = gql`
  ${cartFragment}
  mutation($cartId: String!, $paymentMethod: PaymentMethodInput!) {
    setPaymentMethodOnCart(input: { cart_id: $cartId, payment_method: $paymentMethod }) {
      cart {
        ...CartFields
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const setPaymentMethodOnCart = async (
  cartId: string,
  paymentMethod: PaymentMethodInput,
): Promise<Cart | null> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<SetPaymentMethodOnCart> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: setPaymentMethodOnCartMutation,
      variables: {
        cartId,
        paymentMethod,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.setPaymentMethodOnCart) {
    return data.data.setPaymentMethodOnCart.cart
  }

  return null
}
