
import { defineComponent, reactive, ref } from 'vue'

import AppInlineAlert from '../global/AppInlineAlert.vue'
import { errorMessage } from '../../store/modules/ui/errorMessage'
import { useField, useForm } from 'vee-validate'
import { passwordResetEmail } from '../../../../global/js/magento-api-library/auth/passwordResetEmail'
import useLoader from '../../composables/useLoader'

export default defineComponent({
  name: 'ForgotPasswordForm',
  components: {
    AppInlineAlert,
  },
  setup() {
    const displayAlert = ref<boolean>(false)

    const { setLoading } = useLoader()
    const { meta: formMeta } = useForm()
    const emailField = reactive(useField('email', { email: true, required: true}))

    let failureAlert = ref(false)

    const form = ref({
      email: '',
    })

    const disableFailureAlert = ()=> {
      failureAlert.value = false
    }

    const handleSubmit = async (): Promise<void> => {
      setLoading(true)
      const wasSuccessful = await passwordResetEmail(form.value.email)

      if (wasSuccessful) {
        setLoading(false)
        displayAlert.value = true

        form.value.email = ''
      } else {
        setLoading(false)
        failureAlert.value = true
      }

    }

    return {
      form,
      displayAlert,
      errorMessage,
      handleSubmit,
      formMeta,
      emailField,
      failureAlert,
      disableFailureAlert,
    }
  },
})
