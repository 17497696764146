import AccountTabs from './AccountTabs.vue'
import AccountTab from './tabs/AccountTab.vue'
import ContentTab from './tabs/ContentTab.vue'
import EmailField from './tabs/account/EmailField.vue'
import NameFields from './tabs/account/NameFields.vue'
import ReviewField from './tabs/account/ReviewField.vue'
import OrderHistoryTab from './tabs/OrderHistoryTab.vue'
import SubscriptionsTab from './tabs/SubscriptionsTab.vue'
import AddressFields from './tabs/account/AddressFields.vue'
import PasswordFields from './tabs/account/PasswordFields.vue'
import ProfessionalFields from './tabs/account/ProfessionalFields.vue'
import TranscriptsTab from './tabs/TranscriptsTab.vue'
import AccountManagementContainer from './AccountManagementContainer.vue'
import UserOrderInvoice from './UserOrderInvoice.vue'

const accountComponents = [
  AccountTab,
  ContentTab,
  EmailField,
  NameFields,
  AccountTabs,
  ReviewField,
  AddressFields,
  PasswordFields,
  OrderHistoryTab,
  SubscriptionsTab,
  ProfessionalFields,
  TranscriptsTab,
  AccountManagementContainer,
  UserOrderInvoice,
]

export default accountComponents
