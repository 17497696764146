
import { defineComponent, onBeforeMount, PropType, ref } from 'vue'

import MapSvg from './MapSvg.vue'
import MapForm from './MapForm.vue'
import MapStatesList from './MapStatesList.vue'

import MapState from '../../types/MapState'

export default defineComponent({
  name: 'MapContainer',
  components: {
    MapSvg,
    MapForm,
    MapStatesList,
  },
  props: {
    states: {
      type: Array as PropType<MapState[]>,
      required: true,
    },
  },
  setup() {
    const selectedState = ref<string>('')

    onBeforeMount((): void => {
      if (window.location.hash) {
        selectedState.value = window.location.hash.substring(2)
      }
    })

    return {
      selectedState,
    }
  },
})
