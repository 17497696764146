
import { defineComponent } from 'vue'
import { SplideSlide } from '@splidejs/vue-splide'

export default defineComponent({
  name: 'AppCarouselSlide',
  components: {
    SplideSlide,
  },
})
