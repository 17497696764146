
import { defineComponent, PropType } from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

// Temporary type -- this will be updated & moved when actual data is available
interface CategoryDropdownItem {
  title: string
  linkPath: string
}

export default defineComponent({
  name: 'ProductsCategoryDropdownItem',
  components: { FontAwesomeIcon },
  props: {
    item: {
      type: Object as PropType<CategoryDropdownItem>,
      required: true,
    },
  },
})
