
import { defineComponent, ref, PropType } from 'vue'

import NavTabItem from './NavTabItem.vue'
import UtilityNavigation from './UtilityNavigation.vue'

import NavTab from '../../types/NavTab'
import useMobileNav from '../../composables/useMobileNav'

export default defineComponent({
  name: 'MobileNavigation',
  components: {
    NavTabItem,
    UtilityNavigation,
  },
  props: {
    tabs: {
      type: Array as PropType<NavTab[]>,
      required: true,
    },
  },
  setup() {
    const activeTab = ref<NavTab | null>(null)
    const { mobileNavIsOpen, resetMobileNav } = useMobileNav()

    const updateActiveTab = (tab: NavTab | null): void => {
      activeTab.value = tab
    }

    return {
      activeTab,
      resetMobileNav,
      mobileNavIsOpen,
      updateActiveTab,
    }
  },
})
