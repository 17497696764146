
import { defineComponent } from 'vue'
import AppCarousel from '../global/AppCarousel.vue'
import AppCarouselSlide from '../global/AppCarouselSlide.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'ProductLandingCarousel',
  components: {
    FontAwesomeIcon,
    AppCarousel,
    AppCarouselSlide,
  },
})
