import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'
import {AxiosRequest} from '../../utils/request'
import {del} from 'idb-keyval'

interface GetAuthUserCartResponse {
  data?: {
    customerCart: Cart
  }
  errors?: [{ message: string }]
}

export const authUserCartQuery = gql`
    ${cartFragment}
    query {
        customerCart {
            ...CartFields
        }
    }
`

/**
 * Retrieve the cart for the authenticated user.
 *
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const getAuthUserCart = async (): Promise<Cart | null> => {
  const bearerToken = getBearerToken()
  return new Promise<Cart | null>(async (resolve, reject) => {
    await AxiosRequest.queuedCacheableRequest({
      method: 'POST',
      url: process.env.MIX_MAGENTO_GRAPHQL,
      headers: {
        'Content-Type': 'application/json', 
        Authorization: `Bearer ${bearerToken}`,
        Store: getStoreName(),
      },
      data: {
        query: authUserCartQuery,
      },
    }, {key: 'cart', expirationInSeconds: 300},
    (data: GetAuthUserCartResponse) => {
      if (data.errors) {
        del('cart')
        reject({message: data.errors[0].message, cart: data.data?.customerCart})
      }

      if (data.data) {
        resolve(data.data.customerCart)
      }

      resolve(null)
    },
    )
  })
}
