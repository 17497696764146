export interface State {
  mobileNavIsOpen: boolean
  openedModal: string
  modalIsOpen: boolean
  desktopNavIsOpen: boolean
}

export const state: State = {
  mobileNavIsOpen: false,
  openedModal: '',
  modalIsOpen: false,
  desktopNavIsOpen: false,
}
