
import { computed, defineComponent, nextTick, onBeforeMount, toRefs, watch } from 'vue'

export default defineComponent({
  name: 'MapSvg',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { modelValue } = toRefs(props)

    const local = computed({
      get: () => modelValue.value,
      set: (value) => {
        emit('update:modelValue', value)
      },
    })

    onBeforeMount(async (): Promise<void> => {
      if (local.value) {
        await nextTick()

        const state = document.getElementById(local.value)

        if (state) {
          state.style.setProperty('fill', '#1b65ab')
        }
      }
    })

    watch(local, async (newVal: string, oldVal: string): Promise<void> => {
      await nextTick()

      const newState = document.getElementById(newVal)
      const oldState = document.getElementById(oldVal)

      if (oldState) {
        oldState.style.removeProperty('fill')
      }

      if (newState) {
        newState.style.setProperty('fill', '#1b65ab')
      }
    })

    const handleClick = (event: MouseEvent): void => {
      const target = event.target as HTMLElement

      if (target.id) {
        local.value = target.id

        window.location.hash = `#!${target.id}`
      } else if (target.tagName === 'rect') {
        local.value = target.classList[0]

        window.location.hash = `#!${target.classList[0]}`
      }
    }

    return {
      handleClick,
    }
  },
})
