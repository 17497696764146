
import {computed, defineComponent, onMounted, PropType, reactive, ref, toRefs, watch} from 'vue'

import AppTextInput from '../../../global/forms/inputs/AppTextInput.vue'
import AppSearchableSelect from '../../../global/forms/inputs/AppSearchableSelect.vue'

import UserAddress from '../../../../types/UserAddress'
import useNotify from '../../../../composables/useNotify'
import useCustomer from '../../../../composables/useCustomer'
import Country from '../../../../../../global/js/magento-api-library/types/Country'
import CountryRegion from '../../../../../../global/js/magento-api-library/types/CountryRegion'
import { getCountries } from '../../../../../../global/js/magento-api-library/address/countries'
import { errorMessage } from '../../../../store/modules/ui/errorMessage'
import { useField, useForm } from 'vee-validate'

export default defineComponent({
  name: 'AddressFields',
  components: {
    AppTextInput,
    AppSearchableSelect,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserAddress>,
      required: true,
    },
  },
  emits:['isAddressValid'],
  setup(props, context) {
    const { notify } = useNotify()
    const { customer } = useCustomer()
    const { modelValue } = toRefs(props)

    const shouldDisplayState = ref<boolean>(true)
    const countryData = ref<Country[]>([])
    const countries = ref<{ label: string, value: string }[]>([])
    const selectedCountry = ref<{ label: string, value: string }>({
      label: '',
      value: '',
    })

    const stateData = ref<CountryRegion[]>([])
    const states = ref<{ label: string, value: string }[]>([])
    const selectedState = ref<{ label: string, value: string }>({
      label: '',
      value: '',
    })

    const { meta: formMeta } = useForm()
    const phoneField = reactive(useField('phone', {validPhoneNumber: true, required: true}))
    const streetField = reactive(useField('street', {required: true}))
    const cityField = reactive(useField('city', {required: true}))
    const postalCodeField = reactive(useField('postal_code', {validPostalCode: true, required: true}))
    const phoneFieldError = ref(null)
    const streetFieldError = ref(null)
    const cityFieldError = ref(null)
    const postalCodeFieldError = ref(null)

    const local = computed((): UserAddress => modelValue.value)

    watch([phoneFieldError, streetFieldError, cityFieldError, postalCodeFieldError], (): void => {
      if(phoneFieldError.value === null && streetFieldError.value === null && cityFieldError.value === null
          && postalCodeFieldError.value === null) {
        context.emit('isAddressValid', true)
      }
      else {
        context.emit('isAddressValid', false)
      }
    })

    onMounted(async (): Promise<void> => {
      try {
        countryData.value = await getCountries()

        countries.value = countryData.value.map((country: Country) => ({
          label: country.full_name_english,
          value: country.two_letter_abbreviation,
        }))

        if (customer.value && customer.value.addresses.length) {
          const currentAddress = customer.value.addresses[customer.value?.addresses.length - 1]

          if (currentAddress) {
            const country = countryData.value.find((countryToFind: Country) => {
              return countryToFind.two_letter_abbreviation === currentAddress.country_code
            }) as Country

            selectedCountry.value = {
              label: country.full_name_english,
              value: country.two_letter_abbreviation,
            }

            selectedState.value = {
              label: currentAddress.region.region || '',
              value: currentAddress.region.region_code || '',
            }
          }
        }
      } catch (error) {
        notify({
          type: 'error',
          title: 'Error',
          text: 'Countries could not be retrieved.',
          duration: -1,
        })
      }
    })

    watch(selectedCountry, (): void => {
      const country = countryData.value.find((countryToFind: Country) => {
        return countryToFind.two_letter_abbreviation === selectedCountry.value.value
      })

      local.value.country = selectedCountry.value.value
      if (country !== undefined) {
        if(country.available_regions) {
          stateData.value = country.available_regions

          states.value = country.available_regions.map((region: CountryRegion) => ({
            label: region.name,
            value: region.code,
          }))
          shouldDisplayState.value = true
        } else {
          selectedState.value = {label: '', value: ''}
          stateData.value = []
          states.value = []
          shouldDisplayState.value = false
        }
      }
    })

    watch(selectedState, (): void => {
      const state = stateData.value.find((stateToFind: CountryRegion) => {
        return stateToFind.code === selectedState.value.value
      })

      if(state !== undefined) {
        local.value.state = state.name
        local.value.stateCode = state.code
        local.value.regionID = state.id
      } else {
        local.value.state = ''
        local.value.stateCode = ''
        local.value.regionID = 0
      }
    })

    return {
      local,
      states,
      countries,
      selectedState,
      selectedCountry,
      shouldDisplayState,
      errorMessage,
      formMeta,
      phoneField,
      streetField,
      cityField,
      postalCodeField,
      phoneFieldError,
      streetFieldError,
      cityFieldError,
      postalCodeFieldError,
    }
  },
})
