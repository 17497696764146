import axios, { AxiosResponse } from 'axios'
import { AxiosRequest } from '../../utils/request'

import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'
import Cart from '../types/Cart'

interface CreateEmptyCartResponse {
  data: {
    createEmptyCart: string | null
  }
  errors?: [{ message: string }]
}
interface CustomerCartResponse {
  data: {
    customerCart: Cart | null
  }
  errors?: [{ message: string }]
}

export const createEmptyCartMutation = gql`
  mutation {
    createEmptyCart
  }
`

export const getLoggedInCustomerCart = gql`
  query {
    customerCart {
      id
      billing_address{
        firstname
        lastname
        street
        region{
          code
          label
          region_id
        }
        city
        company
        postcode
        telephone
        country{
          code
          label
        }
      }
      shipping_addresses{
        street
        country{
          code
          label
        }
      }
      total_quantity
      items {
        uid
        product {
          name
          sku
        }
        quantity
      }
    }
  }
`

/**
 * Create a cart for a guest user.
 *
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const createEmptyCart = async (): Promise<string | null> => {
  return new Promise<string|null>(async (resolve, reject) => {
    await AxiosRequest.queuedCacheableRequest({
      method: 'POST',
      url: process.env.MIX_MAGENTO_GRAPHQL,
      headers: {
        'Content-Type': 'application/json',
        Store: getStoreName(),
      },
      data: {
        query: createEmptyCartMutation,
      },
    }, {key: 'createEmptyCart', expirationInSeconds: 300}, (data: CreateEmptyCartResponse) => {
      if (data.errors) {
        reject(data.errors[0].message)
      }

      if (data.data.createEmptyCart) {
        resolve(data.data.createEmptyCart)
      }

      resolve(null)
    })
  });
}

/**
 * Create a cart for a Logged-in user.
 *
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const createEmptyCartForLoggedInUser = async (): Promise<string | null> => {
  const bearerToken = getBearerToken()
  const { data }: AxiosResponse<CreateEmptyCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: createEmptyCartMutation,
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.createEmptyCart) {
    return data.data.createEmptyCart
  }

  return null
}

/**
 * Create a cart for a Logged-in user.
 *
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const getCustomerCartForLoggedInUser = async (): Promise<Cart | null> => {
  const bearerToken = getBearerToken()
  const { data }: AxiosResponse<CustomerCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: getLoggedInCustomerCart,
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.customerCart) {
    localStorage.setItem('cart', JSON.stringify(data.data.customerCart))
    return data.data.customerCart
  }

  return null
}

