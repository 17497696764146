
import {computed, defineComponent, PropType} from 'vue'
import OrderInvoiceItemTable from './tabs/orders/OrderInvoiceItemTable.vue'
import formatDate from '../../utils/formatDate'

export default defineComponent({
  name: 'UserOrderInvoice',
  components: {
    OrderInvoiceItemTable,
  },
  props:{
    invoiceNumber: {
      type: Number,
      required: true,
    },
  },
  setup(props) {
    const activeInvoice = localStorage.getItem('orderInvoice') ?
      JSON.parse(localStorage.getItem('orderInvoice')!.toString()) : null
    if (!activeInvoice || props.invoiceNumber !== parseInt(activeInvoice.number)) {
      window.location.href = window.location.origin + '/user/profile#OrderHistory'
    }
    const orderDate = computed((): string => {
      return activeInvoice.order_date ? formatDate(activeInvoice.order_date) : ''
    })

    return {
      orderDate,
      activeInvoice,
    }
  },
})
