
import { defineComponent, onMounted, ref } from 'vue'

import AppPagination from '../../global/AppPagination.vue'
import OrderHistoryItem from './orders/OrderHistoryItem.vue'
import TableSkeleton from '../../skeletons/TableSkeleton.vue'
import AppContentWrapper from '../../global/AppContentWrapper.vue'

import usePagination from '../../../composables/usePagination'
import Order from '../../../../../global/js/magento-api-library/types/Order'
import { getOrders } from '../../../../../global/js/magento-api-library/orders/getOrders'
import { log } from '../../../../../global/js/utils/logging'

export default defineComponent({
  name: 'OrderHistoryTab',
  components: {
    AppPagination,
    TableSkeleton,
    OrderHistoryItem,
    AppContentWrapper,
  },
  setup() {
    const { pageSize, totalPages, currentPage, totalItems } = usePagination()

    const orders = ref<Order[]>([])
    const hasLoaded = ref<boolean>(false)
    const hasErrors = ref<boolean>(false)

    const setOrders = async (page = 1): Promise<void> => {
      try {
        hasLoaded.value = false
        hasErrors.value = false

        const results = await getOrders(page)

        if (results) {
          orders.value = results.items

          pageSize.value = results.page_info.page_size
          totalPages.value = results.page_info.total_pages
          currentPage.value = results.page_info.current_page
          totalItems.value = results.total_count
        }

        hasLoaded.value = true
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/account/tabs/OrderHistoryTab.vue')
        
        hasLoaded.value = true
        hasErrors.value = true
      }
    }

    const changePage = (currentPage: number): void => {
      setOrders(currentPage)
    }

    onMounted((): void => {
      setOrders()
    })

    return {
      orders,
      pageSize,
      hasErrors,
      hasLoaded,
      changePage,
      totalItems,
      totalPages,
      currentPage,
    }
  },
})
