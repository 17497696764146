import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'
import MagentoAddress from '../types/MagentoAddress'
import CustomerAddress from '../types/CustomerAddress'
import getBearerToken from '../../utils/getBearerToken'

interface UpdateAddressResponse {
  data: {
    updateCustomerAddress: MagentoAddress | null
  }
  errors?: [{ message: string }]
}

export const updateAddressMutation = gql`
  mutation(
    $id: Int!
    $street: [String]
    $city: String
    $state: String
    $stateCode: String
    $postalCode: String
    $country: CountryCodeEnum
    $regionID: Int
    $company: String
    $phoneNumber: String
    $faxNumber: String
  ) {
    updateCustomerAddress(
      id: $id
      input: {
        region: { region: $state, region_id: $regionID, region_code: $stateCode }
        country_code: $country
        street: $street
        city: $city
        postcode: $postalCode
        company: $company
        telephone: $phoneNumber
        fax: $faxNumber
      }
    ) {
      id
      street
      city
      region {
        region
        region_id
        region_code
      }
      postcode
      country_code
      company
      telephone
      fax
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const updateAddress = async (address: CustomerAddress): Promise<MagentoAddress | null> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<UpdateAddressResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: updateAddressMutation,
      variables: {
        id: address.id,
        street: address.suite ? [address.street, address.suite] : [address.street],
        city: address.city,
        state: address.state,
        stateCode: address.stateCode,
        postalCode: address.postalCode,
        country: address.country,
        regionID: address.regionID,
        company: address.company,
        phoneNumber: address.phoneNumber,
        faxNumber: address.faxNumber,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.updateCustomerAddress) {
    return data.data.updateCustomerAddress
  }

  return null
}
