import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'
import customerFullDownloadableProducts from '../types/DownloadableProduct'

interface GetDownloadableProductResponse {
  data: {
    customerFullDownloadableProducts: customerFullDownloadableProducts | null,
  }
  errors?: [{ message: string }],
}

export const downloadableProductQuery = gql`
  query {
    customerFullDownloadableProducts {
      items {
        date
        download_url
        order_increment_id
        remaining_downloads
        status
        product_name
        product_sku
        book_test
      }
    }
  }
`

export const getDownloadableProduct = async (): Promise<customerFullDownloadableProducts | null> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<GetDownloadableProductResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: downloadableProductQuery,
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.customerFullDownloadableProducts) {
    return data.data.customerFullDownloadableProducts
  }

  return null
}
