import { computed, ComputedRef } from 'vue'

import { useStore } from '../store/index'
import { UIMutationTypes } from '../store/modules/ui/mutation-types'

interface UseModal {
  openedModal: ComputedRef<string>
  modalIsOpen: ComputedRef<boolean>
  setOpenedModal: (modalName: string) => void
  resetOpenedModal: () => void
}

const useModal = (): UseModal => {
  const store = useStore()

  const modalIsOpen = computed((): boolean => store.state.ui.modalIsOpen)
  const openedModal = computed((): string => store.state.ui.openedModal)

  const setOpenedModal = (modalName: string): void => {
    store.commit(UIMutationTypes.SET_OPENED_MODAL, modalName)
  }

  const resetOpenedModal = (): void => {
    store.commit(UIMutationTypes.RESET_OPENED_MODAL)
  }

  return {
    modalIsOpen,
    openedModal,
    setOpenedModal,
    resetOpenedModal,
  }
}

export default useModal
