
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'
import {computed, defineComponent, PropType} from 'vue'

import TranscriptItem from '../../../../global/js/magento-api-library/types/TranscriptItem'
import {getTranscriptDownload} from '../../../../global/js/magento-api-library/transcripts/getTranscriptDownload'
import { log } from '../../../../global/js/utils/logging'

export default defineComponent({
  name: 'TranscriptsListItem',
  props: {
    transcriptItem: {
      type: Object as PropType<TranscriptItem>,
      required: true,
    },
    type: {
      type: String,
      required: true,
      validator: (value: string) => ['complete', 'in-progress'].includes(value),
    },
  },
  emits: ['downloadError', 'fetchLink'],
  setup(props, {emit}) {
    const completionDate = computed(() => {
      if (props.type === 'in-progress') {
        return ''
      }
      return format(parseISO(props.transcriptItem.completionDate as string), 'MMM d, y')
    })
    const formatDate = ((date: string) => {
      if (date) {
        return format(Date.parse(date as string), 'MMM d, y')
      }
      return ''
    })

    const handleSsoLink = (uri: string) => {
      emit('fetchLink', uri)
    }

    const downloadCertficate = async (courseId: number, magentoId: string) => {
      try {
        await getTranscriptDownload(courseId, magentoId).then((downloadLocation) => {
          if (downloadLocation !== null) {
            window.open(downloadLocation, '_blank')
          } else {
            emit('downloadError', [true])
          }
        })
      }
      catch (error) {
        log(error as string, 'src/reliasmedia/js/components/transcripts/TranscriptsListItem.vue')
      }
    }

    return {
      handleSsoLink,
      formatDate,
      completionDate,
      downloadCertficate,
    }
  },
})
