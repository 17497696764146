
import {computed, defineComponent, PropType} from 'vue'

import OrderHistoryItemTableRow from './OrderHistoryItemTableRow.vue'

import OrderItem from '../../../../../../global/js/magento-api-library/types/OrderItem'
import Order from '../../../../../../global/js/magento-api-library/types/Order'

export default defineComponent({
  name: 'OrderHistoryItemTable',
  components: {
    OrderHistoryItemTableRow,
  },
  props: {
    orderItems: {
      type: Array as PropType<OrderItem[]>,
      required: true,
    },
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
    showProductId: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const showShipping = computed((): boolean => {
      const shippingTotal = props.order.total.total_shipping?.value || null
      return shippingTotal !== null && shippingTotal > 0
    })

    return {
      showShipping
    }
  }
})
