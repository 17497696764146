
import Cookies from 'js-cookie'
import { defineComponent, onMounted, ref, unref } from 'vue'

import CartItemsTable from './CartItemsTable.vue'
import AppButton from '../../global/AppButton.vue'
import CartAddressInfo from './CartAddressInfo.vue'
import FormSkeleton from '../../skeletons/FormSkeleton.vue'
import ListSkeleton from '../../skeletons/ListSkeleton.vue'
import TableSkeleton from '../../skeletons/TableSkeleton.vue'
import CartBillingContactForm from './CartBillingContactForm.vue'
import AppContentWrapper from '../../global/AppContentWrapper.vue'

import useCart from '../../../composables/useCart'
import useLoader from '../../../composables/useLoader'
import useNotify from '../../../composables/useNotify'
import { placeOrder } from '../../../../../global/js/magento-api-library/cart/placeOrder'
import { log } from '../../../../../global/js/utils/logging'
import { setPaymentMethodOnCart } from '../../../../../global/js/magento-api-library/cart/setPaymentMethodOnCart'

export default defineComponent({
  name: 'CartConfirmationContainer',
  components: {
    AppButton,
    FormSkeleton,
    ListSkeleton,
    TableSkeleton,
    CartItemsTable,
    CartAddressInfo,
    AppContentWrapper,
    CartBillingContactForm,
  },
  setup() {
    const { notify } = useNotify()
    const { setLoading } = useLoader()
    const { cart, getCart } = useCart()

    const isBusy = ref<boolean>(false)
    const hasLoaded = ref<boolean>(false)
    const hasErrors = ref<boolean>(false)
    const hasPhysicalItems = ref<boolean>(false)

    onMounted(async (): Promise<void> => {
      try {
        hasLoaded.value = false
        hasErrors.value = false

        await getCart()
        hasPhysicalItems.value = !(unref(cart).items.find(item => item.product.__typename === 'SimpleProduct') === undefined)

        if (!hasPhysicalItems.value && !unref(cart).is_virtual) {
          hasPhysicalItems.value = true
        }

      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/checkout/confirmation/CartConfirmationContainer.vue')
        
        hasErrors.value = true
      } finally {
        hasLoaded.value = true
      }
    })

    const handleProcessFreeOrder = async (): Promise<void> => {
      try {
        isBusy.value = true

        setLoading(true)

        await setPaymentMethodOnCart(cart.value.id, {
          code: 'free',
        })

        const orderNumber = await placeOrder(cart.value.id)

        if (orderNumber) {
          Cookies.set('orderNumber', orderNumber)
        }

        window.location.assign('/store/thanks')
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/checkout/confirmation/CartConfirmationContainer.vue')
        
        notify({
          type: 'error',
          title: 'Error',
          text: 'Your order could not be placed.',
          duration: -1,
        })
      } finally {
        isBusy.value = false

        setLoading(false)
      }
    }

    return {
      cart,
      isBusy,
      hasErrors,
      hasLoaded,
      handleProcessFreeOrder,
      hasPhysicalItems,
    }
  },
})
