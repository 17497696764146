
import { defineComponent, PropType } from 'vue'

import OrderDetailsTableRow from './OrderDetailsTableRow.vue'

import SingleOrder from '../../../../../global/js/magento-api-library/types/SingleOrder'

export default defineComponent({
  name: 'OrderDetailsTable',
  components: {
    OrderDetailsTableRow,
  },
  props: {
    order: {
      type: Object as PropType<SingleOrder>,
      required: true,
    },
  },
})
