import axios, { AxiosResponse } from 'axios'

import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface ApplyCouponToCartResponse {
  data: {
    applyCouponToCart: { cart: Cart } | null
  }
  errors?: [{ message: string }]
}

export const applyCouponToCartMutation = gql`
  ${cartFragment}
  mutation($cartId: String!, $couponCode: String!) {
    applyCouponToCart(input: { cart_id: $cartId, coupon_code: $couponCode }) {
      cart {
        ...CartFields
      }
    }
  }
`

export const applyCouponToCart = async (
  cartId: string,
  couponCode: string,
): Promise<Cart | null> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<ApplyCouponToCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: applyCouponToCartMutation,
      variables: {
        cartId,
        couponCode,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.applyCouponToCart) {
    return data.data.applyCouponToCart.cart
  }

  return null
}
