
import { defineComponent } from 'vue'

import AppModal from '../../global/AppModal.vue'

export default defineComponent({
  name: 'ArticleLimitModal',
  components: {
    AppModal,
  },
  setup() {
    const handleGoBack = (): void => {
      window.history.back()
    }

    return {
      handleGoBack,
    }
  },
})
