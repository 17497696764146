interface UseLoader {
  setLoading: (isLoading: boolean) => void
}

const useLoader = (): UseLoader => {
  const setLoading = (isLoading: boolean): void => {
    const loader = document.getElementById('app_loader') as HTMLDivElement
    if(!loader){ return }

    if (isLoading) {
      loader.setAttribute('class', 'loading')
    } else {
      loader.removeAttribute('class')
    }
  }

  return {
    setLoading,
  }
}

export default useLoader
