
import { defineComponent, PropType, ref } from 'vue'

import NavTab from '../../types/NavTab'
import useMobileNav from '../../composables/useMobileNav'

export default defineComponent({
  name: 'NavTabDropdown',
  props: {
    activeTab: {
      type: Object as PropType<NavTab>,
      required: false,
      default: null,
    },
  },
  setup(props) {
    const menuItemIndex = ref(0)
    const { mobileNavIsOpen } = useMobileNav()

    const handleArrowPress = (index: number): void => {
      const ul = document.getElementById(`js-subnav-menu-${props.activeTab.tabSlug}`)

      if (ul) {
        const listItems: HTMLAnchorElement[] = Array.from(ul.querySelectorAll('li a'))

        if (index === listItems.length) {
          index = 0
        } else if (index < 0) {
          index = listItems.length - 1
        }

        listItems[index].focus()

        menuItemIndex.value = index
      }
    }

    return {
      menuItemIndex,
      mobileNavIsOpen,
      handleArrowPress,
    }
  },
})
