import { createApp } from 'vue'
import Cookies from 'js-cookie'
import vSelect from 'vue-select'
import Notifications from '@kyvg/vue3-notification'
import { store, key } from './store'
import { loginWithJWTToken } from '../../global/js/magento-api-library/auth/login'
import components from './components'
import focusTrap from './directives/focusTrap'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
// Import required brand icons
import { faFacebookF, faLinkedinIn, faYoutube } from '@fortawesome/free-brands-svg-icons'
// Import required regular icons
import { faArrowAltCircleRight, faCheckSquare, faTrashAlt, faNewspaper } from '@fortawesome/free-regular-svg-icons'
// Import all solid icons
import { fas, faCaretRight, faCaretDown, faTimes, faArrowRight } from '@fortawesome/free-solid-svg-icons'
import { VueReCaptcha } from 'vue-recaptcha-v3'
import Vue3Recaptcha from 'vue3-recaptcha2'

import '../../global/js/ts-validators'
import '../css/main.scss'

library.add(fas, faFacebookF, faLinkedinIn, faYoutube, faArrowAltCircleRight, faCheckSquare, faTrashAlt, faNewspaper, faCaretRight, faCaretDown, faTimes, faArrowRight)

// This is before app creation to ensure all components have proper access to store name
Cookies.set('storeName', process.env.MIX_RELIASMEDIA_MAGENTO_STORE_NAME || 'relias_media_default_view')

const app = createApp({
  async mounted() {
    const searchQuery = new URLSearchParams(location.search)
    const token = searchQuery.get('authToken')
    if (token) {
      const redirectLocation = await loginWithJWTToken(token)

      if (typeof redirectLocation === 'string') {
        window.location.href = redirectLocation
      }
    }
  },
})

app.use(store, key)
app.use(Notifications, {
  componentName: 'VueNotifications',
})
// Recaptcha 3
app.use(VueReCaptcha, { siteKey: process.env.MIX_RECAPTCHA_KEY })

// Recaptcha 2 (fallback)
app.use(Vue3Recaptcha, { siteKey: process.env.MIX_RECAPTCHA_V2_KEY })


app.component('FontAwesomeIcon', FontAwesomeIcon)
app.component('VueSelect', vSelect)

// Add logging here
components.forEach((Component) => {
  if (!Component.name) {
    console.warn('Component with no name detected', Component)
  } else {
    app.component(Component.name, Component)
  }
})

app.directive('trap', focusTrap)

app.mount('#relias')
