
import Cookies from 'js-cookie'
import { defineComponent, ref } from 'vue'

import AppButton from '../../global/AppButton.vue'
import AppTextInput from '../../global/forms/inputs/AppTextInput.vue'
import AppSelectInput from '../../global/forms/inputs/AppSelectInput.vue'

import useCart from '../../../composables/useCart'
import useForm from '../../../composables/useForm'
import useLoader from '../../../composables/useLoader'
import useNotify from '../../../composables/useNotify'
import AuthorizeDispatchResponse from '../../../types/AuthorizeDispatchResponse'
import { cardTypes, cardMonths, cardYears } from '../../../utils/creditCardOptions'
import { placeOrder } from '../../../../../global/js/magento-api-library/cart/placeOrder'
import { setPaymentMethodOnCart } from '../../../../../global/js/magento-api-library/cart/setPaymentMethodOnCart'
import { log } from '../../../../../global/js/utils/logging'
import {clear} from 'idb-keyval'

export default defineComponent({
  name: 'CartBillingContactForm',
  components: {
    AppButton,
    AppTextInput,
    AppSelectInput,
  },
  setup() {
    const { cart } = useCart()
    const { notify } = useNotify()
    const { setLoading } = useLoader()
    const { isBusy, errorMessage, clearErrorMessage, setErrorMessage } = useForm()

    const form = ref({
      cardName: '',
      cardType: '',
      expirationMonth: '',
      expirationYear: '',
      cardNumber: '',
      cvv: '',
    })

    const sendDataToAuthorize = (): void => {
      const authData = {
        clientKey: process.env.MIX_AUTHORIZE_NET_CLIENT_KEY || '',
        apiLoginID: process.env.MIX_AUTHORIZE_NET_API_LOGIN_ID || '',
      }

      const cardData = {
        cardNumber: form.value.cardNumber,
        month: form.value.expirationMonth,
        year: form.value.expirationYear,
        cardCode: form.value.cvv,
      }

      const secureData = {
        authData,
        cardData,
      }

      window.Accept.dispatchData(secureData, async (response: AuthorizeDispatchResponse) => {
        if (response.messages.resultCode === 'Error') {
          isBusy.value = false

          setLoading(false)

          notify({
            type: 'error',
            title: 'Error',
            text: 'Your payment information could not be added.',
            duration: -1,
          })

          return
        }

        try {
          await setPaymentMethodOnCart(cart.value.id, {
            code: 'authnetcim',
            tokenbase_data: {
              cc_owner: form.value.cardName,
              cc_type: form.value.cardType,
              cc_exp_month: form.value.expirationMonth,
              cc_exp_year: form.value.expirationYear,
              cc_last4: form.value.cardNumber.slice(-4),
              cc_cid: form.value.cvv,
              acceptjs_key: response.opaqueData?.dataDescriptor as string,
              acceptjs_value: response.opaqueData?.dataValue as string,
            },
          })

          const orderNumber = await placeOrder(cart.value.id)

          if (orderNumber) {
            Cookies.set('orderNumber', orderNumber)
          }

          window.location.assign('/store/thanks')
        } catch (error) {
          setErrorMessage(error as string)

          log(error as string, 'src/reliasmedia/js/components/checkout/confirmation/CartBillingContactForm.vue')

          notify({
            type: 'error',
            title: 'Error',
            text: 'Payment method could not be set on your cart.',
            duration: -1,
          })
        } finally {
          isBusy.value = false

          setLoading(false)
        }
      })
    }

    const handleSubmit = async (): Promise<void> => {
      isBusy.value = true

      setLoading(true)

      clearErrorMessage()

      sendDataToAuthorize()
      await clear()
    }

    return {
      form,
      isBusy,
      cardTypes,
      cardYears,
      cardMonths,
      errorMessage,
      handleSubmit,
    }
  },
})
