
import { computed, defineComponent, onMounted, ref } from 'vue'

import AccountTabs from './AccountTabs.vue'
import AccountTab from './tabs/AccountTab.vue'
import ContentTab from './tabs/ContentTab.vue'
import OrderHistoryTab from './tabs/OrderHistoryTab.vue'
import SubscriptionsTab from './tabs/SubscriptionsTab.vue'
import TranscriptsTab from './tabs/TranscriptsTab.vue'

import useCustomer from '../../composables/useCustomer'

export default defineComponent({
  name: 'AccountManagementContainer',
  components: {
    AccountTabs,
    Account: AccountTab,
    PurchasedContent: ContentTab,
    OrderHistory: OrderHistoryTab,
    Subscriptions: SubscriptionsTab,
    MyTranscripts: TranscriptsTab,
  },
  setup() {
    const { customer, setCustomer } = useCustomer()
    const activeTab = ref<string>('Account')
    const isLoading = ref<boolean>(true)

    const componentProps = computed(() => {
      return activeTab.value === 'Account'
        ? { hasLoaded: !isLoading.value, reloadForm: true }
        : null
    })

    onMounted(async (): Promise<void> => {
      localStorage.removeItem('orderInvoice')
      const tabHashes = ['Account', 'Subscriptions', 'OrderHistory', 'PurchasedContent', 'MyTranscripts']
      const contentTabHashes = ['downloadable-files', 'purchased-videos', 'online-content']

      function changeTab(hash: string):void {
        if (hash && tabHashes.includes(hash)) {
          activeTab.value = hash
        } else if (hash && contentTabHashes.includes(hash)) {
          activeTab.value = 'PurchasedContent'
        }
      }

      window.addEventListener('hashchange', (event) => {
        let newHash = event.newURL.split('profile#')[1]
        if (newHash === 'EmailPrefs') {
          changeTab('Account')
          history.replaceState(null, '', ' ')
        }
        changeTab(newHash)
      })

      changeTab(window.location.hash.substr(1))

      await setCustomer()

      isLoading.value = false
    })

    return {
      customer,
      activeTab,
      isLoading,
      componentProps,
    }
  },
})
