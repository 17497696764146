
import { defineComponent, PropType } from 'vue'

import MapState from '../../types/MapState'

export default defineComponent({
  name: 'MapStatesListItem',
  props: {
    state: {
      type: Object as PropType<MapState>,
      required: true,
    },
  },
})
