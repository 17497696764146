import { MutationTree } from 'vuex'

import { State } from './state'
import { UIMutationTypes } from './mutation-types'

export interface Mutations {
  [UIMutationTypes.TOGGLE_MOBILE_NAV]: (state: State) => void
  [UIMutationTypes.RESET_MOBILE_NAV]: (state: State) => void
  [UIMutationTypes.SET_OPENED_MODAL]: (state: State, modalName: string) => void
  [UIMutationTypes.RESET_OPENED_MODAL]: (state: State) => void
  [UIMutationTypes.TOGGLE_DESKTOP_NAV]: (state: State) => void
  [UIMutationTypes.RESET_DESKTOP_NAV]: (state: State) => void
}

export const mutations: MutationTree<State> & Mutations = {
  [UIMutationTypes.TOGGLE_MOBILE_NAV]: (state: State) => {
    state.mobileNavIsOpen = !state.mobileNavIsOpen
  },

  [UIMutationTypes.RESET_MOBILE_NAV]: (state: State) => {
    state.mobileNavIsOpen = false
  },

  [UIMutationTypes.SET_OPENED_MODAL]: (state: State, modalName: string) => {
    state.openedModal = modalName
    state.modalIsOpen = true
  },

  [UIMutationTypes.RESET_OPENED_MODAL]: (state: State) => {
    state.openedModal = ''
    state.modalIsOpen = false
  },

  [UIMutationTypes.TOGGLE_DESKTOP_NAV]: (state: State) => {
    state.desktopNavIsOpen = !state.desktopNavIsOpen
  },

  [UIMutationTypes.RESET_DESKTOP_NAV]: (state: State) => {
    state.desktopNavIsOpen = false
  },
}
