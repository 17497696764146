
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'ProductsSort',
  props: {
    sortOption: {
      type: String,
      default: '',
    },
  },
  setup() {
    const url = new URL(window.location.href)
    const sortOption = url.searchParams.has('s')? url.searchParams.get('s'):'title'
    const sortOrder = url.searchParams.has('o')? url.searchParams.get('o'):'ASC'

    const updateSorting = (option: string, order: string): void => {
      url.searchParams.set('s', option)

      if ( option === sortOption) {
        const reverseOrder = sortOrder === 'ASC'? 'DESC':'ASC'
        url.searchParams.set('o', reverseOrder)

      } else {
        url.searchParams.set('o', order)
      }

      window.location.href = url.toString()
    }

    return {
      updateSorting,
    }
  },
})
