
import {defineComponent, onMounted, ref, toRefs, unref, watch} from 'vue'

import EmailField from './account/EmailField.vue'
import NameFields from './account/NameFields.vue'
import AppButton from '../../global/AppButton.vue'
import ReviewField from './account/ReviewField.vue'
import AddressFields from './account/AddressFields.vue'
import PasswordFields from './account/PasswordFields.vue'
import FormSkeleton from '../../skeletons/FormSkeleton.vue'
import ProfessionalFields from './account/ProfessionalFields.vue'
import AppContentWrapper from '../../global/AppContentWrapper.vue'
import AppFormErrorAlert from '../../global/forms/AppFormErrorAlert.vue'

import useForm from '../../../composables/useForm'
import useLoader from '../../../composables/useLoader'
import useNotify from '../../../composables/useNotify'
import useCustomer from '../../../composables/useCustomer'
import { updatePassword } from '../../../../../global/js/magento-api-library/auth/updatePassword'
import { updateAddress } from '../../../../../global/js/magento-api-library/address/updateAddress'
import { updateCustomer } from '../../../../../global/js/magento-api-library/customer/updateCustomer'
import { AddressValidator } from '../../../../../global/js/magento-api-library/types/AddressValidator'
import { createAddress } from '../../../../../global/js/magento-api-library/address/createAddress'

export default defineComponent({
  name: 'AccountTab',
  components: {
    AppButton,
    EmailField,
    NameFields,
    ReviewField,
    FormSkeleton,
    AddressFields,
    PasswordFields,
    AppContentWrapper,
    AppFormErrorAlert,
    ProfessionalFields,
  },
  props: {
    hasLoaded: {
      type: Boolean,
      required: false,
      default: false,
    },
    hasErrors: {
      type: Boolean,
      required: false,
      default: false,
    },
    reloadForm: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const { notify } = useNotify()
    const { setLoading } = useLoader()
    const { customer } = useCustomer()
    const { isBusy, errorMessage, setErrorMessage, clearErrorMessage } = useForm()
    let needAddressReset = ref(false)
    let passwordFlag = ref(true)
    let addressFlag = ref(true)

    const { hasLoaded } = toRefs(props)

    const userInfoForm = ref({
      prefix: '',
      firstName: '',
      middleName: '',
      lastName: '',
    })

    const emailForm = ref({
      email: '',
    })

    const passwordForm = ref({
      currentPassword: '',
      newPassword: '',
      newPasswordConfirmation: '',
    })

    const addressForm = ref({
      id: 0,
      firstName: '',
      lastName: '',
      street: '',
      suite: '',
      city: '',
      state: '',
      stateCode: '',
      postalCode: '',
      country: '',
      regionID: 0,
      company: '',
      jobTitle: '',
      phoneNumber: '',
      phoneExtension: '',
      faxNumber: '',
    })

    const reviewForm = ref({
      hasReviewed: false,
    })

    const professionalDetailsForm = ref({
      birthdate: '',
      npiNumber: '',
      abimMemberId: '',
      abmsBoardId: '',
      primaryCredentials: '',
      secondaryCredentials: '',
      abimMembers: {
        value: '',
        label: '',
      },
      abmsBoard: {
        value: '',
        label: '',
      },
      specialty: {
        value: '',
        label: '',
      },
      profession: {
        value: '',
        label: '',
      },
    })

    const checkAddressValidation = (isAddressValid : boolean) => {
      if (isAddressValid) {
        addressFlag.value = true
        checkFormValidation()
      }
      else {
        addressFlag.value = false
        isBusy.value = true
      }
    }

    const checkPasswordValidation = (isPasswordValid : boolean) => {
      if (isPasswordValid) {
        passwordFlag.value = true
        checkFormValidation()
      }
      else {
        passwordFlag.value = false
        isBusy.value = true
      }
    }

    const checkFormValidation = () => {
      if (addressFlag.value && passwordFlag.value) {
        isBusy.value = false
      }
    }

    const setUserInfo = (): void => {
      let unrefCustomer = unref(customer)
      if (unrefCustomer) {
        userInfoForm.value.prefix = unrefCustomer.prefix || ''
        userInfoForm.value.firstName = unrefCustomer.firstname
        userInfoForm.value.middleName = unrefCustomer.middlename || ''
        userInfoForm.value.lastName = unrefCustomer.lastname

        emailForm.value.email = unrefCustomer.email
      }
    }

    const setAddress = (): void => {
      let unrefCustomer = unref(customer)
      if (unrefCustomer && unrefCustomer.addresses.length) {
        const currentAddress = unrefCustomer.addresses[unrefCustomer.addresses.length - 1]
        addressForm.value.id = currentAddress.id
        addressForm.value.company = currentAddress.company || ''
        addressForm.value.country = currentAddress.country_code
        addressForm.value.phoneNumber = currentAddress.telephone || ''
        addressForm.value.jobTitle = unrefCustomer.media_job_title || ''
        addressForm.value.phoneExtension = unrefCustomer.media_phone_extension || ''
        addressForm.value.faxNumber = currentAddress.fax || ''
        addressForm.value.street = currentAddress.street[0]
        addressForm.value.suite = currentAddress.street[1] || ''
        addressForm.value.city = currentAddress.city
        addressForm.value.stateCode = currentAddress.region.region_code || ''
        addressForm.value.state = currentAddress.region.region || ''
        addressForm.value.regionID = currentAddress.region.region_id
        addressForm.value.postalCode = currentAddress.postcode || ''
      }
    }

    const hasInvalidAddresses = async (): Promise<boolean> => {
      let unrefCustomer = unref(customer)
      if(unrefCustomer && unrefCustomer.addresses.length > 0){
        for (const address of unrefCustomer.addresses) {
          if(!await (new AddressValidator(address).isValid())){
            return true
          }
        }
      }
      return false
    }

    const setProfessionalDetails = (): void => {
      let unrefCustomer = unref(customer)
      if (unrefCustomer) {
        professionalDetailsForm.value.birthdate = unrefCustomer.date_of_birth || ''
        professionalDetailsForm.value.npiNumber = unrefCustomer.media_npi_number || ''
        professionalDetailsForm.value.abimMemberId = unrefCustomer.media_abim_member_id || ''
        professionalDetailsForm.value.abmsBoardId = unrefCustomer.media_secondary_board_id || ''
        professionalDetailsForm.value.primaryCredentials = unrefCustomer.media_credentials || ''
        professionalDetailsForm.value.secondaryCredentials = unrefCustomer.media_credential_secondary || ''

        professionalDetailsForm.value.abimMembers = { value: unrefCustomer.media_abim_membership || '', label: ''}
        professionalDetailsForm.value.abmsBoard = { value: unrefCustomer.media_abms_board || '', label: ''}
        professionalDetailsForm.value.specialty = { value: unrefCustomer.media_specialty || '', label: ''}
        professionalDetailsForm.value.profession = { value: unrefCustomer.media_profession || '', label: ''}
      }
    }

    const handleReset = async (): Promise<void> => {
      await triggerFormLoad()
    }

    const triggerFormLoad = async (): Promise<void> => {
      setUserInfo()
      needAddressReset.value = await hasInvalidAddresses()
      let unrefCustomer = unref(customer)
      if (unrefCustomer && unrefCustomer.addresses.length > 0) {
        setAddress()
      }
      setProfessionalDetails()
    }



    const handleSubmit = async (): Promise<void> => {
      let unrefCustomer = unref(customer)
      try {
        isBusy.value = true

        setLoading(true)

        clearErrorMessage()

        if (passwordForm.value.newPassword) {
          if (!passwordForm.value.newPasswordConfirmation) {
            setErrorMessage('You must confirm your new password.')

            isBusy.value = false

            setLoading(false)

            return
          } else if (passwordForm.value.newPasswordConfirmation !== passwordForm.value.newPassword) {
            setErrorMessage('New password and new password confirmation must match.')

            isBusy.value = false

            setLoading(false)

            return
          }

          await updatePassword(passwordForm.value.currentPassword, passwordForm.value.newPassword)
        }

        await updateCustomer(userInfoForm.value.prefix,
          addressForm.value.jobTitle,
          addressForm.value.phoneExtension,
          professionalDetailsForm.value.birthdate,
          professionalDetailsForm.value.npiNumber,
          professionalDetailsForm.value.abimMemberId,
          professionalDetailsForm.value.abmsBoardId,
          professionalDetailsForm.value.primaryCredentials,
          professionalDetailsForm.value.secondaryCredentials,
          professionalDetailsForm.value.abimMembers.value,
          professionalDetailsForm.value.abmsBoard.value,
          professionalDetailsForm.value.specialty.value,
          professionalDetailsForm.value.profession.value,
        )
        if(unrefCustomer){
          addressForm.value.firstName = unrefCustomer.firstname
          addressForm.value.lastName = unrefCustomer.lastname
        }
        if (unrefCustomer && unrefCustomer.addresses.length === 0) {
          await createAddress(addressForm.value)
        } else {
          await updateAddress(addressForm.value)
        }

        notify({
          type: 'success',
          title: 'Success!',
          text: 'Your account information was updated.',
        })
      } catch (error) {
        setErrorMessage(error as string)

        notify({
          type: 'error',
          title: 'Error',
          text: 'Your account information could not be updated.',
          duration: -1,
        })
      } finally {
        isBusy.value = false

        setLoading(false)
      }
    }

    watch(hasLoaded, async (newVal: boolean): Promise<void> => {
      if (newVal) {
        await triggerFormLoad()
      }
    })

    onMounted(async (): Promise<void> => {
      if(props.reloadForm) {
        await triggerFormLoad()
      }
    })

    return {
      isBusy,
      emailForm,
      reviewForm,
      addressForm,
      handleReset,
      errorMessage,
      handleSubmit,
      passwordForm,
      userInfoForm,
      professionalDetailsForm,
      needAddressReset,
      checkAddressValidation,
      checkPasswordValidation,
    }
  },
})
