
import { computed, defineComponent, PropType } from 'vue'

import MapStatesListItem from './MapStatesListItem.vue'

import MapState from '../../types/MapState'

export default defineComponent({
  name: 'MapStatesList',
  components: {
    MapStatesListItem,
  },
  props: {
    states: {
      type: Array as PropType<MapState[]>,
      required: true,
    },
    selectedState: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const filteredStateData = computed(() => {
      return props.selectedState === 'all'
        ? props.states
        : props.states.filter(state => state.abbreviation === props.selectedState)
    })

    return {
      filteredStateData,
    }
  },
})
