
import { computed, defineComponent, PropType, toRefs } from 'vue'

import salutations from '../../../../utils/salutations'
import AppTextInput from '../../../global/forms/inputs/AppTextInput.vue'
import AppSelectInput from '../../../global/forms/inputs/AppSelectInput.vue'

interface UserInfo {
  prefix: string
  firstName: string
  middleName: string
  lastName: string
}

export default defineComponent({
  name: 'NameFields',
  components: {
    AppTextInput,
    AppSelectInput,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserInfo>,
      required: true,
    },
  },
  setup(props) {
    const { modelValue } = toRefs(props)

    const local = computed((): UserInfo => modelValue.value)

    return {
      local,
      salutations,
    }
  },
})
