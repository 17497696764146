
import { computed, defineComponent, PropType, toRefs } from 'vue'
import AppTextInput from '../../../global/forms/inputs/AppTextInput.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

interface UserEmail {
  email: string
}

export default defineComponent({
  name: 'EmailField',
  components: {
    FontAwesomeIcon,
    AppTextInput,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserEmail>,
      required: true,
    },
  },
  setup(props) {
    const { modelValue } = toRefs(props)

    const local = computed((): UserEmail => modelValue.value)

    return {
      local,
    }
  },
})
