import SingleOrder from '../magento-api-library/types/SingleOrder'
import CartItem from '../magento-api-library/types/CartItem'

interface GA4Item {
	item_id: string
	item_name: string
	affiliation?: string
	coupon?: string
	currency?: string
	discount?: number
	index?: number
	item_brand?: string
	item_category?: string
	item_category2?: string
	item_category3?: string
	item_category4?: string
	item_category5?: string
	item_list_id?: string
	item_list_name?: string
	item_variant?: string
	location_id?: string
	price?: number
	quantity?: number
}

// Events
interface GA4Purchase {
	currency: string
	transaction_id: string
	value: number
	affiliation?: string
	coupon?: string
	shipping?: number
	tax?: number
	items: GA4Item[]
}

interface GA4AddToCart {
	currency: string
	value: number
	items: GA4Item[]
}

export const trackPurchaseGa4 = (order: SingleOrder): void => {
	let ga4Purchase: GA4Purchase = {
		transaction_id: order.id,
		value: order.total.subtotal.value,
		tax: order.total.total_tax.value,
		shipping: order.total.total_shipping.value,
		currency: 'USD',
		items: [],
	}
	for (const item of order.items) {
		let couponCombinedString = ''
		let couponCombinedPrice = 0.00
		item.discounts.forEach((discount) => {
			couponCombinedString += discount.label + '|'
			couponCombinedPrice += discount.amount.value
		})
		ga4Purchase.items.push({
			item_id: item.product_sku,
			item_name: item.product_name,
			coupon: couponCombinedString.substring(0, couponCombinedString.length - 1),
			currency: 'USD',
			discount: couponCombinedPrice,
			price: item.product_sale_price.value,
			quantity: item.quantity_ordered,
		})
	}
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({ecommerce: null})  // Clear the previous ecommerce object.
	window.dataLayer.push({
		event: 'purchase',
		ecommerce: ga4Purchase,
	})
}

export const trackAddToCartGa4 = (cartItem: CartItem, quantity: number): void => {
	window.dataLayer = window.dataLayer || []
	window.dataLayer.push({ecommerce: null})  // Clear the previous ecommerce object.
	window.dataLayer.push({
		event: 'add_to_cart',
		ecommerce: {
			currency: 'USD',
			value: cartItem.prices.price.value,
			items: [
				{
					item_id: cartItem.product.sku,
					item_name: cartItem.product.name,
					currency: 'USD',
					price: cartItem.prices.price.value,
					quantity: quantity,
				},
			],
		} as GA4AddToCart,
	})
}