
import {defineComponent, PropType} from 'vue'

import OrderHistoryItemTableRow from './OrderHistoryItemTableRow.vue'
import Order from '../../../../../../global/js/magento-api-library/types/Order'
import fetchCurrencySymbol from '../../../../utils/fetchCurrencySymbol'

export default defineComponent({
  name: 'OrderInvoiceItemTable',
  components: {
    OrderHistoryItemTableRow,
  },
  props: {
    invoice: {
      type: Object as PropType<Order>,
      required: true,
    },
    showProductId: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props){
    const currencySymbol = fetchCurrencySymbol(props.invoice.total.grand_total.currency)
    return {
      currencySymbol,
    }
  },
})
