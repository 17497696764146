
import {computed, defineComponent, PropType, unref} from 'vue'

import OrderHistoryItemTable from './OrderHistoryItemTable.vue'
import OrderHistoryItemModal from './modals/OrderHistoryItemModal.vue'

import formatDate from '../../../../utils/formatDate'
import useModal from '../../../../composables/useModal'
import useCustomer from '../../../../composables/useCustomer'
import useOrderHistory from '../../../../composables/account/useOrderHistory'
import Order from '../../../../../../global/js/magento-api-library/types/Order'

export default defineComponent({
  name: 'OrderHistoryItem',
  components: {
    OrderHistoryItemModal,
    OrderHistoryItemTable,
  },
  props: {
    order: {
      type: Object as PropType<Order>,
      required: true,
    },
  },
  setup(props) {
    const { customer } = useCustomer()
    const { setOpenedModal } = useModal()
    const { setActiveOrder } = useOrderHistory()

    const orderDate = computed((): string => formatDate(props.order.order_date))

    const customerName = computed((): string => {
      let unrefCustomer = unref(customer);
      return unrefCustomer ? `${unrefCustomer.firstname} ${unrefCustomer.lastname}` : ''
    })

    const handleViewDetails = (): void => {
      setOpenedModal(`order-history-item-${props.order.number}`)

      setActiveOrder(props.order)
    }
    const handleInvoiceViewDetails = (): void => {
      setActiveOrder(props.order)
      localStorage.setItem('orderInvoice',JSON.stringify(props.order))
      window.location.href = window.location.origin + '/user/order/invoice/?id='+ props.order.number
    }

    return {
      orderDate,
      customerName,
      setOpenedModal,
      handleViewDetails,
      handleInvoiceViewDetails,
    }
  },
})
