
import {defineComponent, onMounted, ref, watch} from 'vue'
import useCart from '../../../composables/useCart'

export default defineComponent({
  name: 'Minicart',

  setup() {
    const count = ref<number>(0)
    const { cart, getCart, getMinicartCount, setMinicartCount } = useCart()

    onMounted(async (): Promise<void> => {
      await getCart()
      setMinicartCount(cart.value.total_quantity)
      count.value = cart.value.total_quantity
    })

    watch(
      () => getMinicartCount(),
      async (): Promise<void> => {
        count.value = getMinicartCount()
      }
    )

    return {
      count
    }
  },
})
