
import { computed, defineComponent, ref } from 'vue'

export default defineComponent({
  name: 'AppPagination',
  props: {
    perPage: {
      type: Number,
      required: true,
    },
    totalPages: {
      type: Number,
      required: true,
    },
    currentPage: {
      type: Number,
      required: true,
    },
  },
  emits: ['pageChanged'],
  setup(props, { emit }) {
    const isFirstPage = computed((): boolean => props.currentPage === 1)

    const isLastPage = computed((): boolean => props.currentPage === props.totalPages)

    const lastPageCount = ref<number>(props.totalPages)

    const pages = computed((): { pageNumber: number, isDisabled: boolean }[] => {
      const range: { pageNumber: number, isDisabled: boolean }[] = []

      for (let i = 1; i <= props.totalPages; i += 1) {
        if (i === props.currentPage || i === props.currentPage-1 || i === props.currentPage+1) {
          range.push({
            pageNumber: i,
            isDisabled: i === props.currentPage,
          })
        }
      }

      return range
    })

    const handlePageChange = (page: number): void => {
      emit('pageChanged', page)
    }

    const handlePrevious = (): void => {
      emit('pageChanged', props.currentPage - 1)
    }

    const handleNext = (): void => {
      emit('pageChanged', props.currentPage + 1)
    }

    return {
      pages,
      handleNext,
      isLastPage,
      isFirstPage,
      lastPageCount,
      handlePrevious,
      handlePageChange,
    }
  },
})
