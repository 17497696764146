
import { defineComponent } from 'vue'
import useAuth from '../../composables/useAuth'
import { logout } from '../../../../global/js/magento-api-library/auth/logout'

export default defineComponent({
  name: 'UtilityNavigation',
  setup() {
    const { isLoggedIn } = useAuth()

    const handleLogout = (): void => {
      logout()
    }

    return {
      isLoggedIn, 
      handleLogout,
    }
  },
})
