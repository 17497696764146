
import {computed, defineComponent, onMounted, PropType, reactive, ref, toRefs, watch} from 'vue'

import AppTextInput from '../../global/forms/inputs/AppTextInput.vue'
import AppSearchableSelect from '../../global/forms/inputs/AppSearchableSelect.vue'

import { errorMessage } from '../../../store/modules/ui/errorMessage'
import UserAddress from '../../../types/UserAddress'
import useNotify from '../../../composables/useNotify'
import Country from '../../../../../global/js/magento-api-library/types/Country'
import CountryRegion from '../../../../../global/js/magento-api-library/types/CountryRegion'
import { getCountries } from '../../../../../global/js/magento-api-library/address/countries'
import { log } from '../../../../../global/js/utils/logging'
import { useField, useForm } from 'vee-validate'

export default defineComponent({
  name: 'UserAddressFields',
  components: {
    AppTextInput,
    AppSearchableSelect,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserAddress>,
      required: true,
    },
  },
  emits:['isAddressValid'],
  setup(props, context) {
    const {notify} = useNotify()
    const {modelValue} = toRefs(props)
    let isStateError = ref(false)
    let isStateValidated : boolean
    
    const {meta: formMeta, setFieldValue, setFieldTouched, handleSubmit} = useForm()
    const phoneField = reactive(useField('phone', {validPhoneNumber: true, required: true}))
    const streetField = reactive(useField('street', {required: true}))
    const cityField = reactive(useField('city', {required: true}))
    const postalCodeField = reactive(useField('postal_code', {validPostalCode: true, required: true}))

    const countryData = ref<Country[]>([])
    const countries = ref<{ label: string, value: string }[]>([])
    const selectedCountry = ref<{ label: string, value: string }>({
      label: '',
      value: '',
    })

    const stateData = ref<CountryRegion[]>([])
    const states = ref<{ label: string, value: string }[]>([])
    const selectedState = ref<{ label: string, value: string }>({
      label: '',
      value: '',
    })
    let isStatesAvailable = ref(true)

    const local = computed((): UserAddress => modelValue.value)

    const addressValidChange = ()=> {
      if(isStateValidated && formMeta.value.valid) {
        context.emit('isAddressValid', true)
      }
      else {
        context.emit('isAddressValid', false)
      }
    }

    const stateChange = ()=> {
      if (selectedState.value.value == '') {
        isStateError.value = true
        isStateValidated = false
        context.emit('isAddressValid', false)
      }
      else {
        if (formMeta.value.valid) {
          context.emit('isAddressValid', true)
        }
        isStateError.value = false
        isStateValidated = true
      }
    }

    onMounted(async (): Promise<void> => {
      try {
        countryData.value = await getCountries()

        countries.value = countryData.value.map((country: Country) => ({
          label: country.full_name_english,
          value: country.two_letter_abbreviation,
        }))

        selectedCountry.value = {
          label: 'United States',
          value: 'US',
        }
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/auth/register/UserAddressFields.vue')
        
        notify({
          type: 'error',
          title: 'Error',
          text: 'Countries could not be retrieved.',
          duration: -1,
        })
      }
    })

    watch(selectedCountry, (): void => {
      const country = countryData.value.find((countryToFind: Country) => {
        return countryToFind.two_letter_abbreviation === selectedCountry.value.value
      })

      local.value.country = selectedCountry.value.value

      if (country && country.available_regions) {
        stateData.value = country.available_regions
        states.value = country.available_regions.map((region: CountryRegion) => ({
          label: region.name,
          value: region.code,
        }))
        isStatesAvailable.value = true
        selectedState.value = {
          label: '',
          value: '',
        }
      } else {
        stateData.value = []
        isStatesAvailable.value = false
        selectedState.value = {label: '', value: ''}
      }
    })

    watch(selectedState, (): void => {
      let emptyRegion = true
      if (selectedState.value.label != '' && selectedState.value.value != '') {
        const state = stateData.value.find((stateToFind: CountryRegion) => {
          return stateToFind.code === selectedState.value.value
        })
        if(state) {
          local.value.state = state.name
          local.value.stateCode = state.code
          local.value.regionID = state.id
          emptyRegion = false
        }
      }
      if(emptyRegion){
        local.value.state = ''
        local.value.stateCode = ''
        local.value.regionID = 0
      }
    })

    return {
      local,
      errorMessage,
      phoneField,
      streetField,
      cityField,
      postalCodeField,
      formMeta,
      setFieldValue,
      setFieldTouched,
      handleSubmit,
      addressValidChange,
      stateChange,
      isStateError,
      states,
      countries,
      selectedState,
      selectedCountry,
      isStatesAvailable,
    }
  },
})
