import { ref, Ref } from 'vue'

interface UsePagination {
  pageSize: Ref<number>
  totalItems: Ref<number>
  totalPages: Ref<number>
  currentPage: Ref<number>
}

const usePagination = (): UsePagination => {
  const pageSize = ref<number>(20)
  const totalPages = ref<number>(1)
  const currentPage = ref<number>(1)
  const totalItems = ref<number>(0)

  return {
    pageSize,
    totalItems,
    totalPages,
    currentPage,
  }
}

export default usePagination
