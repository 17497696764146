
import {defineComponent, onMounted, ref} from 'vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
export default defineComponent({
  name: 'AppCookiePolicy',
  components: { FontAwesomeIcon },
  setup() {
    const LS_COOKIE_POLICY = 'CookieConsent'
    const banner = ref<HTMLElement>()
    let showPolicyBanner = ref(false)

    const checkCookiePolicy = (lsName: string): boolean => {
      const policyStatus = localStorage.getItem(lsName)
      let showPolicy = false

      if (policyStatus == null) {
        localStorage.setItem(lsName, 'true')
        document.body.classList.add('cookie-banner-active')
        showPolicy = true
      }

      return showPolicy
    }

    const closeBanner = (): void => {
      showPolicyBanner.value = false
      document.body.classList.remove('cookie-banner-active')
    }

    onMounted((): void => {
      showPolicyBanner.value = checkCookiePolicy(LS_COOKIE_POLICY)
    })

    return {
      LS_COOKIE_POLICY,
      banner,
      showPolicyBanner,
      closeBanner,
    }
  }
})
