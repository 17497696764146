
import { defineComponent, nextTick, watch } from 'vue'
import useArrowKeys from '../../composables/useArrowKeys'
import useDesktopNav from '../../composables/useDesktopNav'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'DesktopNavigation',
  components: { FontAwesomeIcon },
  setup() {
    const { desktopNavIsOpen, toggleDesktopNav, resetDesktopNav } = useDesktopNav()
    const { listItems, setListItems, menuItemIndex, handleArrowPress } = useArrowKeys()

    watch(desktopNavIsOpen, async (newVal: boolean): Promise<void> => {
      if (newVal) {
        const dropdowns = Array.from(document.querySelectorAll('div.utility-nav-dropdown > ul')) as HTMLUListElement[]

        if (dropdowns.length) {
          dropdowns.forEach((dropdown) => {
            if (dropdown.style.display !== 'none') {
              dropdown.style.display = 'none'
            }
          })
        }

        setListItems('div.main-desktop-nav__content')

        await nextTick()

        listItems.value[0].focus()
      } else {
        const button = document.querySelector('button.header__nav-btn') as HTMLButtonElement

        button.focus()
      }
    })

    return {
      listItems,
      setListItems,
      menuItemIndex,
      resetDesktopNav,
      desktopNavIsOpen,
      handleArrowPress,
      toggleDesktopNav,
    }
  },
})
