
import {computed, defineComponent, onMounted, ref, watch} from 'vue'

import CartTableRow from './CartTableRow.vue'
import CartPromoCodeRow from './CartPromoCodeRow.vue'
import CartPromoCodeForm from './CartPromoCodeForm.vue'
import AppInlineAlert from '../../global/AppInlineAlert.vue'
import TableSkeleton from '../../skeletons/TableSkeleton.vue'
import AppContentWrapper from '../../global/AppContentWrapper.vue'

import useAuth from '../../../composables/useAuth'
import useCart from '../../../composables/useCart'
import SelectedShippingMethod from '../../../../../global/js/magento-api-library/types/SelectedShippingMethod'
import { log } from '../../../../../global/js/utils/logging'

export default defineComponent({
  name: 'CartTable',
  components: {
    CartTableRow,
    TableSkeleton,
    AppInlineAlert,
    CartPromoCodeRow,
    AppContentWrapper,
    CartPromoCodeForm,
  },
  setup() {
    const {isLoggedIn} = useAuth()
    const {cart, getCart} = useCart()
    const hasLoaded = ref<boolean>(false)
    const hasErrors = ref<boolean>(false)
    let grandTotal = ref<string>('0')
    const shippingTotal = ref(0)
    const shippingMethod = computed((): SelectedShippingMethod | null => {
      if (cart.value.shipping_addresses[0]?.selected_shipping_method) {
        return cart.value.shipping_addresses[0].selected_shipping_method
      } else {
        return null
      }
    })
    const carrierCode = computed((): string | undefined => {
      return shippingMethod.value?.carrier_code
    })
    const showShipping = computed((): boolean => {
      // Show shipping cost if physical products are in cart
      return !(cart.value.items.find(item => item.product.__typename === 'SimpleProduct') === undefined)
    })
    const hasItems = computed((): boolean => {
      return cart.value.items.length > 0 ? true : false
    })
    const taxTotal = computed(() => {
      if (cart.value.prices.applied_taxes[0]?.amount?.value) {
        let total_tax = cart.value.prices.applied_taxes.reduce((a, b) => {
          return a + b.amount.value
        }, 0)
        return total_tax.toFixed(2)
      } else {
        return 0
      }
    })

    watch(
      () => cart.value.prices.grand_total,
      async (): Promise<void> => {
        grandTotal.value = cart.value.prices.grand_total.value.toFixed(2)
      },
    )

    onMounted(async (): Promise<void> => {
      try {
        hasLoaded.value = false
        hasErrors.value = false

        await getCart()

        grandTotal.value = cart.value.prices.grand_total.value.toFixed(2)

        if (shippingMethod.value !== null) {
          shippingTotal.value = parseFloat(shippingMethod.value.amount.value.toFixed(2))
        }

        hasLoaded.value = true
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/checkout/cart/CartTable.vue')

        hasLoaded.value = true
        hasErrors.value = true
      }
    })

    return {
      cart,
      showShipping,
      hasErrors,
      hasLoaded,
      isLoggedIn,
      grandTotal,
      shippingTotal,
      carrierCode,
      taxTotal,
      hasItems,
    }
  },
})
