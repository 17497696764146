import { computed, ComputedRef } from 'vue'

import { useStore } from '../store/index'
import { UIMutationTypes } from '../store/modules/ui/mutation-types'

interface UseDesktopNav {
  desktopNavIsOpen: ComputedRef<boolean>
  toggleDesktopNav: () => void
  resetDesktopNav: () => void
}

const useDesktopNav = (): UseDesktopNav => {
  const store = useStore()

  const desktopNavIsOpen = computed((): boolean => store.state.ui.desktopNavIsOpen)

  const toggleDesktopNav = (): void => {
    store.commit(UIMutationTypes.TOGGLE_DESKTOP_NAV)
  }

  const resetDesktopNav = (): void => {
    store.commit(UIMutationTypes.RESET_DESKTOP_NAV)
  }

  return {
    resetDesktopNav,
    desktopNavIsOpen,
    toggleDesktopNav,
  }
}

export default useDesktopNav
