import axios, { AxiosResponse } from 'axios'

import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface MergeCartsResponse {
  data?: {
    mergeCarts: Cart
  }
  errors?: [{ message: string }]
}

export const mergeCartsMutation = gql`
  ${cartFragment}
  mutation($sourceCartId: String!) {
    mergeCarts(source_cart_id: $sourceCartId) {
      ...CartFields
    }
  }
`

/**
 * Merge a guest cart with the cart of the authenticated user.
 * This is necessary if a guest user logs in at a later time.
 *
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const mergeCarts = async (sourceCartId: string): Promise<Cart | null> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<MergeCartsResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: mergeCartsMutation,
      variables: {
        sourceCartId,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data) {
    return data.data.mergeCarts
  }

  return null
}
