
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'SkeletonAnimation',
  props: {
    theme: {
      type: String,
      required: false,
      default: 'light',
      validator: (value: string) => ['light', 'dark'].includes(value),
    },
  },
})
