
import { defineComponent } from 'vue'
import useDesktopNav from '../../composables/useDesktopNav'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'DesktopNavToggler',
  components: { FontAwesomeIcon },
  setup() {
    const { desktopNavIsOpen, toggleDesktopNav } = useDesktopNav()

    return {
      desktopNavIsOpen,
      toggleDesktopNav,
    }
  },
})
