import { InjectionKey } from 'vue'
import { createStore, useStore as baseUseStore, Store } from 'vuex'

import { store as ui, State as UIState } from './modules/ui/index'
import { store as cart, State as CartState } from './modules/cart/index'
import { store as account, State as AccountState } from './modules/account/index'
import { store as customer, State as CustomerState } from './modules/customer/index'

export interface RootState {
  ui: UIState
  cart: CartState
  account: AccountState
  customer: CustomerState
}

export const key: InjectionKey<Store<RootState>> = Symbol()

export const store = createStore<RootState>({
  modules: {
    ui,
    cart,
    account,
    customer,
  },
})

export const useStore = (): Store<RootState> => baseUseStore(key)
