
import {computed, defineComponent, reactive, toRefs} from 'vue'
import {useField} from 'vee-validate'

export default defineComponent({
  name: 'AppSearchableSelect',
  props: {
    modelValue: {
      type: [String, Object],
      required: false,
      default: '',
    },
    options: {
      type: [Array, Object],
      required: false,
      default: () => [],
    },
    id: {
      type: String,
      required: true,
    },
    labelText: {
      type: String,
      required: false,
      default: '',
    },
    labelClasses: {
      type: String,
      required: false,
      default: null,
    },
    searchable: {
      type: Boolean,
      required: false,
      default: true,
    },
    filterable: {
      type: Boolean,
      required: false,
      default: true,
    },
    clearable: {
      type: Boolean,
      required: false,
      default: false,
    },
    disabled: {
      type: Boolean,
      required: false,
      default: false,
    },
    required: {
      type: Boolean,
      required: false,
      default: false,
    },
    placeholderText: {
      type: String,
      required: false,
      default: '',
    },
    validationRules: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props, {emit}) {
    const {modelValue} = toRefs(props)

    const validateField = reactive(useField(props.id, props.validationRules))

    const error = computed(() => {
      const localValue = typeof local?.value === 'string' ? local?.value.trim() : local?.value

      return local?.value && localValue === '' && validateField.errorMessage ? true : false
    })

    const local = computed({
      get: () => modelValue.value,
      set: (value) => {
        emit('update:modelValue', value)
      },
    })

    return {
      local,
      validateField,
      error,
    }
  },
})
