
import {computed, defineComponent, unref, ref} from 'vue'

import AppModal from '../../../../global/AppModal.vue'
import OrderHistoryItemTable from '../OrderHistoryItemTable.vue'

import formatDate from '../../../../../utils/formatDate'
import useOrderHistory from '../../../../../composables/account/useOrderHistory'

export default defineComponent({
  name: 'OrderHistoryItemModal',
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    AppModal,
    OrderHistoryItemTable,
  },
  setup(props) {
    const id = ref(props.id as string)
    const { activeOrder } = useOrderHistory()

    const orderDate = computed((): string => {
      return unref(activeOrder).order_date
        ? formatDate(unref(activeOrder).order_date)
        : ''
    })

    return {
      orderDate,
      activeOrder,
      id,
    }
  },
})
