
import { defineComponent, onMounted, Ref, ref, watch } from 'vue'

import AppButton from '../global/AppButton.vue'
import CartAddressForm from './CartAddressForm.vue'
import FormSkeleton from '../skeletons/FormSkeleton.vue'
import AppContentWrapper from '../global/AppContentWrapper.vue'
import AppFormErrorAlert from '../global/forms/AppFormErrorAlert.vue'
import AppCheckboxInput from '../global/forms/inputs/AppCheckboxInput.vue'

import useCart from '../../composables/useCart'
import useForm from '../../composables/useForm'
import CartAddress from '../../types/CartAddress'
import useLoader from '../../composables/useLoader'
import useCustomer from '../../composables/useCustomer'
import { setBillingAddressOnCart } from '../../../../global/js/magento-api-library/cart/setBillingAddressOnCart'
import { setShippingAddressesOnCart } from '../../../../global/js/magento-api-library/cart/setShippingAddressesOnCart'
import { log } from '../../../../global/js/utils/logging'
import { get, update } from 'idb-keyval'

export default defineComponent({
  name: 'CartAddressesContainer',
  components: {
    AppButton,
    FormSkeleton,
    CartAddressForm,
    AppCheckboxInput,
    AppContentWrapper,
    AppFormErrorAlert,
  },
  setup() {
    const { setLoading } = useLoader()
    const { cart, getCart, setCart } = useCart()
    const { customer, setCustomer } = useCustomer()
    const { isBusy, errorMessage, setErrorMessage, clearErrorMessage } = useForm()
    const hasPhysicalItems = ref<boolean>(false)
    const hasLoaded = ref<boolean>(false)
    const hasErrors = ref<boolean>(false)
    const sameAsBilling = ref<boolean>(true)
    interface ValidationError {
      validationError: string
      fieldName: string
    }
    const validateFieldsPrompt = 'Please address the fields below.'
    const formErrors: ValidationError[] = []

    const billingInfoForm = ref({
      firstName: '',
      lastName: '',
      street: '',
      suite: '',
      city: '',
      state: '',
      stateCode: '',
      postalCode: '',
      country: '',
      regionID: 0,
      company: '',
      phoneNumber: '',
      jobTitle: '',
      phoneExtension: '',
      faxNumber: '',
    })

    const shippingInfoForm = ref({
      firstName: '',
      lastName: '',
      street: '',
      suite: '',
      city: '',
      state: '',
      stateCode: '',
      postalCode: '',
      country: '',
      regionID: 0,
      company: '',
      phoneNumber: '',
      jobTitle: '',
      phoneExtension: '',
      faxNumber: '',
    })

    const setAddressInfo = (form: Ref<CartAddress>, forBilling: boolean): void => {
      if (forBilling && cart.value.billing_address) {
        form.value.firstName = cart.value.billing_address.firstname
        form.value.lastName = cart.value.billing_address.lastname
        form.value.phoneNumber = cart.value.billing_address.telephone || ''
        form.value.company = cart.value.billing_address.company || ''
        form.value.street = cart.value.billing_address.street[0]
        form.value.suite = cart.value.billing_address.street[1] || ''
        form.value.city = cart.value.billing_address.city
        form.value.stateCode = cart.value.billing_address.region.code
        form.value.state = cart.value.billing_address.region.label
        form.value.regionID = cart.value.billing_address.region.region_id
        form.value.postalCode = cart.value.billing_address.postcode
        form.value.country = cart.value.billing_address.country?.code || null

        return
      }

      if (!forBilling && cart.value.shipping_addresses.length > 0) {
        const shippingAddress = cart.value.shipping_addresses[0]

        form.value.firstName = shippingAddress.firstname
        form.value.lastName = shippingAddress.lastname
        form.value.phoneNumber = shippingAddress.telephone || ''
        form.value.company = shippingAddress.company || ''
        form.value.street = shippingAddress.street[0]
        form.value.suite = shippingAddress.street[1] || ''
        form.value.city = shippingAddress.city
        form.value.stateCode = shippingAddress.region.code
        form.value.state = shippingAddress.region.label
        form.value.regionID = shippingAddress.region.region_id
        form.value.postalCode = shippingAddress.postcode
        form.value.country = shippingAddress.country?.code || null

        return
      }

      if (customer.value && customer.value.addresses.length) {
        form.value.firstName = customer.value.firstname
        form.value.lastName = customer.value.lastname

        const currentAddress = customer.value.addresses[customer.value.addresses.length - 1]

        form.value.phoneNumber = currentAddress.telephone || ''
        form.value.company = currentAddress.company || ''
        form.value.street = currentAddress.street[0]
        form.value.suite = currentAddress.street[1] || ''
        form.value.city = currentAddress.city
        form.value.stateCode = currentAddress.region.region_code || ''
        form.value.state = currentAddress.region.region || ''
        form.value.regionID = currentAddress.region.region_id
        form.value.postalCode = currentAddress.postcode || ''
        form.value.country = currentAddress.country_code
      }

    }

    const setShippingAsBilling = (): void => {
      shippingInfoForm.value.firstName = billingInfoForm.value.firstName
      shippingInfoForm.value.lastName = billingInfoForm.value.lastName
      shippingInfoForm.value.phoneNumber = billingInfoForm.value.phoneNumber
      shippingInfoForm.value.company = billingInfoForm.value.company || ''
      shippingInfoForm.value.street = billingInfoForm.value.street
      shippingInfoForm.value.suite = billingInfoForm.value.suite || ''
      shippingInfoForm.value.city = billingInfoForm.value.city
      shippingInfoForm.value.stateCode = billingInfoForm.value.stateCode
      shippingInfoForm.value.state = billingInfoForm.value.state
      shippingInfoForm.value.regionID = billingInfoForm.value.regionID
      shippingInfoForm.value.postalCode = billingInfoForm.value.postalCode
      shippingInfoForm.value.country = billingInfoForm.value.country
    }

    const handleSubmit = async (): Promise<void> => {
      try {
        // adding a check if the form has errors. This allows the child component to help with validation reporting
        if(formErrors.length === 0) {
          isBusy.value = true
          setLoading(true)
          clearErrorMessage()
          let cartResponse = null

          if (hasPhysicalItems.value) {
            cartResponse = await setShippingAddressesOnCart(cart.value.id, [shippingInfoForm.value])
            
            cartResponse = await setBillingAddressOnCart(cart.value.id, {
              sameAsShipping: sameAsBilling.value,
              address: billingInfoForm.value,
            })
          } else {
            cartResponse = await setBillingAddressOnCart(cart.value.id, {
              sameAsShipping: false,
              address: billingInfoForm.value,
            })
          }

          if (cartResponse !== null) {
            const updatedCart = {
              data: {
                customerCart: cartResponse,
              },
            }
            setCart(updatedCart.data.customerCart)
            const cacheKey = 'cart'
            const cacheData = await get(cacheKey)
            const cacheValue = {
              data: updatedCart,
              expiration: cacheData ? (cacheData.expiration + (300 * 1000)) : (Date.now() + (300 * 1000)), // .now returns milliseconds
            }
            await update(cacheKey, cacheData => cacheValue)
          }
          window.location.assign('/store/payment')
        } else {
          setErrorMessage(validateFieldsPrompt as string)
          document.getElementById('cart-address-form-errors')?.scrollIntoView()
        }
      } catch (error) {
        setErrorMessage(error as string)
        log(error as string, 'src/reliasmedia/js/components/checkout/CartAddressesContainer.vue')
      } finally {
        isBusy.value = false

        setLoading(false)
      }
    }

    onMounted(async (): Promise<void> => {
      try {
        await getCart()

        hasPhysicalItems.value = !(cart.value.items.find(item => item.product.__typename === 'SimpleProduct') === undefined)

        if (!hasPhysicalItems.value && !cart.value.is_virtual) {
          hasPhysicalItems.value = true
        }

        await setCustomer()
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/checkout/CartAddressesContainer.vue')
        hasErrors.value = true
      } finally {
        hasLoaded.value = true
      }
    })

    watch(hasLoaded, (newVal: boolean) => {
      if (newVal) {
        sameAsBilling.value = cart.value.shipping_addresses.length < 1

        setAddressInfo(billingInfoForm, true)
        setAddressInfo(shippingInfoForm, false)

      }
    })

    watch(sameAsBilling, (newVal: boolean) => {
      if (newVal) {
        setShippingAsBilling()
      }
    })

    const addFormError = (error: ValidationError) =>{
      formErrors.push(error)
    }

    const removeFormError = (fieldName: string) =>{
      let index = formErrors.findIndex(error => error.fieldName === fieldName)

      if (index > -1) {
        formErrors.splice(index, 1)
      }
    }

    return {
      isBusy,
      hasErrors,
      hasLoaded,
      hasPhysicalItems,
      errorMessage,
      handleSubmit,
      sameAsBilling,
      billingInfoForm,
      shippingInfoForm,
      addFormError,
      removeFormError,
    }
  },
})
