
import {computed, defineComponent, PropType, reactive, toRefs} from 'vue'

import salutations from '../../../utils/salutations'
import UserDetails from '../../../types/UserDetails'
import { errorMessage } from '../../../store/modules/ui/errorMessage'
import AppTextInput from '../../global/forms/inputs/AppTextInput.vue'
import AppSelectInput from '../../global/forms/inputs/AppSelectInput.vue'
import {useField, useForm} from 'vee-validate'

export default defineComponent({
  name: 'UserDetailsFields',
  components: {
    AppTextInput,
    AppSelectInput,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserDetails>,
      required: true,
    },
    hasError: {
      type: Boolean,
      required: false,
    },
  },
  emits:['isUserDetailsValid'],
  setup(props, context) {
    const {modelValue} = toRefs(props)
    const {meta: formMeta, setFieldValue, setFieldTouched, handleSubmit} = useForm()
    const firstNameField = reactive(useField('first_name', {required: true}))
    const lastNameField = reactive(useField('last_name', {required: true}))
    const emailField = reactive(useField('email', {email: true, required: true}))
    const passwordField = reactive(useField('password', {password: true, required: true}))
    const confirmPasswordField = reactive(useField('confirmPassword', 'confirmPassword:@password'))
    const local = computed((): UserDetails => modelValue.value)

    const userDetailsValidChange = ()=> {
      context.emit('isUserDetailsValid', formMeta.value.valid)
    }


    return {
      local,
      formMeta,
      setFieldValue,
      setFieldTouched,
      handleSubmit,
      userDetailsValidChange,
      firstNameField,
      lastNameField,
      emailField,
      passwordField,
      confirmPasswordField,
      errorMessage,
      salutations,
    }
  },
})
