
import { computed, defineComponent, onBeforeUnmount, onMounted, PropType, ref, watch } from 'vue'

import DropdownItem from '../../types/DropdownItem'
import useArrowKeys from '../../composables/useArrowKeys'
import useDesktopNav from '../../composables/useDesktopNav'

const dropdownStyles = ['split', 'default']
const dropdownSizes = ['lg', 'md', 'default']
const dropdownTypes = ['primary', 'secondary', 'success', 'info', 'warning', 'danger', 'transparent']

export default defineComponent({
  name: 'AppDropdown',
  props: {
    dropdownText: {
      type: String,
      required: false,
      default: '',
    },
    dropdownItems: {
      type: Array as PropType<DropdownItem[]>,
      required: false,
      default: () => [],
    },
    dropdownType: {
      type: String,
      required: false,
      default: 'primary',
      validator: (value: string): boolean => dropdownTypes.includes(value),
    },
    dropdownStyle: {
      type: String,
      required: false,
      default: 'default',
      validator: (value: string): boolean => dropdownStyles.includes(value),
    },
    dropdownSize: {
      type: String,
      required: false,
      default: 'default',
      validator: (value: string): boolean => dropdownSizes.includes(value),
    },
  },
  setup(props) {
    const isOpen = ref<boolean>(false)
    const dropdownElement = ref<HTMLElement | null>(null)
    const { desktopNavIsOpen, resetDesktopNav } = useDesktopNav()
    const { listItems, setListItems, menuItemIndex, handleArrowPress } = useArrowKeys()

    const isSplitStyle = computed((): boolean => props.dropdownStyle === 'split')

    const dropdownClasses = computed((): string => {
      const classes = [`dropdown--${props.dropdownType}`]

      if (props.dropdownStyle !== 'default') {
        classes.push(`dropdown--${props.dropdownStyle}`)
      }

      if (props.dropdownSize !== 'default') {
        classes.push(`dropdown--${props.dropdownSize}`)
      }

      return classes.join(' ')
    })

    const toggleDropdown = (): void => {
      isOpen.value = !isOpen.value
    }

    const closeDropdown = (): void => {
      isOpen.value = false
    }

    const closeOnClickOutside = (event: MouseEvent): void => {
      const target = event.target as HTMLElement

      if (!dropdownElement.value?.contains(target)) {
        isOpen.value = false
      }
    }

    const handleTabPress = (event: KeyboardEvent): void => {
      const lastListItem = listItems.value[listItems.value.length - 1]
      const target = event.target as HTMLAnchorElement

      if (event.target && target.textContent === lastListItem.textContent) {
        isOpen.value = false
      }
    }

    watch(isOpen, (newVal: boolean) => {
      if (newVal) {
        if (desktopNavIsOpen.value) {
          resetDesktopNav()
        }

        setListItems(`[data-dropdown-id="dropdown-${props.dropdownText}"]`)
      }
    })

    onMounted((): void => {
      window.addEventListener('click', (event) => {
        closeOnClickOutside(event)
      })
    })

    onBeforeUnmount((): void => {
      window.removeEventListener('click', closeOnClickOutside)
    })

    return {
      isOpen,
      isSplitStyle,
      setListItems,
      closeDropdown,
      menuItemIndex,
      handleTabPress,
      toggleDropdown,
      dropdownClasses,
      dropdownElement,
      handleArrowPress,
      closeOnClickOutside,
    }
  },
})
