import cloneDeep from 'lodash.clonedeep'

import Cart from '../../../../../global/js/magento-api-library/types/Cart'

export const defaultCart: Cart = {
  id: '',
  email: '',
  total_quantity: 0,
  prices: {
    subtotal_excluding_tax: {
      value: 0,
      currency: 'USD',
    },
    grand_total: {
      value: 0,
      currency: 'USD',
    },
    applied_taxes: [],
  },
  applied_coupons: null,
  items: [],
  billing_address: null,
  shipping_addresses: [],
  available_payment_methods: [
    {
      code: '',
      title: '',
    },
  ],
  selected_payment_method: {
    code: '',
    title: '',
  },
}

export interface State {
  cart: Cart,
  minicartCount: number,
}

export const state: State = {
  cart: cloneDeep(defaultCart),
  minicartCount: 0,
}
