
import debounce from 'lodash.debounce'
import { defineComponent, ref } from 'vue'

import AppTextInput from '../../global/forms/inputs/AppTextInput.vue'

import useCart from '../../../composables/useCart'
import useForm from '../../../composables/useForm'
import useNotify from '../../../composables/useNotify'
import { applyCouponToCart } from '../../../../../global/js/magento-api-library/cart/applyCouponToCart'
import { log } from '../../../../../global/js/utils/logging'
import {get, update} from 'idb-keyval'
import useAuth from '../../../composables/useAuth'

export default defineComponent({
  name: 'CartPromoCodeForm',
  components: {
    AppTextInput,
  },
  setup() {
    const { notify } = useNotify()
    const { cart, setCart } = useCart()
    const { errorMessage, setErrorMessage, clearErrorMessage } = useForm()
    const { isLoggedIn } = useAuth()

    const code = ref<string>('')
    const isChecking = ref<boolean>(false)

    const handleApplyCode = async (): Promise<void> => {
      try {
        clearErrorMessage()

        if (code.value) {
          isChecking.value = true

          const cartResponse = await applyCouponToCart(cart.value.id, code.value)
          if (cartResponse !== null) {
            let updatedCart = null
            let cacheKey = 'guestCart'

            if (isLoggedIn.value) {
              cacheKey = 'cart'
              updatedCart = {
                data: {
                  customerCart: cartResponse,
                },
              }
              setCart(updatedCart.data.customerCart)
            } else {
              updatedCart = {
                data: {
                  cart: cartResponse,
                },
              }
              setCart(updatedCart.data.cart)
            }

            const cacheData = await get(cacheKey)

            const cacheValue = {
              data: updatedCart,
              expiration: cacheData ? (cacheData.expiration + (300 * 1000)) : (Date.now() + (300*1000)), // .now returns milliseconds
            }
            await update(cacheKey, cacheData => cacheValue)
          }

          code.value = ''

          notify({
            type: 'success',
            title: 'Success!',
            text: 'Promo code was added to your cart.',
          })
        }
      } catch (error) {
        setErrorMessage(error as string)
        log(error as string, 'src/reliasmedia/js/components/checkout/cart/CartPromoCodeForm.vue')
      } finally {
        isChecking.value = false
      }
    }

    const checkCode = debounce(handleApplyCode, 500)

    return {
      cart,
      code,
      checkCode,
      isChecking,
      errorMessage,
      handleApplyCode,
    }
  },
})
