import LoginForm from './LoginForm.vue'
import AuthChecker from './AuthChecker.vue'
import RegisterForm from './RegisterForm.vue'
import ResetPasswordForm from './ResetPasswordForm.vue'
import ForgotPasswordForm from './ForgotPasswordForm.vue'
import ShowAuthUserContent from './ShowAuthUserContent.vue'

const authComponents = [LoginForm, AuthChecker, RegisterForm, ResetPasswordForm, ForgotPasswordForm, ShowAuthUserContent]

export default authComponents
