import mapComponents from './maps'
import authComponents from './auth'
import globalComponents from './global'
import searchFormComponents from './search'
import accountComponents from './account'
import articleComponents from './articles'
import issueComponents from './issues'
import checkoutComponents from './checkout'
import productsComponents from './products'
import carouselComponents from './carousels'
import navigationComponents from './navigation'
import transcriptsComponents from './transcripts'
import formComponents from './forms'

const components = [
  ...mapComponents,
  ...authComponents,
  ...globalComponents,
  ...searchFormComponents,
  ...accountComponents,
  ...articleComponents,
  ...issueComponents,
  ...carouselComponents,
  ...checkoutComponents,
  ...productsComponents,
  ...navigationComponents,
  ...transcriptsComponents,
  ...formComponents,
]

export default components
