import { MutationTree } from 'vuex'

import { State } from './state'
import { AccountMutationTypes } from './mutation-types'
import Order from '../../../../../global/js/magento-api-library/types/Order'

export interface Mutations {
  [AccountMutationTypes.SET_ACTIVE_ORDER]: (state: State, order: Order) => void
}

export const mutations: MutationTree<State> & Mutations = {
  [AccountMutationTypes.SET_ACTIVE_ORDER]: (state: State, order: Order) => {
    state.activeOrder = order
  },
}
