
import { defineComponent, PropType } from 'vue'

import SingleOrder from '../../../../../global/js/magento-api-library/types/SingleOrder'

export default defineComponent({
  name: 'OrderDetailsAddresses',
  props: {
    order: {
      type: Object as PropType<SingleOrder>,
      required: true,
    },
  },
})
