
import { defineComponent, PropType } from 'vue'
import AppTabAccordion from '../../types/AppTabAccordion'
import AppTabsAccordionItem from './AppTabsAccordionItem.vue'

export default defineComponent({
  name: 'AppTabsAccordion',
  components: {
    AppTabsAccordionItem,
  },
  props: {
    tabs: {
      type: Array as PropType<AppTabAccordion[]>,
      required: true,
    },
    activeTab: {
      type: Object as PropType<AppTabAccordion>,
      required: true,
    },
  },
  emits: ['updateActiveTab'],
  setup(props, { emit }) {
    const updateActiveTab = (tab: AppTabAccordion): void => {
      emit('updateActiveTab', tab)
    }

    return {
      updateActiveTab,
    }
  },
})
