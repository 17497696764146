
import { computed, defineComponent, onMounted, PropType, ref, toRefs } from 'vue'

import AppTextInput from '../../../global/forms/inputs/AppTextInput.vue'
import AppRadioInput from '../../../global/forms/inputs/AppRadioInput.vue'
import AppSearchableSelectRequiredFalse from '../../../global/forms/inputs/AppSearchableSelectRequiredFalse.vue'
import UserProfessionalDetails from '../../../../types/UserProfessionalDetails'
import { fetchCustomerDropDowns } from '../../../../utils/fetchCustomerDropDowns'

export default defineComponent({
  name: 'ProfessionalFields',
  components: {
    AppTextInput,
    AppRadioInput,
    AppSearchableSelectRequiredFalse,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserProfessionalDetails>,
      required: true,
    },
  },
  setup(props) {
    const { modelValue } = toRefs(props)

    const local = computed((): UserProfessionalDetails => modelValue.value)

    local.value.abimMembers.label = ''
    local.value.abmsBoard.label = ''
    local.value.specialty.label = ''
    local.value.profession.label = ''

    let abimMembers = ref([{ value: '', label: 'Select an Option' }])
    let abmsBoards = ref([{ value: '', label: 'Select an Option' }])
    let primaryCredentials = ref([{ value: '', label: 'Select an Option' }])
    let specialties = ref([{ value: '', label: 'Select an Option' }])
    let professions = ref([{ value: '', label: 'Select an Option' }])
    let credentialsSecondary = ref([{ value: '', label: 'Select an Option' }])

    const getOptions = async (): Promise<void> => {
      try {
        const dropDowns = await fetchCustomerDropDowns()

        if(dropDowns) {
          abimMembers.value = sortOptions(dropDowns[0].attribute_options)
          abmsBoards.value = sortOptions(dropDowns[1].attribute_options)
          primaryCredentials.value = sortOptions(dropDowns[2].attribute_options)
          professions.value = sortOptions(dropDowns[4].attribute_options)
          credentialsSecondary.value = sortOptions(dropDowns[5].attribute_options)
          specialties.value = sortOptions(dropDowns[3].attribute_options)

          for (let i = 0; i < specialties.value.length; i++) {
            specialties.value[i].label = specialties.value[i].label.replace(/&amp;/g, "&");
          }
        }
      } catch (error) {
        console.log('Unable to load license data.')
      }
    }

    const sortOptions = (option: [{label: string, value: string}]) => {
      return option.sort((a,b) => {
        if(a.label < b.label){
          return -1
        }
        if(a.label > b.label){
          return 1
        }
        return 0
      })
    }

    onMounted(async (): Promise<void> => {
      await getOptions()

      const abimMembersValue = abimMembers.value.find(option => option.value === local.value.abimMembers.value)
      const abmsBoardsValue = abmsBoards.value.find(option => option.value === local.value.abmsBoard.value)
      const specialtiesValue = specialties.value.find(option => option.value === local.value.specialty.value)
      const professionsValue = professions.value.find(option => option.value === local.value.profession.value)

      local.value.abimMembers.label = abimMembersValue?.label || ''
      local.value.abmsBoard.label = abmsBoardsValue?.label || ''
      local.value.specialty.label = specialtiesValue?.label || ''
      local.value.profession.label = professionsValue?.label || ''
    })

    return {
      local,
      abmsBoards,
      abimMembers,
      professions,
      specialties,
      primaryCredentials,
      credentialsSecondary,
    }
  }
})
