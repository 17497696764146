import AppAlert from './AppAlert.vue'
import AppModal from './AppModal.vue'
import AppButton from './AppButton.vue'
import AppLoader from './AppLoader.vue'
import AppDropdown from './AppDropdown.vue'
import AppCarousel from './AppCarousel.vue'
import AppPagination from './AppPagination.vue'
import AppInlineAlert from './AppInlineAlert.vue'
import AppCarouselSlide from './AppCarouselSlide.vue'
import AppTabsAccordion from './AppTabsAccordion.vue'
import AppCookiePolicy from './AppCookiePolicy.vue'
import AppTextInput from './forms/inputs/AppTextInput.vue'
import AppRadioInput from './forms/inputs/AppRadioInput.vue'
import AppFormErrorAlert from './forms/AppFormErrorAlert.vue'
import AppTabsAccordionItem from './AppTabsAccordionItem.vue'
import AppSelectInput from './forms/inputs/AppSelectInput.vue'
import AppFlashNotifications from './AppFlashNotifications.vue'
import AppCheckboxInput from './forms/inputs/AppCheckboxInput.vue'
import AppSearchableSelect from './forms/inputs/AppSearchableSelect.vue'
import AppAccordion from './accordion/AppAccordion.vue'
import AppAccordionPanel from './accordion/AppAccordionPanel.vue'
import AppBreadcrumbs from './AppBreadcrumbs.vue'

const globalComponents = [
  AppAlert,
  AppModal,
  AppButton,
  AppLoader,
  AppCarousel,
  AppDropdown,
  AppTextInput,
  AppAccordion,
  AppPagination,
  AppRadioInput,
  AppBreadcrumbs,
  AppInlineAlert,
  AppSelectInput,
  AppCookiePolicy,
  AppCarouselSlide,
  AppCheckboxInput,
  AppTabsAccordion,
  AppAccordionPanel,
  AppFormErrorAlert,
  AppSearchableSelect,
  AppTabsAccordionItem,
  AppFlashNotifications,
]

export default globalComponents
