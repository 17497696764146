import axios, { AxiosResponse } from 'axios'

interface GetIssueBySkuResponseItem {
    sku: string | null
    issueUrl: string | null
}
interface GetIssueBySkuResponse extends Array<GetIssueBySkuResponseItem> {
    errors? : [{message: string}]
}

export const getIssueUrlBySku = async (sku: string): Promise<string | null> => {
    const { data }: AxiosResponse<GetIssueBySkuResponse> = await axios({
        method: 'POST',
        url: '/get-issue-by-sku',
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json'
        },
        data: {
            skus: sku,
        },
    })
    if (data.errors) {
        throw data.errors[0].message
    }
    if (typeof data[0] !== 'undefined') {
        return data[0].issueUrl
    }

    return null
}
