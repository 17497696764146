import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import Subscription from '../types/Subscription'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface GetSubscriptionsResponse {
  data: {
    subscriptions: Subscription[] | null
  }
  errors?: [{ message: string }]
}

export const subscriptionsQuery = gql`
  query {
    subscriptions {
      entity_id
      description
      next_run
      status
      quote {
        items {
          sku
          name
        }
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const getSubscriptions = async (): Promise<Subscription[] | null> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<GetSubscriptionsResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: subscriptionsQuery,
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.subscriptions) {
    data.data.subscriptions.filter((subscription)=>{
      return subscription.next_run >= Date()
    })
    return data.data.subscriptions
  }

  return null
}
