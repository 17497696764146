
import { defineComponent, nextTick, ref } from 'vue'
import useLoader from '../../composables/useLoader'
import useForm from '../../composables/useForm'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'UtilityNavigationSearch',
  components: { FontAwesomeIcon },
  setup() {
    const showForm = ref<boolean>(false)
    const searchQuery = ref<string>('')
    const { setLoading } = useLoader()
    const { isBusy } = useForm()
    const closeSearch = async (): Promise<void> => {
      showForm.value = false

      await nextTick()

      const button = document.querySelector('button.utility-nav__search__toggle') as HTMLButtonElement

      if (button) {
        button.focus()
      }
    }

    const handleSearch = (): void => {
      isBusy.value = true

      setLoading(true)

      /**
       * https://www.algolia.com/doc/guides/building-search-ui/going-further/routing-urls/vue/
       * Set up the search input name according to algolia requirements
       */
      let searchInputName = process.env.ELASTICSEARCH_RM_INDEX +'[query]' ?? ''

      window.location.href = '/search?'+ searchInputName + '=' + `${searchQuery.value}`
    }

    const showSearch = async (): Promise<void> => {
      showForm.value = true

      await nextTick()

      const input = document.getElementById('search_query') as HTMLInputElement

      if (input) {
        input.focus()
      }
    }

    return {
      showForm,
      showSearch,
      closeSearch,
      searchQuery,
      handleSearch,
    }
  },
})
