
import {computed, defineComponent, PropType, ref, toRefs} from 'vue'
import UserProfileReview from '../../../../types/UserProfileReview'
import AppCheckboxInput from '../../../global/forms/inputs/AppCheckboxInput.vue'
import { errorMessage } from '../../../../store/modules/ui/errorMessage'

export default defineComponent({
  name: 'ReviewField',
  components: {
    AppCheckboxInput,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserProfileReview>,
      required: true,
    },
  },
  emits:['isReviewValid'],
  setup(props, context) {
    const { modelValue } = toRefs(props)

    let isReviewedError = ref(false)

    const local = computed((): UserProfileReview => modelValue.value)

    const reviewChange = (review : boolean)=> {
      if (review) {
        isReviewedError.value = true
        context.emit('isReviewValid', false)
      }
      else {
        isReviewedError.value = false
        context.emit('isReviewValid', true)
      }
    }

    return {
      local,
      isReviewedError,
      errorMessage,
      reviewChange,
    }
  },
})
