import axios, { AxiosResponse } from 'axios'

import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

type ShippingMethod = {
  carrier_code: string
  method_code: string
}

interface SetShippingMethodsOnCartResponse {
  data: {
    setShippingMethodsOnCart: { cart: Cart } | null
  }
  errors?: [{ message: string }]
}

export const setShippingMethodsOnCartMutation = gql`
  ${cartFragment}
  mutation($cartId: String!, $shippingMethods: [ShippingMethodInput!]!) {
    setShippingMethodsOnCart(
      input: { cart_id: $cartId, shipping_methods: $shippingMethods }
    ) {
      cart {
        ...CartFields
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const setShippingMethodsOnCart = async (
  cartId: string,
  shippingMethods: ShippingMethod[],
): Promise<Cart | null> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<SetShippingMethodsOnCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: setShippingMethodsOnCartMutation,
      variables: {
        cartId,
        shippingMethods,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.setShippingMethodsOnCart) {
    return data.data.setShippingMethodsOnCart.cart
  }

  return null
}
