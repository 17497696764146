import CartItem from '../magento-api-library/types/CartItem'
import SingleOrder from '../magento-api-library/types/SingleOrder'
import {trackAddToCartGa4, trackPurchaseGa4} from './ga4'
import {trackAddToCartUa, trackPurchaseUa} from './ua'

export const trackPurchase = (order: SingleOrder): void => {
	trackPurchaseGa4(order)
	trackPurchaseUa(order)
}

export const trackAddProductToCart = (cartItem: CartItem | undefined, quantity: number): void => {
	if (!cartItem) return

	trackAddToCartGa4(cartItem, quantity)
	trackAddToCartUa(cartItem, quantity)
}