
import { computed, defineComponent, PropType } from 'vue'
import AppTabAccordion from '../../types/AppTabAccordion'

export default defineComponent({
  name: 'AppTabsAccordionItem',
  props: {
    tab: {
      type: Object as PropType<AppTabAccordion>,
      required: true,
    },
    activeTab: {
      type: Object as PropType<AppTabAccordion>,
      required: true,
    },
  },
  emits: ['setActiveTab'],
  setup(props, { emit }) {
    const isActive = computed((): boolean => props.tab.title === props.activeTab.title)

    const tabClass = computed((): string => isActive.value ? 'tabs-accordion__tab--active' : 'tabs-accordion__tab--inactive')

    const setActiveTab = (tab: AppTabAccordion): void => {
      emit('setActiveTab', tab)
    }

    return {
      isActive,
      tabClass,
      setActiveTab,
    }
  },
})
