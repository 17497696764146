
import {computed, defineComponent, PropType} from 'vue'

import CartAddress from '../../../../../global/js/magento-api-library/types/CartAddress'

export default defineComponent({
  name: 'CartAddressInfoItem',
  props: {
    heading: {
      type: String,
      required: true,
    },
    address: {
      type: Object as PropType<CartAddress>,
      required: true,
    },
  },
  setup(props) {
    const region = computed((): string => {
      return props.address.region.code === null ? '' : props.address.region.code
    })

    return {
      region,
    }
  },
})
