
import {defineComponent, onMounted, ref} from 'vue'
import ProductsCategoryDropdownItem from './ProductsCategoryDropdownItem.vue'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'ProductsCategoryDropdown',
  components: {
    FontAwesomeIcon,
    //ProductsCategoryDropdownItem,
  },
  props: {
    label: {
      type: String,
      required: true,
      default: '',
    },
  },
  setup() {
    const isOpen = ref<boolean>(true)
    const dropdownIconClass = ref<string>('angle-down')

    const toggleDropdown = (): void => {
      isOpen.value = !isOpen.value
      dropdownIconClass.value = isOpen.value ? 'angle-down':'angle-left'
    }

    return {
      isOpen,
      toggleDropdown,
      dropdownIconClass,
    }
  },
})
