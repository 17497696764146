
import { defineComponent, watch } from 'vue'
import useModal from '../../composables/useModal'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'AppModal',
  components: { FontAwesomeIcon },
  props: {
    name: {
      type: String,
      required: true,
    },
    showCloseButton: {
      type: Boolean,
      required: false,
      default: true,
    },
    subtitle: {
      type: String,
      required: false,
      default: '',
    },
  },
  setup(props) {
    const { modalIsOpen, openedModal, resetOpenedModal } = useModal()

    const handleOpened = (): void => {
      document.body.classList.add('body--lock')
    }

    const handleClosed = (): void => {
      resetOpenedModal()

      document.body.classList.remove('body--lock')
    }

    const handleWrapperClose = (e:Event): void => {
      const el = <Element>e.target

      if (el.classList.contains('modal-wrapper')) {
          resetOpenedModal()
      }
    }

    watch(openedModal, (newValue: string): void => {
      if (newValue === props.name) {
        handleOpened()
      }
    })

    return {
      openedModal,
      modalIsOpen,
      handleClosed,
      handleOpened,
      handleWrapperClose
    }
  },
})
