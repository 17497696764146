const print = (printContentId: string): void => {
  const printDocument = window.open('')
  const printContents = document.querySelector(`#${printContentId}`)?.innerHTML
  if(printDocument && printContents) {
    const style = `<style>
        .text-center {
            text-align: center;
        }
        .table {
          border-collapse: collapse !important;
          font-size: 0.875rem !important;
          line-height: 1.25rem !important;
          margin: 1rem 0 !important;
          overflow-x: auto !important;
          table-layout: auto !important;
          text-align: left !important;
          width: 100% !important;
        }
        table, th, td {
          border: 1px solid black;
        }
        .thead {
          background-color: #f8f8f8 !important;
          border-bottom-color: #e6e6e6 !important;
          border-bottom-style: solid !important;
          border-bottom-width: 1px !important;
          color: #636363 !important;
          font-weight: 500 !important;
        }
        th {
            padding: 0.5rem 0.5rem;
            text-align: center;
        }
        th>h4 {
            margin: 0;
        }
        tbody, tr {
          border-bottom: 1px solid #e6e6e6 !important;
        }
        td {
          padding: 1rem 0.5rem !important;
        }
    </style>`
    printDocument.document.write(style + printContents)
    printDocument.stop()
    printDocument.print()
    // printDocument.close()
  }
}

export default print