import cloneDeep from 'lodash.clonedeep'
import Order from '../../../../../global/js/magento-api-library/types/Order'

export const defaultActiveOrder = {
  id: '',
  number: '0',
  order_date: '',
  invoices: [],
  billing_address: {
    firstname: '',
    lastname: '',
    telephone: '',
    company: '',
    street: [],
    city: '',
    region: {
      code: '',
      label: '',
      region_id: 0,
    },
    postcode: '',
    country: {
      code: '',
      label: '',
    },
  },
  items: [],
  total: {
    subtotal: {
      value: 0,
      currency: '',
    },
    grand_total: {
      value: 0,
      currency: '',
    },
    total_tax: {
      value: 0,
      currency: '',
    },
  },
}

export interface State {
  activeOrder: Order
}

export const state: State = {
  activeOrder: cloneDeep(defaultActiveOrder),
}
