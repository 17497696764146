
import { computed, defineComponent, PropType, toRefs } from 'vue'

import MapState from '../../types/MapState'

export default defineComponent({
  name: 'MapForm',
  props: {
    modelValue: {
      type: String,
      required: true,
    },
    states: {
      type: Array as PropType<MapState[]>,
      required: true,
    },
  },
  setup(props, { emit }) {
    const { modelValue } = toRefs(props)

    const local = computed({
      get: () => modelValue.value,
      set: (value) => {
        emit('update:modelValue', value)

        if (value === 'all') {
          window.history.pushState(null, '', window.location.pathname)
        } else {
          window.location.hash = `#!${value}`
        }
      },
    })

    return {
      local,
    }
  },
})
