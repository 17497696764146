
import { defineComponent, ref, watch } from 'vue'

import AppButton from '../global/AppButton.vue'
import UserAddressFields from './register/UserAddressFields.vue'
import UserDetailsFields from './register/UserDetailsFields.vue'
import ReviewField from '../account/tabs/account/ReviewField.vue'
import AppFormErrorAlert from '../global/forms/AppFormErrorAlert.vue'
import ProfessionalFields from '../account/tabs/account/ProfessionalFields.vue'
import useForm from '../../composables/useForm'
import UserAddress from '../../types/UserAddress'
import UserDetails from '../../types/UserDetails'
import useLoader from '../../composables/useLoader'
import UserProfileReview from '../../types/UserProfileReview'
import UserProfessionalDetails from '../../types/UserProfessionalDetails'
import Vue3Recaptcha from 'vue3-recaptcha2'
import useCart from '../../composables/useCart'

import { login } from '../../../../global/js/magento-api-library/auth/login'
import { createCustomer } from '../../../../global/js/magento-api-library/auth/register'
import { createAddress } from '../../../../global/js/magento-api-library/address/createAddress'
import { log } from '../../../../global/js/utils/logging'
import { useReCaptcha } from 'vue-recaptcha-v3'
import { RECAPTCHA_V2_SITE_KEY, validateRecaptchaV3, recaptchaChallenge } from '../../../../global/js/utils/recaptcha'


export default defineComponent({
  name: 'RegisterForm',
  components: {
    AppButton,
    ReviewField,
    AppFormErrorAlert,
    UserAddressFields,
    UserDetailsFields,
    ProfessionalFields,
    Vue3Recaptcha,
  },
  setup() {
    const { setLoading } = useLoader()
    const { isBusy, errorMessage, setErrorMessage, clearErrorMessage } = useForm()
    const { cart, combineCarts, getCart} = useCart()
    let hasError = ref<boolean>(false)
    let userDetailsFlag = false
    let addressFlag = false
    let reviewFlag = false

    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha() || {}

    const userDetailsForm = ref<UserDetails>({
      prefix: '',
      firstName: '',
      middleName: '',
      lastName: '',
      email: '',
      password: '',
      passwordConfirmation: '',
      birthdate: '',
      npiNumber: '',
      abimMembers: '',
      abimMemberId: '',
      abmsBoard: '',
      abmsBoardId: '',
      primaryCredentials: '',
      secondaryCredentials: '',
      specialty: '',
      profession: '',
      jobTitle: '',
      phoneExtension: '',
      fax: '',
      mediaReviewed: 0,
    })

    const userAddressForm = ref<UserAddress>({
      firstName: '',
      lastName: '',
      street: '',
      suite: '',
      city: '',
      state: '',
      stateCode: '',
      postalCode: '',
      country: '',
      regionID: 1,
      company: '',
      jobTitle: '',
      phoneNumber: '',
      phoneExtension: '',
      faxNumber: '',
    })

    const reviewForm = ref<UserProfileReview>({
      hasReviewed: false,
    })

    const professionalDetailsForm = ref<UserProfessionalDetails>({
      birthdate: '',
      npiNumber: '',
      abimMembers: {
        value: '',
        label: '',
      },
      abimMemberId: '',
      abmsBoard: {
        value: '',
        label: '',
      },
      abmsBoardId: '',
      primaryCredentials: '',
      secondaryCredentials: '',
      specialty: {
        value: '',
        label: '',
      },
      profession: {
        value: '',
        label: '',
      },
    })

    const checkUserDetailsValidation = (isUserDetailsValid : boolean) => {
      if (isUserDetailsValid) {
        userDetailsFlag = true
        checkFormValidation()
      }
      else {
        userDetailsFlag = false
        isBusy.value = true
      }
    }

    const checkAddressValidation = (isAddressValid : boolean) => {
      if (isAddressValid) {
        addressFlag = true
        checkFormValidation()
      }
      else {
        addressFlag = false
        isBusy.value = true
      }
    }

    const checkReviewValidation = (isReviewValid : boolean) => {
      if (isReviewValid) {
        reviewFlag = true
        checkFormValidation()
      }
      else {
        reviewFlag = false
        isBusy.value = true
      }
    }

    const checkFormValidation = () => {
      if (userDetailsFlag && addressFlag && reviewFlag) {
        isBusy.value = false
      }
    }

    const reCaptchaKey = ref('')

    isBusy.value = true

    const handleSubmit = async (validateRecaptcha = true): Promise<void> => {
      try {

        // If recaptcha needs to be validated and the recaptcha plugin loaded successfully
        if (validateRecaptcha && recaptchaLoaded && executeRecaptcha) {
          
          // Wait until the plugin is ready
          await recaptchaLoaded()

          // Get and validate the V3 token
          reCaptchaKey.value = await executeRecaptcha('submit')
          const recaptchaResult = await validateRecaptchaV3(reCaptchaKey.value)

          if (!recaptchaResult.success) {
            // Recaptcha failed to validate
            log('ReCaptcha did not validate using V3', 'src/reliasmedia/js/components/auth/RegisterForm.vue')
            setErrorMessage('An error has occured. Please try again later.')
            return
          } else if(recaptchaResult.success && recaptchaChallenge.value) {
            // Recaptcha validated but user confidence score is low, stop processing form submission. User will be challenged with recaptcha V2
            return
          }
        }

        setLoading(true)
        let formatDate = (x: string) => {
          let dateObj = new Date(x)
          if (dateObj.toString() === 'Invalid Date') { return ''}
          return (dateObj.getMonth() + 1) + '/' + dateObj.getUTCDate() + '/' + dateObj.getFullYear()
        }

        clearErrorMessage()
        userDetailsForm.value.birthdate = formatDate(professionalDetailsForm.value.birthdate)
        userDetailsForm.value.abimMemberId = professionalDetailsForm.value.abimMemberId
        userDetailsForm.value.npiNumber = professionalDetailsForm.value.npiNumber
        userDetailsForm.value.abimMembers = professionalDetailsForm.value.abimMembers.value
        userDetailsForm.value.abmsBoard = professionalDetailsForm.value.abmsBoard.value
        userDetailsForm.value.abmsBoardId = professionalDetailsForm.value.abmsBoardId
        userDetailsForm.value.primaryCredentials = professionalDetailsForm.value.primaryCredentials
        userDetailsForm.value.secondaryCredentials = professionalDetailsForm.value.secondaryCredentials
        userDetailsForm.value.specialty = professionalDetailsForm.value.specialty.value
        userDetailsForm.value.profession = professionalDetailsForm.value.profession.value
        userDetailsForm.value.jobTitle = userAddressForm.value.jobTitle
        userDetailsForm.value.phoneExtension = userAddressForm.value.phoneExtension
        userDetailsForm.value.fax = userAddressForm.value.faxNumber
        userDetailsForm.value.mediaReviewed = reviewForm.value.hasReviewed ? 1: 0

        userAddressForm.value.firstName = userDetailsForm.value.firstName
        userAddressForm.value.lastName = userDetailsForm.value.lastName

        await createCustomer(userDetailsForm.value, reCaptchaKey.value)

        const loginWasSuccessful = await login(userDetailsForm.value.email, userDetailsForm.value.password)

        //Check to make sure the customer was logged in and if they had any items.  If so merge guest and new users carts
        if (loginWasSuccessful) {
          await getCart()
          const guestCartItems = cart.value.total_quantity

          if (guestCartItems > 0) {
            await combineCarts()
          }
        }

        //Once the customer is created save their address
        await createAddress(userAddressForm.value)

        window.location.assign('/user/profile')
      } catch (error) {
        setErrorMessage(error as string)

        hasError.value = true

        const formErrorEl = document.querySelector('#register-form-errors') as HTMLDivElement
        if (formErrorEl) {
          formErrorEl.scrollIntoView()
        }

      } finally {
        isBusy.value = false

        setLoading(false)
      }
    }

    /**
     * V2 Challenge Response handler
     */
    const handleRecaptchaV2Response = (token: string) => {
      if (token) {
        //submitForm(formDataToSend)
        handleSubmit(false)
      } else {
        log('Recaptcha v2 validation failed.', 'src/reliasmedia/js/components/auth/RegisterForm.vue')
      }
    }

    /**
     * Move the user to the top of the page if an error occurs
     */
    watch(errorMessage, (newErrorFlag) => {
      if (newErrorFlag) {
        window.scrollTo({ top: 0, behavior: 'smooth' })
      }
    })

    return {
      isBusy,
      reviewForm,
      errorMessage,
      handleSubmit,
      checkUserDetailsValidation,
      checkAddressValidation,
      checkReviewValidation,
      userAddressForm,
      userDetailsForm,
      professionalDetailsForm,
      hasError,
      recaptchaChallenge,
      RECAPTCHA_V2_SITE_KEY,
      handleRecaptchaV2Response,
    }
  },
})
