import axios, { AxiosResponse } from 'axios'

import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import CustomerAddress from '../types/CustomerAddress'
import getBearerToken from '../../utils/getBearerToken'

interface SetBillingAddressOnCartResponse {
  data: {
    setBillingAddressOnCart: { cart: Cart } | null
  }
  errors?: [{ message: string }]
}

export const setBillingAddressOnCartMutation = gql`
  ${cartFragment}
  mutation($cartId: String!, $billingAddress: BillingAddressInput!) {
    setBillingAddressOnCart(
      input: { cart_id: $cartId, billing_address: $billingAddress }
    ) {
      cart {
        ...CartFields
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const setBillingAddressOnCart = async (
  cartId: string,
  billingAddress: { sameAsShipping: boolean; address: CustomerAddress },
): Promise<Cart | null> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<SetBillingAddressOnCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: setBillingAddressOnCartMutation,
      variables: {
        cartId,
        billingAddress: {
          same_as_shipping: billingAddress.sameAsShipping,
          address: {
            firstname: billingAddress.address.firstName,
            lastname: billingAddress.address.lastName,
            street: billingAddress.address.suite
              ? [billingAddress.address.street, billingAddress.address.suite]
              : [billingAddress.address.street],
            city: billingAddress.address.city,
            region: billingAddress.address.stateCode,
            postcode: billingAddress.address.postalCode,
            country_code: billingAddress.address.country,
            telephone: billingAddress.address.phoneNumber || '',
            company: billingAddress.address.company,
          },
        },
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.setBillingAddressOnCart) {
    return data.data.setBillingAddressOnCart.cart
  }

  return null
}
