import { ref, Ref } from 'vue'
import {logout} from '../../../global/js/magento-api-library/auth/logout'

interface UseAuth {
  isLoggedIn: Ref<boolean>
}

const useAuth = (forceLoginOnExpire = false): UseAuth => {
  const isLoggedIn = ref(false)
  const token = localStorage.getItem('authToken')

  if (token) {
    const { expiration } = JSON.parse(token)

    if (Date.now() > Date.parse(expiration)) {
      logout().then(() => {
        // TODO: Investigate if this is necessary since we have AuthChecker.vue
        if(forceLoginOnExpire) {
          window.location.assign('/user/login')
        }
      })
    } else {
      isLoggedIn.value = true
    }
  }

  return {
    isLoggedIn,
  }
}

export default useAuth
