
import { defineComponent, onMounted, ref } from 'vue'
import { getSubscriptions } from '../../../../global/js/magento-api-library/subscriptions/getSubscriptions'
import { log } from '../../../../global/js/utils/logging'
import useAuth from '../../composables/useAuth'
import useNotify from '../../composables/useNotify'
import useForm from '../../composables/useForm'
import AddToCartButton from './AddToCartButton.vue'

export default defineComponent({
  name: 'SubscribeNow',
  components: {
    AddToCartButton,
  },
  props: {
    productSku: {
      type: String,
      required: true,
    },
    buttonClasses: {
      type: String,
      required: false,
      default: null,
    },
    buttonText: {
      type: String,
      required: false,
      default: 'Add to Cart',
    },
    redirectUrl: {
      type: String,
      required: false,
      default: null,
    },
  },
  setup(props) {

    const showAddToCartBtn = ref<boolean>(false)
    const userIsSubscribed = ref<boolean>(false)
    const { isLoggedIn } = useAuth()
    const { notify } = useNotify()
    const { setErrorMessage } = useForm()

    onMounted(async (): Promise<void> => {
      if (isLoggedIn.value) {
        try {
          const results = await getSubscriptions()

          if (results) {
            for (let i = 0; i < results.length; i++) {
              const items = results[i].quote.items

              if (items.find(item => item.sku === props.productSku) || items.find(item => item.sku === 'RM-AAP')) {
                userIsSubscribed.value = true
                break
              }
            }
          }
        } catch (error) {
          setErrorMessage(error as string)

          log(error as string, 'src/reliasmedia/js/components/products/SubscribeNow.vue')

          notify({
            type: 'error',
            title: 'Error',
            text: 'Subscription data failed to load.',
            duration: -1,
          })
        }
      }

      if (!userIsSubscribed.value || !isLoggedIn.value) {
        showAddToCartBtn.value = true
      }
    })

    return {
      showAddToCartBtn,
    }
  },
})
