
import { defineComponent, onMounted } from 'vue'

import useAuth from '../../composables/useAuth'

export default defineComponent({
  name: 'AuthChecker',
  setup() {
    const { isLoggedIn } = useAuth()

    onMounted((): void => {
      if (!isLoggedIn.value) {
        window.location.assign('/user/login')
      }
    })
  },
})
