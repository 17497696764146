
import { defineComponent } from 'vue'

import useAuth from '../../composables/useAuth'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'DownloadLimiter',
  components: { FontAwesomeIcon },
  props: {
    url: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const {isLoggedIn} = useAuth()

    return {
      isLoggedIn,
    }
  },
})
