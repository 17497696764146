
import {defineComponent} from "vue";

export default defineComponent({
  name: 'AppAccordion',
  props: {
    classes: {
      default: '',
      type: String,
    },
  },
})
