import { nextTick } from 'vue'
import { notify as vueNotify } from '@kyvg/vue3-notification'

type NotifyOptions = {
  group?: string
  title?: string
  text: string
  type?: string
  duration?: number
  speed?: number
}

interface UseNotify {
  notify: (options: NotifyOptions) => Promise<void>
}

const useNotify = (): UseNotify => {
  const notify = async (options: NotifyOptions): Promise<void> => {
    vueNotify(options)

    await nextTick()

    const notification = document.querySelector('div.notification') as HTMLDivElement

    if (notification) {
      notification.focus()
    }
  }

  return {
    notify,
  }
}

export default useNotify
