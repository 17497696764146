import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface PlaceOrderResponse {
  data: {
    placeOrder: {
      order: {
        order_number: string
        sso_url: string
      }
    } | null
  }
  errors?: [{ message: string }]
}

export const placeOrderMutation = gql`
  mutation($cartId: String!) {
    placeOrder(input: { cart_id: $cartId }) {
      order {
        order_number
        sso_url
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const placeOrder = async (cartId: string): Promise<string | any> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<PlaceOrderResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: placeOrderMutation,
      variables: {
        cartId,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.placeOrder) {
    if (data.data.placeOrder.order.sso_url) {
      localStorage.setItem('sso_url', data.data.placeOrder.order.sso_url)
    }
    return data.data.placeOrder.order.order_number
  }

  return null
}
