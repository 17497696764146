import { del } from 'idb-keyval'
import axios, { AxiosResponse } from 'axios'

import Cart from '../types/Cart'
import gql from '../../utils/gql'
import cartFragment from './fragments/cartFragment'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

type SimpleCartItemInput = {
  quantity: number
  sku: string
}
type BundledCartItemInput = {
  quantity: number
  sku: string
  selected_options: Array<string>
}

interface AddProductsToCartResponse {
  data: {
    addProductsToCart: { cart: Cart } | null,
    addSimpleProductsToCart: { cart: Cart } | null,
  }
  errors?: [{ message: string }]
}

export const addProductsToCartMutation = gql`
  ${cartFragment}
  mutation($cartId: String!, $cartItems: [SimpleProductCartItemInput!]!) {
    addSimpleProductsToCart(input: { cart_id: $cartId, cart_items: $cartItems }) {
      cart {
        ...CartFields
      }
    }
  }
`

export const addBundledProductsToCartMutation = gql`
  ${cartFragment}
  mutation($cartId: String!, $cartItems: [CartItemInput!]!) {
    addProductsToCart(cartId: $cartId, cartItems: $cartItems) {
      cart {
        ...CartFields
      }
    }
  }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const addProductsToCart = async (
  cartId: string,
  cartItems: SimpleCartItemInput[],
): Promise<Cart | null> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }

  const { data }: AxiosResponse<AddProductsToCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: addProductsToCartMutation,
      variables: {
        cartId,
        cartItems: cartItems.map(item => ({
          data: {
            quantity: item.quantity,
            sku: item.sku,
          },
        })),
      },
    },
  })

  // Clear the Request cache when any cart request has been made, we want to prevent a user from having a stuck cart
  await del('guestCart')
  await del('cart')

  if(data.data.addSimpleProductsToCart){
    localStorage.setItem('cart', JSON.stringify(data.data.addSimpleProductsToCart.cart))
  }

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.addSimpleProductsToCart) {
    return data.data.addSimpleProductsToCart.cart
  }

  return null
}

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const addBundledProductsToCart = async (
  cartId: string,
  cartItems: BundledCartItemInput[],
): Promise<Cart | null> => {
  const bearerToken = getBearerToken()

  const headers = bearerToken
    ? {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    }
    : {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    }
  const { data }: AxiosResponse<AddProductsToCartResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers,
    data: {
      query: addBundledProductsToCartMutation,
      variables: {
        cartId,
        cartItems
      },
    },
  })

  // Clear the Request cache when any cart request has been made, we want to prevent a user from having a stuck cart
  await del('guestCart')
  await del('cart')

  if(data.data.addProductsToCart){
    localStorage.setItem('cart', JSON.stringify(data.data.addProductsToCart.cart))
  }

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.addProductsToCart) {
    return data.data.addProductsToCart.cart
  }

  return null
}
