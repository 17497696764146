import { computed, ComputedRef } from 'vue'

import { useStore } from '../store/index'
import { logout } from '../../../global/js/magento-api-library/auth/logout'
import Customer from '../../../global/js/magento-api-library/types/Customer'
import { CustomerMutationTypes } from '../store/modules/customer/mutation-types'
import { getCustomer } from '../../../global/js/magento-api-library/customer/getCustomer'

interface UseCustomer {
  customer: ComputedRef<Customer | null>
  setCustomer: () => Promise<void>
}

const useCustomer = (): UseCustomer => {
  const store = useStore()

  const customer = computed((): Customer | null => store.state.customer?.customer)

  const setCustomer = async (): Promise<void> => {
    try {
      const customer = await getCustomer()

      if (customer) {
        store.commit(CustomerMutationTypes.SET_CUSTOMER, customer)
      }
    } catch (error) {
      await logout()
    }
  }

  return {
    customer,
    setCustomer,
  }
}

export default useCustomer
