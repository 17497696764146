
import { defineComponent } from 'vue'

const alertTypes = ['success', 'error', 'warning']

export default defineComponent({
  name: 'AppInlineAlert',
  props: {
    alertType: {
      type: String,
      required: false,
      default: null,
      validator: (value: string): boolean => alertTypes.includes(value),
    },
  },
})
