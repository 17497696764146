
import { computed, defineComponent, PropType, ref } from 'vue'

import useCart from '../../../composables/useCart'
import useLoader from '../../../composables/useLoader'
import useNotify from '../../../composables/useNotify'
import CartItem from '../../../../../global/js/magento-api-library/types/CartItem'
import { removeProductFromCart } from '../../../../../global/js/magento-api-library/cart/removeProductFromCart'
import { log } from '../../../../../global/js/utils/logging'
import {get, update} from 'idb-keyval'
import useAuth from '../../../composables/useAuth'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

export default defineComponent({
  name: 'CartTableRow',
  components: { FontAwesomeIcon },
  props: {
    item: {
      type: Object as PropType<CartItem>,
      required: true,
    },
  },
  setup(props) {
    const { notify } = useNotify()
    const { setLoading } = useLoader()
    const { cart, setCart, isCartPage, setMinicartCount } = useCart()
    const isLoading = ref<boolean>(false)
    const { isLoggedIn } = useAuth()

    const discount = computed((): string => {
      return props.item.prices.total_item_discount.value
        ? `$${props.item.prices.total_item_discount.value.toFixed(2)}`
        : ''
    })

    const netPrice = computed((): string => {
      return props.item.prices.total_item_discount.value
        ? (props.item.prices.price.value - props.item.prices.total_item_discount.value).toFixed(2)
        : props.item.prices.price.value.toFixed(2)
    })

    const handleRemoveItem = async (): Promise<void> => {
      try {
        isLoading.value = true

        setLoading(true)
        const cartResponse = await removeProductFromCart(cart.value.id, props.item.uid)
        if (cartResponse !== null) {
          let updatedCart = null
          let cacheKey = 'guestCart'

          if (isLoggedIn.value) {
            cacheKey = 'cart'
            updatedCart = {
              data: {
                customerCart: cartResponse,
              },
            }
            setCart(updatedCart.data.customerCart)
          } else {
            updatedCart = {
              data: {
                cart: cartResponse,
              },
            }
            setCart(updatedCart.data.cart)
          }
          let cacheData = await get(cacheKey)
          // update cache since this delete item request is async
          const cacheValue = {
            data: updatedCart,
            expiration: cacheData ? (cacheData.expiration + (300 * 1000)) : (Date.now() + (300*1000)), // .now returns milliseconds
          }
          await update(cacheKey,cacheData=> cacheValue)

          setMinicartCount(cart.value.total_quantity)
        }

        notify({
          type: 'success',
          title: 'Success!',
          text: 'Item was removed from your cart.',
        })
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/checkout/cart/CartTableRow.vue')
        notify({
          type: 'error',
          title: 'Error',
          text: 'Item could not be removed from your cart.',
          duration: -1,
        })
      } finally {
        isLoading.value = false

        setLoading(false)
      }
    }

    return {
      discount,
      netPrice,
      isLoading,
      isCartPage,
      handleRemoveItem,
    }
  },
})
