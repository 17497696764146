import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import Product from '../types/Product'
import getStoreName from '../../utils/getStoreName'

interface SingleProductResponse {
  data: {
    products: {
      items: Product[]
    }
  }
}

export const singleProductQuery = gql`
  query($sku: String!) {
    products(filter: { sku: { eq: $sku } }) {
      items {
        uid
        rlms_id
        is_curriculum
        name
        sku
        __typename
        description {
          html
        }
        ... on BundleProduct{
          items{
            options{
              uid
              product {
                sku
                rlms_id
                is_curriculum
              }
            }
          }
        }
        price_range {
          minimum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              currency
              value
            }
            final_price {
              currency
              value
            }
          }
          maximum_price {
            discount {
              amount_off
              percent_off
            }
            regular_price {
              currency
              value
            }
            final_price {
              currency
              value
            }
          }
        }
      }
    }
  }
`

export const getSingleProduct = async (productSKU: string): Promise<Product[]> => {
  const { data }: AxiosResponse<SingleProductResponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Store: getStoreName(),
    },
    data: {
      query: singleProductQuery,
      variables: {
        sku: productSKU,
      },
    },
  })

  return data.data.products.items
}
