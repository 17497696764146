import range from 'lodash.range'

export const cardTypes = [
  {
    name: 'MasterCard',
    code: 'MC',
  },
  {
    name: 'Visa',
    code: 'VI',
  },
  {
    name: 'American Express',
    code: 'AE',
  },
  {
    name: 'Discover',
    code: 'DI',
  },
]

export const cardMonths = [
  {
    name: 'Jan',
    code: '01',
  },
  {
    name: 'Feb',
    code: '02',
  },
  {
    name: 'Mar',
    code: '03',
  },
  {
    name: 'Apr',
    code: '04',
  },
  {
    name: 'May',
    code: '05',
  },
  {
    name: 'Jun',
    code: '06',
  },
  {
    name: 'Jul',
    code: '07',
  },
  {
    name: 'Aug',
    code: '08',
  },
  {
    name: 'Sep',
    code: '09',
  },
  {
    name: 'Oct',
    code: '10',
  },
  {
    name: 'Nov',
    code: '11',
  },
  {
    name: 'Dec',
    code: '12',
  },
]

export const cardYears = range(
  new Date().getFullYear(),
  new Date().getFullYear() + 10,
).map((year: number) => year.toString())
