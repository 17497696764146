
import {computed, defineComponent, onMounted, PropType, ref, toRefs, watch} from 'vue'

import AppTextInput from '../global/forms/inputs/AppTextInput.vue'
import AppSearchableSelect from '../global/forms/inputs/AppSearchableSelect.vue'

import UserAddress from '../../types/UserAddress'
import useCustomer from '../../composables/useCustomer'
import useCountries from '../../composables/useCountries'
import Country from '../../../../global/js/magento-api-library/types/Country'
import CountryRegion from '../../../../global/js/magento-api-library/types/CountryRegion'
export default defineComponent({
  name: 'BillingAddressForm',
  components: {
    AppTextInput,
    AppSearchableSelect,
  },
  props: {
    modelValue: {
      type: Object as PropType<UserAddress>,
      required: true,
    },
    addressType: {
      type: String,
      required: true,
    },
    formValidationState:{
      type: Function,
      required: true,
    },
    removeValidationError:{
      type: Function,
      required: true,
    },
  },
  setup(props) {
    const { customer } = useCustomer()
    const { modelValue } = toRefs(props)
    const {
      states,
      countries,
      stateData,
      countryData,
      selectedState,
      setRegionData,
      selectedCountry,
    } = useCountries()
    let isStatesAvailable = ref(true)

    const emailForm = ref({
      email: '',
    })

    const local = computed((): UserAddress => modelValue.value)

    onMounted((): void => {
      setRegionData()

      if (customer.value) {
        emailForm.value.email = customer.value.email
      }
    })

    watch(selectedCountry, (): void => {
      const country = countryData.value.find((countryToFind: Country) => {
        return countryToFind.two_letter_abbreviation === selectedCountry.value.value
      })

      local.value.country = selectedCountry.value.value

      if (country && country.available_regions) {
        stateData.value = country.available_regions
        states.value = country.available_regions.map((region: CountryRegion) => ({
          label: region.name,
          value: region.code,
        }))
        isStatesAvailable.value = true
        if (local.value.regionID) {
          selectedState.value = {
            label: local.value.state,
            value: local.value.stateCode,
          }
        } else {
          selectedState.value = {
            label: '',
            value: '',
          }
        }
      } else {
        stateData.value = []
        isStatesAvailable.value = false
        selectedState.value = {label: '', value: ''}
      }
    })

    watch(selectedState, (): void => {
      let emptyRegion = true
      if (selectedState.value.label != '' && selectedState.value.value != '') {
        const state = stateData.value.find((stateToFind: CountryRegion) => {
          return stateToFind.code === selectedState.value.value
        })
        if (state) {
          local.value.state = state.name
          local.value.stateCode = state.code
          local.value.regionID = state.id
          emptyRegion = false
        }
      }
      if (emptyRegion) {
        local.value.state = ''
        local.value.stateCode = ''
        local.value.regionID = 0
      }
    })

    function setFieldError( error: {fieldName: string, validationError: string} ) {
      props.formValidationState(error)
    }
    function removeFieldError( fieldName: string ) {
      props.removeValidationError(fieldName)
    }

    return {
      local,
      states,
      customer,
      countries,
      emailForm,
      selectedState,
      selectedCountry,
      isStatesAvailable,
      setFieldError,
      removeFieldError,
    }
  },
})
