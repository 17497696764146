
import { defineComponent } from 'vue'

import SkeletonAnimation from './SkeletonAnimation.vue'

export default defineComponent({
  name: 'ListSkeleton',
  components: {
    SkeletonAnimation,
  },
  props: {
    count: {
      type: Number,
      required: false,
      default: 4,
    },
  },
})
