
import { defineComponent } from 'vue'
import TranscriptsContainer from '../../transcripts/TranscriptsContainer.vue'

export default defineComponent({
  name: 'TranscriptsTab',
  components: {
    TranscriptsContainer,
  },
})
