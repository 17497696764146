
import { defineComponent, reactive, ref } from 'vue'

import AppFormErrorAlert from '../global/forms/AppFormErrorAlert.vue'
import AppCheckboxInput from '../global/forms/inputs/AppCheckboxInput.vue'
import { errorMessage } from '../../store/modules/ui/errorMessage'
import useCart from '../../composables/useCart'
import useLoader from '../../composables/useLoader'
import useNotify from '../../composables/useNotify'
import { login } from '../../../../global/js/magento-api-library/auth/login'
import { log } from '../../../../global/js/utils/logging'
import { useField, useForm } from 'vee-validate'

export default defineComponent({
  name: 'LoginForm',
  components: {
    AppCheckboxInput,
    AppFormErrorAlert,
  },
  setup() {
    const { notify } = useNotify()
    const { setLoading } = useLoader()
    const { cart, combineCarts, getCart} = useCart()
    const { meta: formMeta } = useForm()

    let invalidLogin = ref(false)
    const emailField = reactive(useField('email', { email: true, required: true}))
    const passwordField = reactive(useField('password', { required: true }))

    const form = ref({
      email: '',
      password: '',
      remember: false,
    })

    const canGoBack = (): boolean => {
      const referrer = document.referrer

      if (referrer !== '' && referrer.indexOf(location.protocol + '//' + location.host) === 0) {
        return true
      }

      return false
    }

    const disableInvalidLogin = ()=> {
      invalidLogin.value = false
    }

    const handleSubmit = async (): Promise<void> => {
      setLoading(true)

      try {
        await getCart()
        const guestCartItems = cart.value.total_quantity
        const loginWasSuccessful = await login(form.value.email, form.value.password)

        if (loginWasSuccessful) {
          if (guestCartItems > 0) {
            await combineCarts()

            window.location.assign('/cart')
          } else {
            if (canGoBack()) {
              window.location.assign(document.referrer)
            } else {
              window.location.assign('/user/profile')
            }
          }
        } else {
          invalidLogin.value = true

          notify({
            type: 'error',
            title: 'Error',
            text: 'Login failed.',
            duration: -1,
          })
        }
      } catch (error) {
        log(error as string, 'src/reliasmedia/js/components/auth/LoginForm.vue')
        
        notify({
          type: 'error',
          title: 'Error',
          text: 'Login failed.',
          duration: -1,
        })
      }

      setLoading(false)
    }

    return {
      form,
      errorMessage,
      handleSubmit,
      formMeta,
      emailField,
      passwordField,
      invalidLogin,
      disableInvalidLogin,
    }
  },
})
