import axios, { AxiosResponse } from 'axios'

import gql from '../../utils/gql'
import Order from '../types/Order'
import PageInfo from '../types/PageInfo'
import getStoreName from '../../utils/getStoreName'
import getBearerToken from '../../utils/getBearerToken'

interface GetOrdersReponse {
  data: {
    customer: {
      orders: {
        total_count: number
        page_info: PageInfo
        items: Order[]
      }
    } | null
  }
  errors?: [{ message: string }]
}

export const ordersQuery = gql`
    query($pageSize: Int, $currentPage: Int) {
        customer {
            orders(pageSize: $pageSize, currentPage: $currentPage) {
                total_count
                page_info {
                    current_page
                    page_size
                    total_pages
                }
                items {
                    id
                    number
                    order_date
                    invoices {
                        id
                        items {
                            ... on DownloadableInvoiceItem {
                                id
                                product_sku
                                downloadable_links {
                                    uid
                                    title
                                }
                            }
                        }
                    }
                    items {
                        id
                        product_sku
                        product_name
                        quantity_ordered
                        product_sale_price {
                            currency
                            value
                        }
                        discounts {
                            amount {
                                currency
                                value
                            }
                            label
                        }
                    }
                    billing_address {
                        firstname
                        lastname
                        postcode
                        street
                        city
                        region_id
                        region
                        telephone
                        country_code
                    }
                    shipping_address {
                        firstname
                        lastname
                        postcode
                        street
                        city
                        region_id
                        region
                        telephone
                        country_code
                    }
                    payment_methods {
                        name
                        additional_data {
                            name
                            value
                        }
                        type
                    }
                    total{
                        discounts{
                            amount{
                                value
                                currency
                            }
                            label
                        }
                        subtotal{
                            value
                            currency
                        }
                        grand_total{
                            value
                            currency
                        }
                        shipping_handling{
                            total_amount{
                                value
                                currency
                            }
                        }
                        taxes{
                            amount{
                                value
                                currency
                            }
                            rate
                            title
                        }
                        total_shipping{
                            value
                            currency
                        }
                        total_tax{
                            value
                            currency
                        }
                    }
                }
            }
        }
    }
`

/**
 * @throws {string} Will throw one error message; Magento only returns one at a time, rather than an array of all errors.
 */
export const getOrders = async (
  currentPage = 1,
  pageSize = 20,
): Promise<{
  total_count: number
  page_info: PageInfo
  items: Order[]
} | null> => {
  const bearerToken = getBearerToken()

  const { data }: AxiosResponse<GetOrdersReponse> = await axios({
    method: 'POST',
    url: process.env.MIX_MAGENTO_GRAPHQL,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
      Store: getStoreName(),
    },
    data: {
      query: ordersQuery,
      variables: {
        pageSize,
        currentPage,
      },
    },
  })

  if (data.errors) {
    throw data.errors[0].message
  }

  if (data.data.customer) {
    return data.data.customer.orders
  }

  return null
}
